import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useGlobalState } from "./GlobalStateContext"
import * as html2canvas from 'html2canvas';
import jsPDF from "jspdf"
import axios from 'axios'
import { Cookies } from 'react-cookie'
import { format } from 'date-fns'

const certificatesContext = createContext();

export function CertificatesContext({ children }) {
    const { t, API_PATH, activePage: completed, setActivePage: setCompleted, language, alphaRecruitUrlParams, userData, valuesComm, valuesThink, setValuesComm, setValuesThink } = useGlobalState()
    const [base64StrComm, setBase64StrComm] = useState(null)
    const [base64StrThink, setBase64StrThink] = useState(null)
    const [error, setError] = useState("")
    const date = format(new Date(), 'yyyy-MM-dd');

    const html2ImageStr = useCallback(async (strAssessment, setBase64Str, createPDF) => { /* Return the base64 string from the certificate html and optionally create a PDF file from it. */
        html2canvas(document.getElementById('cert' + strAssessment), {
            scale: 1
        }).then(async canvas => {
            const imgData = await canvas.toDataURL('image/jpeg', 1);
            setBase64Str(imgData);

            if (createPDF === true) {
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = Math.floor((imgProps.height * pdfWidth) / imgProps.width);

                let fname = '';
                if (strAssessment === 'Comm') {
                    fname = date + ' Urkunde Assessment Kommunikation ' + userData.name;
                } else if (strAssessment === 'Think') {
                    fname = date + ' Urkunde Assessment Denkstil ' + userData.name;
                }
                pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight, '', 'FAST');
                pdf.save(fname + '.pdf');
            }
        }).catch(e => { setError(e.message) })
    }, [userData, date])

    const storeInAlphaRecruit = useCallback(() => {
        axios.post(API_PATH + 'store-assessments-in-alpha-recruit.php', { urlParams: alphaRecruitUrlParams, userData: userData, valuesComm: valuesComm, valuesThink: valuesThink })
            .catch(e => { setError(e.message) })
    }, [API_PATH, alphaRecruitUrlParams, userData, valuesComm, valuesThink])

    const createCertsAndStoreValues = useCallback(() => {
        axios.post(API_PATH + 'store-assessment-values-in-database.php', { urlParams: alphaRecruitUrlParams, userData: userData, valuesComm: valuesComm, valuesThink: valuesThink, base64StrComm: base64StrComm, base64StrThink: base64StrThink })
            .then(() => {
                if (alphaRecruitUrlParams && alphaRecruitUrlParams.res_id && alphaRecruitUrlParams.res_model && alphaRecruitUrlParams.token) {
                    storeInAlphaRecruit()
                }
                setCompleted(true)
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, base64StrComm, base64StrThink, setCompleted, storeInAlphaRecruit, alphaRecruitUrlParams, userData, valuesComm, valuesThink])


    useEffect(() => {
        if (completed === false || completed === 'false') {
            const cookies = new Cookies()
            if (Object.keys(valuesComm).length === 0 && cookies.get('valuesComm')) {
                setValuesComm(cookies.get('valuesComm'));
            }
            if (Object.keys(valuesThink).length === 0 && cookies.get('valuesThink')) {
                setValuesThink(cookies.get('valuesThink'));
            }
        }
    }, [completed, setValuesComm, setValuesThink, valuesComm, valuesThink])

    useEffect(() => {
        if (base64StrComm && base64StrThink) {
            createCertsAndStoreValues()
        }
    }, [base64StrComm, base64StrThink, createCertsAndStoreValues, valuesComm, valuesThink])

    const generateAssessments = useCallback((generatePDF) => {
        const cookies = new Cookies()
        if (completed === false || completed === 'false' || !completed) {
            if ((Object.keys(valuesComm).length > 0 && Object.keys(valuesThink).length > 0) || (cookies.get('valuesComm') && cookies.get('valuesThink'))) {
                html2ImageStr('Think', setBase64StrThink, generatePDF)
                html2ImageStr('Comm', setBase64StrComm, generatePDF)
            } else {
                setError(t('Certificates.error_processing'))
            }
        }
    }, [t, completed, html2ImageStr, valuesComm, valuesThink])

    const styleAlphaA = useCallback((strAssessment) => {
        let spans = ["username", "filltime", "description", "privacy_note", "evaluation_note", "website"]
        for (let i in spans) {
            document.getElementById("span_" + spans[i] + "_" + strAssessment).innerHTML = document.getElementById("span_" + spans[i] + "_" + strAssessment).innerHTML.replace(/a/g, `<span class="alpha-a">a</span>`);
        }
    }, [])

    const Header = () => {
        return (
            <div id='header-cert'>
                <img src={process.env.PUBLIC_URL + '/images/alphatop/logo.png'} alt='main-logo' id='main-logo' />
            </div>
        )
    }

    const MainText = ({ t, time, date, strAssessment }) => {
        return (
            <>
                <span className="cert-bold" style={{ marginBottom: '1.55rem', fontSize: '1.4rem', letterSpacing: '0.4rem', textTransform: 'uppercase' }}><b>{t('Certificates.heading_' + strAssessment)}</b></span>
                <span id={'span_username_' + strAssessment} className="cert-bold" style={{ lineHeight: '1.375', fontSize: '1rem', letterSpacing: '0.025rem' }}><b>{userData.name}</b></span>
                <span id={'span_filltime_' + strAssessment} style={{ fontSize: '0.675rem' }}>{t('Certificates.fill_time')}: {time}</span>
                <span style={{ fontSize: '0.67rem' }}>{date}</span>
                <span id={'span_description_' + strAssessment} style={{
                    marginTop: '1.55rem', fontSize: '0.9rem', lineHeight: '1.375',
                    width: strAssessment === 'comm' ? (language === 'de' ? '570px' : language === 'en' ? '550px' : language === 'hu' ? '550px' : language === 'pl' ? '510px' : language === 'ro' && '520px')
                        : strAssessment === 'think' && (language === 'de' ? '480px' : language === 'en' ? '460px' : language === 'hu' ? '550px' : language === 'pl' ? '530px' : language === 'ro' && '520px')
                }}>{t('Certificates.description_' + strAssessment)}</span >
            </>
        )
    }

    const Footer = ({ strAssessment }) => {
        return (
            <div id={'footer-cert-' + strAssessment} className='footer-cert d-flex flex-row justify-content-between'>
                <div className='text-start' style={{ fontSize: '0.6rem', lineHeight: '1.575' }}>
                    <div id={'span_privacy_note_' + strAssessment}>
                        {t('Certificates.privacy_note_1')}<br />
                        {t('Certificates.privacy_note_2')}
                    </div>
                    <div id={'span_evaluation_note_' + strAssessment}>{t('Certificates.evaluation_note')}</div>
                </div>
                <h6 id={'span_website_' + strAssessment} className="cert-bold"><b>alphatop.com</b></h6>
            </div>
        )
    }

    return (
        <certificatesContext.Provider
            value={{ generateAssessments, styleAlphaA, Header, MainText, Footer, error, setError }}
        >
            {children}
        </certificatesContext.Provider>
    );
}

export function useCert() {
    return useContext(certificatesContext)
}