import React, { useState } from "react";
import { Form, Alert, Button } from "react-bootstrap";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useUserAuth } from "../context/UserAuthContext";

const AccountSettings = () => {
    const { t } = useGlobalState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState("");
    const { updateDisplayName, updateUserEmail, updateUserPassword } = useUserAuth();

    const updateName = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await updateDisplayName(name);
            alert('Name changed!')
        } catch (err) { setError(err.message) }
    }

    const updateEmail = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await updateUserEmail(email);
            alert('Email changed!')
        } catch (err) { setError(err.message) }
    }

    const updatePassword = async (e) => {
        e.preventDefault();
        setError("");
        try {
            if (password === confirmPassword) {
                await updateUserPassword(password);
                alert('Password changed!')
            } else {
                setError(t('Auth.alert_passwords_must_match'), { ns: 'internal' });
            }
        } catch (err) { setError(err.message) }
    }

    return (
        <>
            <h3 className="mt-5">Account Settings</h3>
            <div className="p-5 box mt-4 text-center col-12 col-sm-10 col-md-8 col-lg-5">
                <h4 className="mb-3">Change Name</h4>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={updateName} autoComplete="off">
                    <Form.Group className="mb-3" controlId="formBasicDisplayName">
                        <Form.Control
                            type="text"
                            placeholder='New Name'
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="Submit">
                            Change Name
                        </Button>
                    </div>
                </Form>
            </div>
            <div className="p-5 box mt-4 text-center col-12 col-sm-10 col-md-8 col-lg-5">
                <h4 className="mb-3">Change Email</h4>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={updateEmail} autoComplete="off">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                            type="email"
                            placeholder='New Email'
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="Submit">
                            Change Email
                        </Button>
                    </div>
                </Form>
            </div>
            <div className="p-5 box mt-4 text-center col-12 col-sm-10 col-md-8 col-lg-5">
                <h4 className="mb-3">Change Password</h4>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={updatePassword} autoComplete="off">
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Control
                            type="password"
                            placeholder='New Password'
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicConfirm">
                        <Form.Control
                            type="password"
                            placeholder='Confirm New Password'
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                    </Form.Group>

                    <div className="d-grid gap-2">
                        <Button variant="primary" type="Submit">
                            Change Password
                        </Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default AccountSettings;