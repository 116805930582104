import React, { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail,
  updateProfile,
  updateEmail,
  updatePassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth } from "../firebase";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import axios from 'axios'

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const { API_PATH } = useGlobalState();
  const [user, setUser] = useState(undefined);
  const [userAccessRights, setUserAccessRights] = useState({});

  useEffect(() => {
    if (user && Object.keys(userAccessRights).length === 0) {
      axios.post(API_PATH + 'user-access-rights.php', { action: 'read', email: user.email })
        .then(res => { setUserAccessRights(res.data) })
        .catch(e => { console.log(e) })
    }
  }, [API_PATH, user, userAccessRights])

  const signUp = async (name, email, password) => {
    const { user } = await createUserWithEmailAndPassword(auth, email, password)
    await updateProfile(user, { displayName: name })
    return user;
  }
  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }
  function updateDisplayName(name) {
    return updateProfile(auth.currentUser, { displayName: name })
  }
  function updateUserEmail(email) {
    return updateEmail(auth.currentUser, email)
  }
  function updateUserPassword(password) {
    return updatePassword(auth.currentUser, password)
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <userAuthContext.Provider
      value={{ user, userAccessRights, setUser, logIn, signUp, logOut, resetPassword, updateDisplayName, updateUserEmail, updateUserPassword, googleSignIn }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}