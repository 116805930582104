export const CHARACTERIZATIONS_THINK_RO = [
    [
        "Cu o atitudine imparțială/Obiectiv",
        "Sunt deschis și receptiv la idei și opinii noi, chiar dacă acestea diferă de ale mele.",
        "Simultan",
        "Să fi capabil să faci mai multe sarcini diferite în același timp."
    ],
    [
        "Creativ",
        "A fi creativ, a avea idei și a le realiza în mod creativ.",
        "Entuziast",
        "Mă implic ușor, cu mare entuziasm."
    ],
    [
        "Conceptual",
        "A concepe/ întocmi ceva singur și independent (de exemplu: propuneri, elaborări, planuri etc.)",
        "Practic",
        "Prefer acțiunea concretă în locul contemplării teoretice."
    ],
    [
        "Plin de fantezie",
        "Capacitatea de a îți imagina lucruri care nu pot fi percepute cu simțurile sau care nu există în realitate. Abilitatea de a aborda problemele într-un mod nou.",
        "Cantitativ",
        "Înclinația de a considera și evalua faptele în funcție de criterii măsurabile. Îmi place să mă concentrez pe numere."
    ],
    [
        "Explorator",
        "Îmi place să mă aflu pe teren necunoscut și să încerc idei noi sau să găsesc concepte noi.",
        "Creativ",
        "A fi creativ, a avea idei și a le realiza în mod creativ."
    ],
    [
        "Strateg",
        "Pot să gândesc strategic și să văd lucrurile precum și urmările acestora într-un context general.",
        "Pasionat",
        "Sentimentele și ideile mele mă pot influența foarte puternic."
    ],
    [
        "Cuprinzător",
        "Să poți vedea faptele individuale în contextul lor general, asta însemnând să vezi pădurea întreagă în locul multor copaci separați.",
        "Organizat",
        "Organizez cu plăcere derularea unui proiect."
    ],
    [
        "Aventuros",
        "Sunt dispus să iau decizii incerte și să văd oportunități în ele.",
        "Logic",
        "Să gândești logic și concludent conform legilor."
    ],
    [
        "Pasionat",
        "Sentimentele și ideile mele mă pot influența foarte puternic.",
        "Integrabil",
        "Am capacitatea de combina părți și elemente din idei, concepte și situații într-un întreg."
    ],
    [
        "Săritor",
        "Sunt bucuros să ofer ajutorul meu altora și șă îi susțin atunci când au dificultăți.",
        "Intuitiv (Sentiment)",
        "Am o intuiție pentru starea de spirit a altor oameni și mă pot baza pe sentimentele mele de simpatie și antipatie fără o justificare rațională. Recunosc ceva emoțional fără a avea dovezi concrete."
    ],
    [
        "Emoțional",
        "Simt lucrurile foarte intens și îmi pot arăta sentimentele.",
        "Perseverent",
        "Sunt dur și perseverent în a-mi urma obiectivele."
    ],
    [
        "Prietenos",
        "Îmi place să fiu amabil, drăguț și cald.",
        "Bazat pe fapte",
        "Pentru mine este important să cunosc faptele exacte și să îmi bazez decizia pe aceste fapte."
    ],
    [
        "Empatic",
        "Înțeleg adesea sentimentele celorlalți și mă pot conecta cu alți oameni.",
        "Conceptual",
        "A concepe/ întocmi ceva singur și independent (de exemplu: propuneri, elaborări, planuri etc.)"
    ],
    [
        "Armonios",
        "Am tendința de a echilibra lucrurile și sentimentele.",
        "Săritor",
        "Sunt bucuros să ofer ajutorul meu altora și șă îi susțin atunci când au dificultăți."
    ],
    [
        "Ospitalier",
        "Îmi place să invit oamenii la mine pentru a petrece timpul împreună.",
        "Detaliat",
        "A lua în considerare componentele mici și cele mai mici ale unui fapt și a le acorda imporanță."
    ],
    [
        "Binevoitor",
        "Sunt deschis și amabil cu alți oameni.",
        "Matematic",
        "Înclinația de a lucra cu numere și date și de a le putea procesa."
    ],
    [
        "Organizat",
        "Organizez cu plăcere derularea unui proiect.",
        "Artistic",
        "Dragoste sau talent pentru pictură, desen sau sculptură. Tendința pentru design estetic-artistic în diferite domenii ale vieții."
    ],
    [
        "Detaliat",
        "A lua în considerare componentele mici și cele mai mici ale unui fapt și a le acorda imporanță.",
        "Interpersonal",
        "Construiesc relații cu oameni mulți și diferiți și le mențin pe termen lung."
    ],
    [
        "Disciplinat",
        "unt în control și îmi pot urma hotărârile fără să mă descurajez.",
        "Structurat",
        "Îmi place o structură sistematică și un cadru fix."
    ],
    [
        "Practic",
        "Prefer acțiunea concretă în locul contemplării teoretice.",
        "Analitic",
        "Împărțirea lucrurilor sau ideilor în părți pentru a vedea conexiunile și relațiile dintre părți."
    ],
    [
        "Dominant",
        "Vrei să te afirmi și să îi controlezi pe ceilalți.Tind să dau tonul.",
        "Cu o atitudine imparțială/Obiectiv",
        "Sunt deschis și receptiv la idei și opinii noi, chiar dacă acestea diferă de ale mele.",
    ],
    [
        "Planificator",
        "Îmi place să determin în avans pașii necesari pentru atingerea unui obictiv anume.",
        "Emoțional",
        "Simt lucrurile foarte intens și îmi pot arăta sentimentele."
    ],
    [
        "Secvențial",
        "Defalcarea problemelor într - o ordine și tratatrea lor în consecință, asta însemnând fac unul câte unul.",
        "Disciplinat",
        "Sunt în control și îmi pot urma hotărârile fără să mă descurajez.",
    ],
    [
        "Punctual",
        "Este important pentru mine să respect termenele limită și să - mi controlez timpul.",
        "Clar",
        "Pot face afirmații clare și lipsite de ambiguitate și îmi ating scopul.",
    ],
    [
        "Logic",
        "Să gândești logic și concludent conform legilor.",
        "Aventuros",
        "Sunt dispus să iau decizii incerte și să văd oportunități în ele.",
    ],
    [
        "Matematic",
        "Înclinația de a lucra cu numere și date și de a le putea procesa.",
        "De încredere",
        "Am o atitudine pozitivă față de alți oameni și  îmi place să mă bazez pe ei.",
    ],
    [
        "Clar",
        "Pot face afirmații clare și lipsite de ambiguitate și îmi ating scopul.",
        "Bazat pe procedură",
        "Îmi place să stabilesc proceduri specifice pentru acțiunile mele, pe care apoi să le pot urma.",
    ],
    [
        "Științific ",
        "Îmi pace să mă ocup de legi și fapte explicabile și verificabile logic.",
        "Îm plac cifrele ",
        "Mă orientez pe baza rapoartelor numerice.Am tendința de a găsi numere și măsurători exacte.",
    ],
    [
        "Explicit",
        "Îmi place să mă ocup de afirmații clare, care conțin fapte concise.",
        "Plin de fantezie",
        "Capacitatea de a îți imagina lucruri care nu pot fi percepute cu simțurile sau care nu există în realitate.Abilitatea de a aborda problemele într - un mod nou.",
    ],
    [
        "Rațional",
        "Nu evaluezi faptele din punct de vedere emoțional, ci cu intelect și rațiune.",
        "Prietenos",
        "Îmi place să fiu amabil, drăguț și cald.",
    ],
    [
        "Cantitativ",
        "Înclinația de a considera și evalua faptele în funcție de criterii măsurabile.Îmi place să mă concentrez pe numere.",
        "Practic",
        "Prefer acțiunea concretă în locul contemplării teoretice.",
    ],
    [
        "Tehnic",
        "Înțelegi și aplici cunoștințele din inginerie și științe naturale.",
        "Științific",
        "Îmi pace să mă ocup de legi și fapte explicabile și verificabile logic.",
    ],
    [
        "Simultan",
        "Să fi capabil să faci mai multe sarcini diferite în același timp.",
        "Ingenios",
        "Am adesea multe idei creative și sugestii la proiecte. ",
    ],
    [
        "Integrabil",
        "Am capacitatea de combina părți și elemente din idei, concepte și situații într - un întreg.",
        "Sociabil",
        "Îmi place să am de - a face cu oamenii și prefer să lucrez în echipă decât singur.",
    ],
    [
        "Artristic",
        "Dragoste sau talent pentru pictură, desen sau sculptură.Tendința pentru design estetic - artistic în diferite domenii ale vieții.",
        "Harnic",
        "Îmi place să lucrez.",
    ],
    [
        "Aventuros",
        "Sunt dispus să iau decizii incerte și să văd oportunități în ele.",
        "Critic",
        "Verific și evaluez atent, de ex: proiecte sau idei.Atitudinea mea este adesea cumpătată.",
    ],
    [
        "Ingenios",
        "Am adesea multe idei creative și sugestii la proiecte.",
        "Explorator",
        "Îmi place să mă aflu pe teren necunoscut și să încerc idei noi sau să găsesc concepte noi.",
    ],
    [
        "Curios",
        "Sunt o persoană care pune multe întrebări și îi place să afle despre toată lumea.",
        "Empatic",
        "Înțeleg adesea sentimentele celorlalți și mă pot conecta cu alți oameni.",
    ],
    [
        "Vizionar",
        "Am o imaginație bună și îmi place să gândesc în viitor.",
        "Dominant",
        "Vrei să te afirmi și să îi controlezi pe ceilalți.Tind să dau tonul.",
    ],
    [
        "Simbolic",
        "Gândesc în imagini și parabole.",
        "Explicit",
        "Îmi place să fac afirmații clare care conțin fapte logice.",
    ],
    [
        "Sociabil",
        "Îmi place să am de - a face cu oamenii și prefer să lucrez în echipă decât singur.",
        "Curios",
        "Sunt o persoană care pune multe întrebări și îi place să afle despre toată lumea.",
    ],
    [
        "Intuitiv(Sentiment)",
        "Am o intuiție pentru starea de spirit a altor oameni și mă pot baza pe sentimentele mele de simpatie și antipatie fără o justificare rațională.Recunosc ceva emoțional fără a avea dovezi concrete.",
        "Expresiv",
        "Îmi exprim părerile foarte accentuat și clar.",
    ],
    [
        "Interpersonal",
        "Construiesc relații cu oameni mulți și diferiți și le mențin pe termen lung.",
        "Detaliat",
        "A lua în considerare componentele mici și cele mai mici ale unui fapt și a le acorda imporanță.",
    ],
    [
        "De încredere",
        "Am o atitudine pozitivă față de alți oameni și îmi place să mă bazez pe ei.",
        "Axat pe fapte",
        "Mod riguros de gândire bazat exclusiv pe fapte.",
    ],
    [
        "Entuziast",
        "Mă implic ușor, cu mare entuziasm.",
        "Strateg",
        "Pot să gândesc strategic și să văd lucrurile precum și urmările acestora într - un context general.",
    ],
    [
        "Expresiv",
        "Îmi exprim părerile foarte accentuat și clar.",
        "Armonios",
        "Am tendința de a echilibra lucrurile și sentimentele.",
    ],
    [
        "Cooperativ",
        "Îmi place să lucrez cu alții pentru a atinge un obiectiv comun.",
        "Planificator",
        "Îmi place să determin în avans pașii necesari pentru atingerea unui obictiv anume.",
    ],
    [
        "Deschis cu oamenii",
        "Sunt deschis la sugestii și ajutor din partea altor persoane.",
        "Rational",
        "Nu evaluezi faptele din punct de vedere emoțional, ci cu intelect și rațiune.",
    ],
    [
        "Practic",
        "Prefer acțiunea concretă în locul contemplării teoretice.",
        "Vizionar",
        "Am o imaginație bună și îmi place să gândesc în viitor.",
    ],
    [
        "Perseverent",
        "Sunt dur și perseverent în a - mi urma obiectivele.",
        "Cooperativ",
        "Îmi place să lucrez cu alții pentru a atinge un obiectiv comun.",
    ],
    [
        "Structurat",
        "Îmi place o structură sistematică și un cadru fix.",
        "Protector",
        "Sunt o personă precaută care ia în considerare cu atenție consecințele acțiunilor sale.",
    ],
    [
        "Bazat pe procedură",
        "Îmi place să stabilesc proceduri specifice pentru acțiunile mele, pe care apoi să le pot urma.",
        "Provocator",
        "Tind să pun la îndoială lucrurile și îmi place să fac pe 'avocatul diavolului'.",
    ],
    [
        "Harnic",
        "Îmi place să lucrez.",
        "Cuprinzător",
        "Să poți vedea faptele individuale în contextul lor general, asta însemnând să vezi pădurea întreagă în locul multor copaci separați.",
    ],
    [
        "Detaliat",
        "A lua în considerare componentele mici și cele mai mici ale unui fapt și a le acorda imporanță.",
        "Ospitalier",
        "Îmi place să invit oamenii la mine pentru a petrece timpul împreună.",
    ],
    [
        "Protector",
        "Sunt o personă precaută care ia în considerare cu atenție consecințele acțiunilor sale.",
        "Secvențial",
        "Defalcarea problemelor într - o ordine și tratatrea lor în consecință, asta însemnând fac unul câte unul.",
    ],
    [
        "Convențional",
        "Tendința de a se agăța de tradițional.",
        "Cantitativ",
        "Înclinația de a considera și evalua faptele în funcție de criterii măsurabile.Îmi place să mă concentrez pe numere.",
    ],
    [
        "Matematic",
        "Înclinația de a lucra cu numere și date și de a le putea procesa.",
        "Simbolic",
        "Gândesc în imagini și parabole.",
    ],
    [
        "Obiectiv",
        "Gândesc și acționez fără prejudecăți și rareori mă las influențat de sentimentele personale.",
        "Deschis cu oamenii",
        "Sunt deschis la sugestii și ajutor din partea altor persoane.",
    ],
    [
        "Analitic",
        "Împărțirea lucrurilor sau ideilor în părți pentru a vedea conexiunile și relațiile dintre părți.",
        "Convențional",
        "Tendința de a se agăța de tradițional.",
    ],
    [
        "Îmi plac cifrele ",
        "Mă orientez pe baza rapoartelor numerice.Am tendința de a găsi numere și măsurători exacte.",
        "Realist",
        "Văd lucrurile așa cum sunt sau așa cum apar și evit speculațiile și vanitatea.",
    ],
    [
        "Critic",
        "Verific și evaluez atent, de ex: proiecte sau idei.Atitudinea mea este adesea cumpătată.",
        "Explorator",
        "Îmi place să mă aflu pe teren necunoscut și să încerc idei noi sau să găsesc concepte noi.",
    ],
    [
        "Axat pe fapte",
        "Mod riguros de gândire bazat exclusiv pe fapte.",
        "Binevoitor",
        "Sunt deschis și amabil cu alți oameni.",
    ],
    [
        "Provocator",
        "Tind să pun la îndoială lucrurile și îmi place să fac pe 'avocatul diavolului'.",
        "Punctual",
        "Este important pentru mine să respect termenele limită și să - mi controlez timpul.",
    ],
    [
        "Realist",
        "Văd lucrurile așa cum sunt sau așa cum apar și evit speculațiile și vanitatea.",
        "Tehnic",
        "Înțelegi și aplici cunoștințele din inginerie și științe naturale.",
    ],
]