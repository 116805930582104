import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_DE } from "./de/de";
import { TRANSLATIONS_EN } from "./en/en";
import { TRANSLATIONS_PL } from "./pl/pl";
import { TRANSLATIONS_RO } from "./ro/ro";
import { TRANSLATIONS_HU } from "./hu/hu";
import { INTERNAL_DE } from "./de/internal.de.js";
import { INTERNAL_EN } from "./en/internal.en.js";

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
        internal: INTERNAL_EN
      },
      de: {
        translation: TRANSLATIONS_DE,
        internal: INTERNAL_DE
      },
      pl: {
        translation: TRANSLATIONS_PL,
        internal: INTERNAL_EN
      },
      ro: {
        translation: TRANSLATIONS_RO,
        internal: INTERNAL_EN
      },
      hu: {
        translation: TRANSLATIONS_HU,
        internal: INTERNAL_EN
      }
    }
  });

export default i18n;