export const CHARACTERIZATIONS_THINK_EN = [
  ["Unprejudiced", "I am open and receptive of new ideas and views, even when they are different from my own.", "Multitasking", "Able to work on several different tasks at the same time."],
  ["Creative", "To be actively creative; to have ideas and then realize them.", "Enthusiastic", "I easily get involved with something with great enthusiasm."],
  ["Conceptional", "Independently design or compose something (e.g. proposals, explanations, plans etc.).", "Practical", "I prefer concrete actions to theoretical consideration."],
  ["Imaginative", "The ability to imagine things which do not exist in reality or cannot be grasped by the senses. The ability to approach problems in new ways.", "Quantitative", "The tendency to consider and evaluate matters according to measurable criteria. I like to use numbers as a guide."],
  ["Explorative", "I like to go into unknown territory and try to find new ideas or concepts. ", "Creative", "To be actively creative; to have ideas and then realize them."],
  ["Strategic", "I can think strategically, see things in their overall context and anticipate their effects.", "Passionate", "My feelings and ideas can affect me greatly."],
  ["Holistic", "Able to see individual matters in their overall context, i.e. able to see the forest from the trees.", "Organized", "I like to shape the course of a project into a functioning whole."],
  ["Risk-taking", "I am prepared to make uncertain decisions and see opportunities in them.", "Logical", "Thinking consequently and conclusively according to rules."],
  ["Passionate", "My feelings and ideas can affect me greatly.", "Integrating", "I have the ability to combine pieces and elements from different ideas, concepts and situations into a whole."],
  ["Helpful", "I like to offer my help to others and support them during difficulties.", "Intuitive (sense)", "Have a sense of other people's state of mind and can trust my feelings of sympathy and antipathy without rational justification. Perceive something emotionally without having factual evidence."],
  ["Emotional", "I feel things very intensely and can also show my feelings.", "Persistent", "I am tough and persevering in pursuit of my goals."],
  ["Friendly", "I like to be endearing, kind and warm.", "Fact-based", "It is important for me to know the exact facts and to use these facts to make my decision."],
  ["Empathetic", "I often understand the feelings of others and can relate to/sympathize with other people.", "Conceptional", "Independently design or compose something (e.g. proposals, explanations, plans etc.)."],
  ["Unifying", "I tend to bring things and feelings into balance.", "Helpful", "I like to offer my help to others and support them during difficulties."],
  ["Hospitable", "I like to invite people over in order to be together with them.", "Detailed", "Take into account and place value in the smallest components of a matter."],
  ["Accommodating", "I meet other people openly and kindly.", "Mathematical", "The tendency to work with numbers and data and to be able to process them."],
  ["Organized", "I like to shape the course of a project into a functioning whole.", "Artistic", "A fondness or talent for painting, drawing or sculpting. Tendency to aesthetic/artistic design in different areas of life."],
  ["Detailed", "Take into account and place value in the smallest components of a matter.", "Interpersonal", "Build and maintain relationships with many and varied people."],
  ["Disciplined", "I have self-control and can pursue my intentions undeterred.", "Structured", "I like systematic organization and firm boundaries."],
  ["Practical", "I prefer concrete actions to theoretical consideration.", "Analytical", "Breaking things or ideas down into parts in order to realize the laws and relationships between the individual pieces."],
  ["Domineering", "To want to assert myself and control others. I tend to set the tone.", "Unprejudiced", "I am open and receptive of new ideas and views, even when they are different from my own."],
  ["Planner", "I like to determine the necessary steps in advance, in order to achieve a specific goal.", "Emotional", "I feel things very intensely and can also show my feelings."],
  ["Sequential", "To break things down into a sequence and deal with them accordingly, i.e. do them one by one.", "Disciplined", "I have self-control and can pursue my intentions undeterred."],
  ["Punctual", "It is important for me to meet deadlines and control my time.", "Straightforward", "I can make clear and unambiguous statements and get to the point."],
  ["Logical", "Thinking consequently and conclusively according to rules.", "Risk-taking", "I am prepared to make uncertain decisions and see opportunities in them."],
  ["Mathematical", "The tendency to work with numbers and data and to be able to process them.", "Trusting", "I have a positive attitude towards other people and like to rely on them."],
  ["Straightforward", "I can make clear and unambiguous statements and get to the point.", "Procedural", "I like to set certain procedures for my approach that I can then follow."],
  ["Scientific", "I like to deal with laws and logically explainable and verifiable facts.", "Likes numbers", "Guided by numerical relationships. The tendency to find exact numbers and measurements."],
  ["Clear", "I like to deal with clear statements which contain understandable facts.", "Imaginative", "The ability to imagine things which do not exist in reality or cannot be grasped by the senses. The ability to approach problems in new ways."],
  ["Rational", "To see and evaluate matters not emotionally, but based on reason and intellect.", "Friendly", "I like to be endearing, kind and warm."],
  ["Quantitative", "The tendency to consider and evaluate matters according to measurable criteria. I like to use numbers as a guide.", "Practical", "I prefer concrete actions to theoretical consideration."],
  ["Technical", "To understand and apply knowledge from engineering and the natural sciences.", "Scientific", "I like to deal with laws and logically explainable and verifiable facts."],
  ["Multitasking", "Able to work on several different tasks at the same time.", "Inventive", "During projects, I often have many creative ideas and suggestions."],
  ["Integrating", "I have the ability to combine pieces and elements from different ideas, concepts and situations into a whole.", "Social", "I love having to do with people and prefer working in a team rather than alone."],
  ["Artistic", "A fondness or talent for painting, drawing or sculpting. Tendency to aesthetic/artistic design in different areas of life.", "Hardworking", "I enjoy working."],
  ["Risk-taking", "I am prepared to make uncertain decisions and see opportunities in them.", "Critical", "Careful testing and evaluation, e.g. of projects or ideas. My attitude is often to wait and see."],
  ["Inventive", "During projects, I often have many creative ideas and suggestions.", "Explorative", "I like to go into unknown territory and try to find new ideas or concepts."],
  ["Curious", "I am a person who asks a lot and likes to know about everyone.", "Empathetic", "I often understand the feelings of others and can relate to/sympathize with other people."],
  ["Visionary", "I have a good imagination and like to think about the future.", "Domineering", "To want to assert myself and control others. I tend to set the tone."],
  ["Symbolic", "Thinking in pictures and metaphors.", "Clear", "I like to deal with clear statements which contain understandable facts."],
  ["Social", "I love having to do with people and prefer working in a team rather than alone.", "Curious", "I am a person who asks a lot and likes to know about everyone."],
  ["Intuitive (sense)", "Have a sense of other people's state of mind and can trust my feelings of sympathy and antipathy without rational justification. Perceive something emotionally without having factual evidence.", "Expressive", "I convey my views powerfully and clearly."],
  ["Interpersonal", "Build and maintain relationships with many and varied people.", "Detailed", "Take into account and place value in the smallest components of a matter."],
  ["Trusting", "I have a positive attitude towards other people and like to rely on them.", "Factual", "Rigorous way of thinking based exclusively on facts."],
  ["Enthusiastic", "I easily get involved with something with great enthusiasm.", "Strategic", "I can think strategically, see things in their overall context and anticipate their effects."],
  ["Expressive", "I convey my views powerfully and clearly.", "Unifying", "I tend to bring things and feelings into balance."],
  ["Cooperative", "I like working together with others towards a common goal.", "Planner", "I like to determine the necessary steps in advance, in order to achieve a specific goal."],
  ["Open to people", "I am open to suggestions and offers of help from other people.", "Rational", "To see and evaluate matters not emotionally, but based on reason and intellect."],
  ["Practical", "I prefer concrete actions to theoretical consideration.", "Visionary", "I have a good imagination and like to think about the future."],
  ["Persistent", "I am tough and persevering in pursuit of my goals.", "Cooperative", "I like working together with others towards a common goal."],
  ["Structured", "I like systematic organization and firm boundaries.", "Cautious", "I am a cautious person, who carefully considers the consequences of my actions."],
  ["Procedural", "I like to set certain procedures for my approach that I can then follow.", "Provocative", "I tend to question facts and I like to play the \"Devil's Advocate\"."],
  ["Hardworking", "I enjoy working.", "Holistic", "Able to see individual matters in their overall context, i.e. able to see the forest from the trees."],
  ["Detailed", "Take into account and place value in the smallest components of a matter.", "Hospitable", "I like to invite people over in order to be together with them."],
  ["Cautious", "I am a cautious person, who carefully considers the consequences of my actions.", "Sequential", "To break things down into a sequence and deal with them accordingly, i.e. do them one by one."],
  ["Conventional", "The tendency to stick to the traditional.", "Quantitative", "I like to use numbers as a guide."],
  ["Mathematical", "The tendency to work with numbers and data and to be able to process them.", "Symbolic", "Thinking in pictures and metaphors."],
  ["Objective", "I think and act without preconceptions and seldom allow myself to be influenced by my feelings.", "Open to people", "I am open to suggestions and offers of help from other people."],
  ["Analytical", "Breaking things or ideas down into parts in order to realize the laws and relationships between the individual pieces.", "Conventional", "The tendency to stick to the traditional."],
  ["Likes numbers", "Guided by numerical relationships. The tendency to find exact numbers and measurements.", "Realistic", "I see things the way they are or are presented, avoiding speculation and imagination."],
  ["Critical", "Careful testing and evaluation, e.g. of projects or ideas. My attitude is often to wait and see.", "Risk-taking", "I am prepared to make uncertain decisions and see opportunities in them."],
  ["Factual", "Rigorous way of thinking based exclusively on facts.", "Accommodating", "I meet other people openly and kindly."],
  ["Provocative", "I tend to question facts and I like to play the \"Devil's Advocate\".", "Punctual", "It is important for me to meet deadlines and control my time."],
  ["Realistic", "I see things the way they are or are presented, avoiding speculation and imagination.", "Technical", "To understand and apply knowledge from engineering and the natural sciences."]
]