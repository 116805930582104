export const TRANSLATIONS_EN = {
    App: {
        lang: 'en',
        title: 'Assessment Center',
        welcome: 'Heartily welcome',
        page: 'Page',
        out_of: 'out of',
        btn_start: 'Start',
        btn_start_now: 'Start now',
        btn_back: 'Back',
        btn_next: 'Next',
        btn_previous_page: 'Previous Page',
        btn_next_page: 'Next Page',
        cancel: 'Cancel',
        loading: 'Loading...',
        name: 'Name',
        email: 'Email address',
        company: 'Company',
        operating_unit: 'Operating Unit'
    },
    Nav: {
        dashboard: 'Internal dashboard',
        restart_assessment: 'Restart Assessment (Admin)',
        certificates: 'Certificate archive',
        recovery: 'Recover certificates',
        account_settings: 'Account settings',
        config: 'Configuration',
        users: 'User management',
        log_out: 'Log Out'
    },
    Header: {
        alert_reset_assessment: 'Do you really want to reset the assessment?',
        warning_reset_assessment: 'This function may only be used if the applicant cannot otherwise continue processing.',
        yes_restart_assessment: 'Yes, reset assessment',
        alert_assessment_reset: 'The assessment has been reset.'
    },
    Home: {
        find_your_strengths: 'Find your strengths!',
        quote_hamlet: '\'The Readiness is all!\'',
        alpha_recruit_error: 'ALPHA Recruit Error: Invalid link',
        alert_user_not_found: 'Sorry, this link is invalid. Please get in touch with your contact person.'
    },
    Introduction: {
        introduction: 'Introduction',
        page_1_heading: 'Heartily welcome',
        page_1_1: 'On the following pages you will learn how to complete the Assessment Center.',
        page_1_2: 'Please note that this assessment should only be completed by those 15 years old and above.',
        page_1_3: 'This survey aims to give you nuanced feedback on your preferred way of doing things in everyday life. The following questions include statements about your personal way of communicating and thinking in different situations that could be important to your professional activities.',
        page_2_heading: 'Consent to Data Handling',
        page_2_1: 'Before you begin the assessment, we will inform you how we will process your data and how you can consent to the handling of the data.',
        page_2_2: 'Please read the information and tick the box below to give your consent.',
        page_2_3: ' I consent to my personal data being stored. All personal information will be handled confidentially and with utmost care. The data will only be available to Human Resources.',
        data_protection_info: 'Read the privacy information',
        alert_data_storage: 'Please check the box above to consent to the storage of your personal data.',
        page_3_heading: 'And this is how it works',
        page_3_1: 'On the next pages you will be shown various statements. Please evaluate them in the way that fits you best.',
        page_3_2: 'There are no correct or incorrect answers.',
        page_3_3: 'The only way to ensure a logical and consistent result, is through honest answers. This also provides the best chance of you being able to profit from the feedback given by the results.',
        page_3_4: 'If situations are described that you have not yet personally experienced - please assess how you would probably behave in them.',
        page_3_5: 'If certain statements are difficult to rate, please choose the answer that fits you best.',
        page_4_heading: 'Almost ready to go',
        page_4_1: 'The survey will take approximately 15-20 minutes. Please note that the time you take to complete the survey has no effect on the results. Please answer as spontaneously as possible. ',
        page_4_2: 'Your answers and information will be handled with ',
        page_4_3: 'absolute confidentiality.',
        page_4_4: 'By submitting at the end, you automatically confirm that you have answered the questions honestly and without help from others.',
    },
    Assessments: {
        assessment: 'Assessment',
        progress_of_user: 'Progress of ',
        current_fill_time: 'Current fill time: ',
        profile_creation_heading: 'Your profile will be created now. Please be patient.',
        profile_creation_1: 'Thank you for your time, your communication and thinking preferences profile is now being created.',
        profile_creation_2: 'Please wait for your profile to be created. This may take a few seconds to minutes. During this process, the page must always remain open.',
        completed_heading: 'Congratulations, ',
        completed: 'You have now completed the Communication and Thinking styles assessments. Your contact person will get in touch with you shortly.'
    },
    Communication: {
        name: 'Communication',
        heading: 'What fits you best?',
        introduction_1: 'On the following pages you will find 56 sections, each with two character statements.',
        introduction_2: 'For each section, select the statement which fits you best. Have situations with your peers in mind - preferably from your professional life, but also from your private life (for example on a sports team).',
        introduction_3: 'Your selections should not be based on hierarchical relationships with superiors / family / relatives.',
        completed_heading: 'Part 1 of 2 completed!',
        completed: 'You have now completed the Communication assessment. Click "Next" to continue with the second assessment or "Back" to review your answers.',
    },
    ThinkingStyle: {
        name: 'Thinking Styles',
        heading: 'I am rather ...',
        introduction_1: 'On the following pages you will find 64 sections, each with two characteristics and their explanations.',
        introduction_2: 'Which of the two characteristics fits better to your way of thinking? Choose this characteristic.',
        btn_submit: 'Submit answers',
        completed_heading: 'Part 2 of 2 completed!',
        completed: 'You have now completed the Thinking styles assessment. Click "Submit Answers" to complete the assessments or "Back" to review your answers.'
    },
    Certificates: {
        heading_comm: 'Communication profile',
        description_comm: 'The action and communication model shows in a value-free presentation how people can be perceived in communication.',
        heading_think: 'Thinking preferences',
        description_think: 'Your profile was created based on your answers and displays your thinking preferences ranked from strong to low.',
        fill_time: 'Processing time',
        privacy_note_1: 'The data is strictly confidential and may ',
        privacy_note_2: 'only be used for internal company purposes.',
        evaluation_note: 'The profile may only be evaluated by certified persons.',
        error_processing: 'Error while processing the assessment data. The recovery of the data has failed!'
    }
}