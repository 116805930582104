import React, { useState } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext";
import { useUserAuth } from "../auth/context/UserAuthContext";
import { Container, Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Modal, Button } from 'rsuite'
const logo = process.env.PUBLIC_URL + '/images/alphatop/logo.png'

const Header = () => {
    const { t, alphaRecruitUrlParams, language, handleLanguageChange, restartAssessment, setActiveComponent, tempDataIsSet } = useGlobalState();
    const { user } = useUserAuth();
    const [openDialog, setOpenDialog] = useState(false)

    const handleLogoOnClick = () => {
        setActiveComponent('Home');
        sessionStorage.clear();
    }

    const ResetAssessment = () => {
        return (tempDataIsSet && alphaRecruitUrlParams?.res_id &&
            <>
                <div className="modal-container">
                    <Modal backdrop="static" role="alertdialog" open={openDialog} onClose={() => setOpenDialog(false)} size="xs">
                        <Modal.Body>
                            {t('Header.alert_reset_assessment')}<br />
                            <b>{t('Header.warning_reset_assessment')}</b>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={restartAssessment} variant="primary">{t('Header.yes_restart_assessment')}</Button>
                            <Button onClick={() => setOpenDialog(false)} variant="secondary">{t('App.cancel')}</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                <Nav.Link onClick={() => setOpenDialog(true)}>{t('Nav.restart_assessment')}</Nav.Link>
            </>
        )
    }

    return (
        <>
            <Navbar className='navbar position-relative bg-white' id='navbar' expand="sm">
                <Container className="justify-content-center text-align-center" id='nav-container'>
                    {user &&
                        user.uid ?
                        <>
                            <Navbar.Brand className='mx-auto position-absolute d-flex align-items-center' style={{ gap: "5px", color: "#112c47" }} href={process.env.PUBLIC_URL} onClick={() => handleLogoOnClick()}>
                                <img src={logo} height="28" id='logo_header' alt='ALPHATOP Assessment Center' />
                                <b id="heading_header" style={{ fontSize: "18px", lineHeight: 2 }}> ASSESSMENT </b>
                            </Navbar.Brand>
                            <Navbar.Collapse>
                                <Nav>
                                    <LinkContainer to="/dashboard">
                                        <Nav.Link>{t("Nav.dashboard")}</Nav.Link>
                                    </LinkContainer>
                                    <ResetAssessment />
                                </Nav>
                            </Navbar.Collapse>
                        </>
                        : <Navbar.Brand className='mx-auto position-absolute d-flex align-items-center' style={{ gap: "5px", color: "#112c47" }}>
                            <img src={logo} height="28" id='logo_header' alt='ALPHATOP Assessment Center' />
                            <b id="heading_header" style={{ fontSize: "18px", lineHeight: 2 }}> ASSESSMENT </b>
                        </Navbar.Brand>
                    }
                    <NavDropdown className='ms-auto' value={language} title={<img src={process.env.PUBLIC_URL + '/images/flags/' + language + '.png'} height='24' alt='language' />}>
                        <Dropdown.Item onClick={() => handleLanguageChange('de')}><img src={process.env.PUBLIC_URL + '/images/flags/de.png'} alt='Deutsch' height='24' /></Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLanguageChange('en')}><img src={process.env.PUBLIC_URL + '/images/flags/en.png'} alt='English' height='24' /></Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLanguageChange('pl')}><img src={process.env.PUBLIC_URL + '/images/flags/pl.png'} alt='Polski' height='24' /></Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLanguageChange('ro')}><img src={process.env.PUBLIC_URL + '/images/flags/ro.png'} alt='Română' height='24' /></Dropdown.Item>
                        <Dropdown.Item onClick={() => handleLanguageChange('hu')}><img src={process.env.PUBLIC_URL + '/images/flags/hu.png'} alt='Magyar' height='24' /></Dropdown.Item>
                    </NavDropdown>
                </Container>
            </Navbar>
        </>
    )
}

export default Header
