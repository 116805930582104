export const TRANSLATIONS_RO = {
    App: {
        lang: 'ro',
        title: 'Centre de evaluare',
        welcome: 'Bine ai venit!',
        page: 'Pagina',
        out_of: 'din',
        btn_start: 'Începe',
        btn_start_now: 'Începe acum',
        btn_back: 'Înapoi',
        btn_next: 'Înainte',
        btn_previous_page: 'Pagina anterioară',
        btn_next_page: 'Pagina următoare',
        cancel: 'Anulează',
        loading: 'Încărcare...',
        name: 'Nume',
        email: 'Adresa de e-mail',
        company: 'Organizație',
        operating_unit: 'Filiala'
    },
    Nav: {
        dashboard: 'Internal dashboard',
        restart_assessment: 'Restart Assessment (Admin)',
        certificates: 'Certificate archive',
        recovery: 'Recover certificates',
        account_settings: 'Account settings',
        config: 'Configuration',
        users: 'User management',
        log_out: 'Log Out'
    },
    Header: {
        alert_reset_assessment: 'Do you really want to reset the assessment?',
        warning_reset_assessment: 'This function may only be used if the applicant cannot otherwise continue processing.',
        yes_restart_assessment: 'Yes, reset assessment',
        alert_assessment_reset: 'The assessment has been reset.'
    },
    Home: {
        find_your_strengths: 'Găsește-ți punctele forte!',
        quote_hamlet: '\'Să fii pregătit înseamnă totul!\'',
        alpha_recruit_error: 'ALPHA Recruit Eroare: Legătură invalidă',
        alert_user_not_found: 'Ne pare rău, acest link nu este valabil. Vă rugăm să luați legătura cu persoana de contact.'
    },
    Introduction: {
        introduction: 'Introducere',
        page_1_heading: 'Bine ați venit',
        page_1_1: 'În paginile următoare veți afla cum să completați Centrul de evaluare.',
        page_1_2: 'Vă rugăm să rețineți că această evaluare trebuie să fie completată numai de persoanele cu vârsta de 15 ani și peste.',
        page_1_3: 'Acest sondaj își propune să vă ofere un feedback nuanțat cu privire la modul preferat de a face lucrurile în viața de zi cu zi. Următoarele întrebări includ afirmații despre modul dvs. personal de a comunica și de a gândi în diferite situații care ar putea fi importante pentru activitățile dvs. profesionale.',
        page_2_heading: 'Consimțământul pentru prelucrarea datelor',
        page_2_1: 'Înainte de a începe evaluarea, te informăm cu privire la modul în care prelucrăm datele tale și la modul în care îți poți da consimțământul pentru prelucrarea datelor.',
        page_2_2: 'Te rugăm să citești informațiile și apoi să bifezi căsuța de mai jos, pentru ați da consimțământul.',
        page_2_3: ' Sunt de acord cu stocarea datelor mele cu caracter personal. Toate informațiile cu caracter personal vor fi tratate confidențial și cu cea mai mare atenție. Datele vor fi puse doar la dispoziția departamentului de resurse umane.',
        data_protection_info: 'Citește politica de confidențialitate',
        alert_data_storage: 'Vă rugăm să bifați căsuța de mai sus pentru a fi de acord cu stocarea datelor dvs. personale.',
        page_3_heading: 'Iată cum funcționează',
        page_3_1: 'Pe paginile următoare ți se vor afișa diverse afirmații. Te rugăm să le clasifici așa cum ți se potrivesc ție personal.',
        page_3_2: 'Răspunsurile nu pot fi nici corecte, nici greșite.',
        page_3_3: 'Numai la o completare sinceră, rezultatele tale vor fi cât mai logice și cât mai corecte. Așa vei obține un rezultat care te caracterizează.',
        page_3_4: 'Dacă sunt descrise situații pe care nu le-ai experimentat încă personal - atunci te rugăm să evaluezi modul în care te-ai comporta cel mai probabil în ele.',
        page_3_5: 'Dacă ți se pare dificil să evaluezi afirmațile individuale, te rugăm selectează afirmația care ți se potrivește cel mai bine.',
        page_4_heading: 'Suntem pe cale să începem',
        page_4_1: 'Va dura aproximativ 15-20 de minute pentru a finaliza. Timpul de care ai nevoie nu are niciun efect asupra rezultatului tău. Te rugăm să răspunzi cât mai spontan posibil.',
        page_4_2: 'Desigur, tratăm răspunsurile și rezultatele tale cu ',
        page_4_3: 'o confidențialitate absolută.',
        page_4_4: 'La final, trimițând formularul, confirmi automat că ai răspuns la întrebări sincer și fără ajutorul altora.',
    },
    Assessments: {
        assessment: 'Evaluare',
        progress_of_user: 'Progress of ',
        current_fill_time: 'Current fill time: ',
        profile_creation_heading: 'Profilul dvs. va fi creat acum. Vă rugăm să aveți răbdare.',
        profile_creation_1: 'Vă mulțumim pentru timpul acordat, profilul dvs. de comunicare și preferințele de gândire sunt în curs de creare.',
        profile_creation_2: 'Vă rugăm să așteptați crearea profilului dumneavoastră. Acest lucru poate dura câteva secunde sau minute. În timpul acestui proces, pagina trebuie să rămână mereu deschisă.',
        completed_heading: 'Felicitări, ',
        completed: 'Tocmai ai încheiat evaluările "comunicarea" și "stilul de gândire". Persoana ta de contact va lua în scurt timp legătura cu tine.'
    },
    Communication: {
        name: 'Comunicarea',
        heading: 'Ce ți se potrivește cel mai bine?',
        introduction_1: 'Pe paginile următoare vei găsi 56 de secțiuni, fiecare cu câte două afirmații de caracterizare. În fiecare secțiune, alege propoziția care ți se potrivește cel mai bine..',
        introduction_2: 'Ai in vedere de preferință situațile din viața profesională, dar și din cea privată (de exemplu la clubul sportiv) între colegii de echipă de același "rang".',
        introduction_3: 'Selecția ta nu trebuie sa fie influențată în raport cu superiorii/ familia/ rudele.',
        completed_heading: 'Partea 1 din 2 a fost finaliztă!',
        completed: 'Acum ai finalizat evaluarea comunicării. Apasă pe "Mai departe" pentru a trece la a doua evaluare sau pe "Înapoi" pentru a-ți revizui selecția.',
    },
    ThinkingStyle: {
        name: 'Stil de gândire',
        heading: 'Sunt mai degrabă ...',
        introduction_1: 'Pe paginile următoare vei găsi 64 de secțiuni, fiecare cu câte două trăsături și explicațiile aferente.',
        introduction_2: 'Care dintre cele două trăsături se potrivește mai bine mentalității tale? Selectează această trăsătură.',
        btn_submit: 'Trimite răspunsul',
        completed_heading: 'Partea 2 din 2 a fost finaliztă!',
        completed: 'Acum ai finalizat evaluarea stilului de gândire. Apasă pe "Trimite răspunsul" pentru a încheia evaluare sau pe "Înapoi" pentru a-ți revizui selecția.'
    },
    Certificates: {
        heading_comm: 'PROFILUL DE COMUNICARE',
        description_comm: 'Acest model de acțiune și comunicare prezintă într-o manieră lipsită de judecăți modul în care oamenii pot fi percepuți în comunicare.',
        heading_think: 'PROFILUL TIPULUI DE GÂNDIRE',
        description_think: 'Profilul tău a fost creat pe baza răspunsurilor furnizate și evidențiază stilul tău de gândire printr-o prezentare lipsită de judecăți.',
        fill_time: 'Durata de prelucrare',
        privacy_note_1: 'Datele sunt strict secrete și sunt vizibile ',
        privacy_note_2: 'doar pentru tine și pentru partenerul tău.',
        evaluation_note: 'Profilul poate fi evaluat doar de către persoane certificate.'
    }
}