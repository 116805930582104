export const CHARACTERIZATIONS_THINK_PL = [
    ["Bezstronny", "Jestem otwarty i chętnie przyjmuję nowe pomysły i perspektywy, nawet jeśli różnią się od moich.", "Wielozadaniowość", "Jestem w stanie jednocześnie wykonywać wiele różnorodnych zadań."],
    ["Kreatywny", "Lubię działać twórczo, mieć pomysły i realizować je w praktyce.", "Entuzjastyczny", "Łatwo angażuję się w coś z dużym entuzjazmem."],
    ["Konceptualny", "Samodzielnie tworzę i opracowuję różne rzeczy (np. propozycje, opracowania, plany itp.).", "Praktyczny", "Wolę konkretną akcję niż teoretyczne rozważania."],
    ["Z wyobraźnią", "Potrafię wyobrazić sobie rzeczy niemożliwe do uchwycenia przez zmysły lub nieistniejące w rzeczywistości. Potrafię podejść do problemów w nowatorski sposób.", "Podejście ilościowe", "Tendencja do analizowania i oceniania faktów według mierzalnych kryteriów. Lubię korzystać z danych liczbowych."],
    ["Typ eksploratora", "Chętnie wchodzę w nieznane obszary i poszukuję nowych pomysłów lub koncepcji.", "Kreatywny", "Lubię działać twórczo, mieć pomysły i realizować je w praktyce."],
    ["Strategiczny", "Potrafię myśleć strategicznie, dostrzegać związki między rzeczami oraz ich wpływ.", "Namiętny", "Moje emocje i pomysły potrafią silnie na mnie oddziaływać."],
    ["Zbiorczy", "Skupiam się na całości, a nie poszczególnych rzeczach", "Zorganizowany", "Lubię planować przebieg projektu tak, aby tworzył spójną całość."],
    ["Śmiały", "Jestem gotów podejmować ryzyko i dostrzegać w nim szanse.", "Logiczny", "Myślę logicznie i konsekwentnie zgodnie z prawami i zależnościami."],
    ["Namiętny", "Moje emocje i pomysły potrafią silnie na mnie oddziaływać.", "Integrujący", "Mam zdolność łączenia części i elementów pomysłów, koncepcji i sytuacji w całość."],
    ["Pomocny", "Chętnie oferuję innym", "Intuicyjny (emocjonalny)", "Mam wyczucie nastawienia innych ludzi i mogę polegać na moim uczuciu sympatii i antypatii bez racjonalnego uzasadnienia."],
    ["Uczuciowy", "Intensywnie odczuwam rzeczy i potrafię wyrazić swoje emocje.", "Wytrwały", "Jestem wytrzymały i zdeterminowany w dążeniu do swoich celów."],
    ["Przyjazny", "Chętnie jestem miły, uprzejmy i serdeczny.", "Orientacja na fakty", "Ważne dla mnie jest posiadanie dokładnych faktów i kierowanie się nimi podczas podejmowania decyzji."],
    ["Empatyczny", "Często rozumiem uczucia innych i potrafię wczuć się w sytuację innych ludzi.", "Konceptualny", "Samodzielnie tworzę i opracowuję różne rzeczy (np. propozycje, opracowania, plany)."],
    ["Harmonizujący", "Skłonność do harmonizowania rzeczy i uczuć.", "Pomocny", "Chętnie oferuję innym swoją pomoc i wspieram ich w trudnościach."],
    ["Gościnny", "Chętnie zapraszam ludzi do siebie, aby spędzić z nimi czas.", "Szczegółowy", "Uwzględniam drobne i najmniejsze elementy sytuacji i przywiązuję do nich wagę."],
    ["Ugodowy", "Otwarcie i serdecznie odnoszę się do innych ludzi.", "Matematyczny", "Skłonność do pracy z liczbami i danymi oraz umiejętność ich przetwarzania."],
    ["Zorganizowany", "Lubię organizować przebieg projektu w celu osiągnięcia działającej całości.", "Artystyczny", "Sympatia lub talent do malowania, rysowania lub rzeźbienia. Skłonność do artystycznego podejścia w różnych obszarach życia."],
    ["Szczegółowy", "Uwzględniam drobne i najmniejsze elementy sytuacji i przywiązuję do nich wagę.", "Międzyludzki", "Budowanie i utrzymywanie relacji z wieloma różnymi ludźmi."],
    ["Zdyscyplinowany", "Jestem opanowany i konsekwentnie realizuję swoje postanowienia.", "Ustrukturyzowany", "Lubię systematyczne organizowanie i określanie ram działań."],
    ["Praktyczny", "Preferuję konkretną akcję ponad teoretyczne rozważania.", "Analityczny", "Rozkładanie rzeczy lub pomysłów na części w celu zrozumienia prawidłowości i związków między nimi."],
    ["Dominujący", "Dążę do dominacji nad innymi", "Bezstronny", "Jestem otwarty i chętnie przyjmuję nowe pomysły i perspektywy, nawet jeśli różnią się od moich."],
    ["Planista", "Chętnie ustalam z wyprzedzeniem niezbędne kroki do osiągnięcia określonego celu.", "Uczuciowy", "Intensywnie odczuwam rzeczy i potrafię wyrazić swoje emocje."],
    ["Sekwencyjny", "Rozbieranie zagadnień na części i odpowiednie ich traktowanie, czyli wykonywanie rzeczy po kolei.", "Zdyscyplinowany", "Jestem opanowany i konsekwentnie realizuję swoje postanowienia."],
    ["Punktualny", "Dla mnie ważne jest dotrzymywanie terminów i kontrolowanie czasu.", "Jednoznaczny", "Potrafię formułować jasne i jednoznaczne wypowiedzi oraz przedstawiać sprawy w zwięzły sposób."],
    ["Logiczny", "Myślenie zgodne z prawami logiki, konsekwentne i przekonywujące.", "Odważny", "Jestem gotów podejmować niepewne decyzje i dostrzegać w nich szanse."],
    ["Matematyczny", "Skłonność do pracy z liczbami i danymi oraz umiejętność ich przetwarzania.", "Ufny", "Mam pozytywne podejście do innych ludzi i chętnie na nich polegam."],
    ["Jednoznaczny", "Potrafię formułować jasne i jednoznaczne wypowiedzi oraz przedstawiać sprawy w zwięzły sposób.", "Skoncentrowany na procesie", "Chętnie ustalam określone metody postępowania, których potem mogę się trzymać."],
    ["Naukowy", "Chętnie zajmuję się prawidłowościami i faktami logicznie wyjaśnialnymi i udowodnialnymi.", "Lubi liczby", "Orientacja na działania i liczby. Skłonność do znajdowania dokładnych wartości liczbowych i wymiarów."],
    ["Wyraźny", "Lubię mieć do czynienia z jednoznacznymi wypowiedziami, zawierającymi zrozumiałe fakty.", "Z wyobraźnią", "Potrafię wyobrazić sobie rzeczy niemożliwe do uchwycenia przez zmysły lub nieistniejące w rzeczywistości. Potrafię podejść do problemów w nowatorski sposób."],
    ["Racjonalny", "Ocenianie sytuacji nie na podstawie emocji, lecz na podstawie zdrowego rozsądku i rozumu.", "Przyjazny", "Chętnie jestem miły, uprzejmy i serdeczny."],
    ["Ilościowy", "Tendencja do oceniania sytuacji na podstawie mierzalnych kryteriów. Chęć orientacji w liczbach.", "Praktyczny", "Wolę konkretną działalność niż teoretyczne rozważania."],
    ["Techniczny", "Zrozumienie i stosowanie wiedzy z dziedziny inżynierii i nauk przyrodniczych.", "Naukowy", "Chętnie zajmuję się prawidłowościami i faktami logicznie wyjaśnialnymi i udowodnialnymi."],
    ["Wielozadaniowość", "Umiejętność równoczesnego wykonywania różnorodnych zadań.", "Kreatywny", "Często mam wiele kreatywnych pomysłów i propozycji w projektach."],
    ["Integrujący", "Mam zdolność łączenia części i elementów pomysłów, koncepcji i sytuacji w całość.", "Miłośnik towarzystwa", "Kocham mieć kontakt z ludźmi i wolę pracować w zespole niż samodzielnie."],
    ["Artystyczny", "Preferencja lub talent w dziedzinie malarstwa, rysunku lub rzeźby. Skłonność do estetyczno-artystycznego kształtowania różnych obszarów życia.", "Pracowity", "Czerpię radość z pracy."],
    ["Odważny", "Jestem gotów podejmować niepewne decyzje i dostrzegać w nich szanse.", "Krytyczny", "Staranne sprawdzanie i ocenianie, na przykład projektów lub pomysłów. Często zachowuję postawę oczekiwania."],
    ["Kreatywny", "Często mam wiele kreatywnych pomysłów i propozycji w projektach.", "Poszukiwacz", "Lubię zapuszczać się w nieznane obszary i próbować znaleźć nowe pomysły lub koncepcje."],
    ["Ciekawski", "Jestem osobą, która zadaje wiele pytań i chętnie dowiaduje się o wszystkim.", "Empatyczny", "Często rozumiem uczucia innych i potrafię wczuć się w sytuację innych ludzi."],
    ["Wizjonerski", "Mam dobrą wyobraźnię i lubię myśleć o przyszłości.", "Dominujący", "Dążę do dominacji nad innymi"],
    ["Obrazowy", "Myślenie za pomocą obrazów i porównań.", "Wyraźny", "Lubię mieć do czynienia z jasnymi wypowiedziami, które zawierają zrozumiałe fakty."],
    ["Społeczny", "Kocham mieć kontakt z ludźmi i wolę pracować w zespole niż samodzielnie.", "Ciekawski", "Jestem osobą, która zadaje wiele pytań i chętnie dowiaduje się o wszystkim."],
    ["Intuicyjny (emocjonalny)", "Mam wyczucie nastawienia innych ludzi i mogę polegać na moim uczuciu sympatii i antypatii bez racjonalnego uzasadnienia.", "Ekspresyjny", "Bardzo wyraziście i jasno przekazuję swoje poglądy."],
    ["Międzyludzki", "Budowanie i utrzymywanie relacji z wieloma różnymi ludźmi.", "Szczegółowy", "Uwzględniam małe i najmniejsze elementy w przypadku czegoś i przywiązuję do nich wagę."],
    ["Ufny", "Mam pozytywne nastawienie do innych ludzi i chętnie polegam na nich.", "Orientujący się w faktach", "Ścisłe podejście oparte wyłącznie na faktach do myślenia."],
    ["Entuzjastyczny", "Łatwo się angażuję i z wielkim entuzjazmem podejmuję się czegoś.", "Strategiczny", "Potrafię myśleć strategicznie, dostrzegać związki między rzeczami i przewidywać ich skutki."],
    ["Ekspresyjny", "Bardzo wyraziście i jasno przekazuję swoje poglądy.", "Harmonizujący", "Tendencja do harmonizowania rzeczy i uczuć."],
    ["Współpracujący", "Chętnie współpracuję z innymi nad wspólnym celem.", "Planista", "Chętnie określam z góry niezbędne kroki, aby osiągnąć określony cel."],
    ["Otwarty dla innych ludzi", "Jestem otwarty na propozycje oraz pomoc od innych ludzi.", "Racjonalny", "Patrzę na sprawy nie emocjonalnie, ale z wykorzystaniem rozumu i rozsądku."],
    ["Praktyczny", "Wolę konkretną działalność niż teoretyczne rozważania.", "Wizjonerski", "Mam wyobraźnię i chętnie myślę o przyszłości."],
    ["Wytrwały", "Jestem wytrwały i niezłomny w dążeniu do moich celów.", "Współpracujący", "Chętnie współpracuję z innymi nad wspólnym celem."],
    ["Ustrukturyzowany", "Lubię systematyczne uporządkowanie i ustalone ramy.", "Zabezpieczający", "Jestem ostrożną osobą, która starannie rozważa konsekwencje swoich działań."],
    ["Skoncentrowany na procesie", "Chętnie ustalam określone metody postępowania, których potem mogę się trzymać.", "Wyzwania", "Tendencja do kwestionowania faktów i lubię odgrywać rolę 'adwokata diabła'."],
    ["Pracowity", "Czerpię radość z pracy.", "Zbiorczy", "Potrafię dostrzegać poszczególne elementy w ich pełnym kontekście, czyli widzieć las zamiast wielu pojedynczych drzew."],
    ["Szczegółowy", "Uwzględniam małe i najmniejsze elementy w przypadku czegoś i przywiązuję do nich wagę.", "Gościnny", "Chętnie zapraszam ludzi do siebie, aby być z nimi."],
    ["Zabezpieczający", "Jestem ostrożną osobą, która starannie rozważa konsekwencje swoich działań.", "Sekwencyjny", "Rozbieram fakty na poszczególne etapy i odpowiednio je traktuję, czyli wykonuję je jeden po drugim."],
    ["Konwencjonalny", "Tendencja do trzymania się ustalonych wzorców.", "Ilościowy", "Tendencja do oceniania i wartościowania rzeczy na podstawie mierzalnych kryteriów. Często korzystam z liczb."],
    ["Matematyczny", "Skłonność do operowania liczbami i danymi oraz umiejętność ich przetwarzania.", "Przenośny", "Myślenie w obrazach i metaforach."],
    ["Obiektywny", "Myślę i działam bez uprzedzeń i rzadko daję się osobistym uczuciom.", "Otwarty dla innych ludzi", "Jestem otwarty na propozycje oraz pomoc od innych ludzi."],
    ["Analityczny", "Rozbieram rzeczy lub pomysły na części, aby dostrzec prawidłowości i zależności między nimi.", "Konwencjonalny", "Tendencja do trzymania się ustalonych wzorców."],
    ["Lubi liczby", "Orientacja na działania i liczby. Skłonność do znajdowania dokładnych wartości liczbowych i wymiarów.", "Realistyczny", "Widzę rzeczy takimi, jakie są lub się przedstawiają, unikam spekulacji i wyobrażeń."],
    ["Krytyczny", "Staranne badanie i ocenianie, na przykład projektów lub pomysłów. Moje podejście jest często wyczekujące.", "Ryzykowny", "Jestem gotowy podjąć niepewne decyzje i dostrzec w nich szanse."],
    ["Orientujący się w faktach", "Ścisłe podejście oparte wyłącznie na faktach do myślenia.", "Ugodowy", "Otwarcie i serdecznie odnoszę się do innych ludzi."],
    ["Wyzwania", "Tendencja do kwestionowania faktów i lubię odgrywać rolę 'adwokata diabła'.", "Punktualny", "Dla mnie ważne jest dotrzymanie terminów i kontrolowanie mojego czasu."],
    ["Realistyczny", "Widzę rzeczy takimi, jakie są lub się przedstawiają i unikam spekulacji i wyobrażeń.", "Techniczny", "Rozumiem i stosuję wiedzę z zakresu inżynierii i nauk przyrodniczych."]
]