export const TRANSLATIONS_HU = {
    App: {
        lang: "hu",
        title: "Értékelő Központ",
        welcome: "Üdvözöljük",
        page: "Oldal",
        out_of: "ból",
        btn_start: "Start",
        btn_start_now: "Kezdjük",
        btn_back: "Vissza",
        btn_next: "Tovább",
        btn_previous_page: "Előző oldal",
        btn_next_page: "Következő oldal",
        cancel: "Mégse",
        loading: "Betöltés...",
        name: "Név",
        email: "E-mail cím",
        company: "Vállalat",
        operating_unit: "Üzemeltetési egység"
    },
    Nav: {
        dashboard: 'Internal dashboard',
        restart_assessment: 'Restart Assessment (Admin)',
        certificates: 'Certificate archive',
        recovery: 'Recover certificates',
        account_settings: 'Account settings',
        config: 'Configuration',
        users: 'User management',
        log_out: 'Log Out'
    },
    Header: {
        alert_reset_assessment: 'Do you really want to reset the assessment?',
        warning_reset_assessment: 'This function may only be used if the applicant cannot otherwise continue processing.',
        yes_restart_assessment: 'Yes, reset assessment',
        alert_assessment_reset: 'The assessment has been reset.'
    },
    Home: {
        find_your_strengths: "Találd meg az erősségeidet!",
        quote_hamlet: "A felkészültség a legfontosabb!",
        alpha_recruit_error: "ALPHA Recruit hiba: Érvénytelen link",
        alert_user_not_found: "Sajnos ez a link érvénytelen. Kérlek, vedd fel a kapcsolatot az illetékes kapcsolattartóddal."
    },
    Introduction: {
        introduction: "Bevezetés",
        page_1_heading: "Üdvözöljük",
        page_1_1: "A következő oldalakon megtudhatja, hogyan töltse ki az értékelő központot.",
        page_1_2: "Ennek célja, hogy visszajelzést kapjon az egyéni gondolkodásmódjáról és mindennapi kommunikációs viselkedéséről.",
        page_1_3: "Kérjük, vegye figyelembe, hogy az értékelés csak 15 éves kor felett tölthető ki.",
        page_2_heading: "Hozzájárulás az adatfeldolgozáshoz",
        page_2_1: "Az értékelés megkezdése előtt tájékoztatjuk Önt arról, hogyan kezeljük az Ön adatait, és hogyan adja hozzájárulását az adatkezeléshez.",
        page_2_2: "Kérjük, olvassa el a tájékoztatást, majd jelölje be az alábbi négyzetet, hogy hozzájárulását adja.",
        page_2_3: "Hozzájárulok személyes adataim tárolásához. Minden személyes adatot bizalmasan és a legnagyobb gondossággal kezelünk. Az adatokat csak a személyzeti osztály számára teszik hozzáférhetővé.",
        data_protection_info: "Adatvédelmi információk elolvasása",
        alert_data_storage: "Kérjük, jelölje be a fenti négyzetet, ha hozzájárul személyes adatainak tárolásához.",
        page_3_heading: "És ez így működik",
        page_3_1: "A következő oldalakon különböző állítások jelennek meg. Kérjük, sorolja be őket aszerint, hogy személy szerint hogyan vonatkoznak Önre.",
        page_3_2: "Nincsenek sem helyes, sem helytelen válaszok.",
        page_3_3: "Csak az őszinte válaszok biztosíthatják, hogy eredményei logikusak és koherensek legyenek. Így lesz a legtöbb haszna a kérdőívnek.",
        page_3_4: "Ha olyan helyzeteket írnak le, amelyeket még nem tapasztalt személyesen, kérjük, aszerint értékelje, hogy valószínűleg hogyan viselkedne ezekben a helyzetekben.",
        page_3_5: "Ha nehezen tudja értékelni az egyes állításokat, kérjük, válassza ki azt az állítást, amelyik a legjobban illik magára.",
        page_4_heading: "Mindjárt készen áll",
        page_4_1: "Körülbelül 15-20 percet vesz igénybe a kérdőív kitöltése. A szükséges idő nem befolyásolja az eredményt. Kérjük, válaszoljon a lehető legspontánabb módon.",
        page_4_2: "Természetesen válaszait és eredményeit",
        page_4_3: "abszolút bizalmasan kezeljük.",
        page_4_4: "Az űrlap elküldésével a végén automatikusan megerősíti, hogy őszintén és mások segítsége nélkül válaszolt a kérdésekre."
    },
    Assessments: {
        assessment: "Értékelés",
        progress_of_user: 'Felhasználó folyamata: ',
        current_fill_time: 'Jelenlegi kitöltési idő: ',
        profile_creation_heading: 'Az Ön profilja most jön létre. Kérjük, legyen türelemmel.',
        profile_creation_1: 'Köszönjük, hogy időt szakított ránk, a kommunikációs és gondolkodási stílus profilja most jön létre.',
        profile_creation_2: 'Kérjük, várjon a profiljának létrehozására. Ez néhány másodpercet vagy percet vehet igénybe. A folyamat során az oldalnak folyamatosan nyitva kell maradnia.',
        completed_heading: 'Gratulálunk,',
        completed: 'Ön most már kitöltötte a kommunikációs és gondolkodási stílusfelmérést. Kapcsolattartója hamarosan jelentkezni fog.'
    },
    Communication: {
        name: 'Kommunikáció',
        heading: 'Mi illik magához a legjobban?',
        introduction_1: 'A következő oldalakon 56 szakasz található, mindegyikhez két Önre jellemző mondat tartozik. Válassza ki minden szakaszból azt a mondatot, amelyik a legjobban illik Önre.',
        introduction_2: 'A szituációk szakmai életére fognak vonatkozni, de a magánéletéből (pl. egy sportklubban szerzett tapasztalat alapján) is származhatnak, minden esetben az "egyenrangú" csapattársak közötti viselkedés formáját tartsa szem előtt.',
        introduction_3: 'Az Ön választását ne befolyásolják a felettesek/családtagok/rokonok hierarchikus viszonyai.',
        completed_heading: 'A kérdőív első felével elkészült!',
        completed: 'Ezzel befejezte a kérdőív első felét ami a kommunikáció témakör volt. Kattintson a "Tovább" gombra a második rész folytatásához, vagy a "Vissza" gombra a válaszai felülvizsgálatához.',
    },
    ThinkingStyle: {
        name: 'Gondolkodásmód',
        heading: 'Én inkább ...',
        introduction_1: 'A következő oldalakon 64 szakasz található, mindegyikben két tulajdonsággal és a hozzájuk tartozó magyarázatokkal fog találkozni.',
        introduction_2: 'A két tulajdonság közül melyik illik jobban az Ön gondolkodásmódjához? Válassza ezt a tulajdonságot.',
        btn_submit: 'VÁLASZ KÜLDÉSE',
        completed_heading: 'A kérdőív második felével elkészült!',
        completed: 'Ön most befejezte a gondolkodási stílus felmérését. Kattintson a "Válaszok elküldése" gombra az értékelések befejezéséhez vagy a "Vissza" gombra a kiválasztás ellenőrzéséhez.'
    },
    Certificates: {
        heading_comm: 'Communication profile',
        description_comm: 'The action and communication model shows in a value-free presentation how people can be perceived in communication.',
        heading_think: 'Thinking preferences',
        description_think: 'Your profile was created based on your answers and displays your thinking preferences ranked from strong to low.',
        fill_time: 'Processing time',
        privacy_note_1: 'The data is strictly confidential and may ',
        privacy_note_2: 'only be used for internal company purposes.',
        evaluation_note: 'The profile may only be evaluated by certified persons.',
        error_processing: 'Error while processing the assessment data. The recovery of the data has failed!'
    }
}