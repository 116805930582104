import React, { useState, useEffect } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { Alert } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import { Document, Page, pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
const PrivacyPolicyDSGVO = process.env.PUBLIC_URL + '/Pflichtinformationen.pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();

const Introduction = () => {
    const { t, setActiveComponent, activePage, setActivePage } = useGlobalState();
    const [error, setError] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        document.querySelector("#content").style.background = '#fafafa';
    }, []);

    const onClickHandler = () => {
        if (activePage === '2' && !document.getElementById('checkbox').checked) {
            setError(t('Introduction.alert_data_storage'));
        } else if (activePage === '4') {
            setActiveComponent('Communication');
            setActivePage('Introduction');
        } else {
            setError('');
            setActivePage(String(Number(activePage) + 1));
        }
    }

    const showModal = () => {
        setIsOpen(true);
    };

    const hideModal = () => {
        setIsOpen(false);
    };

    const renderIntroductionPage = () => {
        if (activePage === '1') {
            return (
                <>
                    <h2 className='mb-4'>{t("Introduction.page_1_heading")}</h2>
                    <p>{t("Introduction.page_1_1")}</p>
                    <p>{t("Introduction.page_1_2")}</p>
                    <p>{t("Introduction.page_1_3")}</p>
                </>
            )
        } else if (activePage === '2') {
            return (
                <>
                    <h3 className='mb-4'>{t("Introduction.page_2_heading")}</h3>
                    <p>{t("Introduction.page_2_1")}<br />{t("Introduction.page_2_2")}</p>
                    <button className="btn btn-secondary my-1" onClick={showModal}>{t('Introduction.data_protection_info')}</button>
                    <p className='my-3'><label><input id='checkbox' type='checkbox' />{t("Introduction.page_2_3")}</label></p>
                    {error && <Alert variant="danger">{error}</Alert>}
                </>
            )
        } else if (activePage === '3') {
            return (
                <>
                    <h3 className='mb-4'>{t("Introduction.page_3_heading")}</h3>
                    <p>{t("Introduction.page_3_1")}</p>
                    <p><b>{t("Introduction.page_3_2")}</b><br />{t("Introduction.page_3_3")}</p>
                    <p>{t("Introduction.page_3_4")}</p>
                    <p>{t("Introduction.page_3_5")}</p>
                </>
            )
        } else if (activePage === '4') {
            return (
                <>
                    <h3 className='mb-4'>{t("Introduction.page_4_heading")}</h3>
                    <p>{t("Introduction.page_4_1")}</p>
                    <p>{t("Introduction.page_4_2")}<b>{t("Introduction.page_4_3")}</b></p>
                    <p>{t("Introduction.page_4_4")}</p>
                </>
            )
        }
    }


    const PdfViewer = () => {
        const [numPages, setNumPages] = useState(null);
        const [pageNumber, setPageNumber] = useState(1);

        function onDocumentLoadSuccess({ numPages }) {
            setNumPages(numPages);
        }

        return (
            <div>
                <Document file={PrivacyPolicyDSGVO} onLoadSuccess={onDocumentLoadSuccess}>
                    <Page pageNumber={pageNumber} scale={1.3} dpi={300} renderTextLayer={false} />
                </Document>
                <div className='pdf-footer'>
                    <button className='btn btn-secondary' style={{ float: 'left', fontSize: '14px', visibility: pageNumber === 1 ? "hidden" : "visible" }} onClick={() => setPageNumber(pageNumber - 1)}>{t("App.btn_previous_page")}</button>
                    <span className='page-text'>{t("App.page")} {pageNumber} / {numPages}</span>
                    <button className='btn btn-secondary' style={{ float: 'right', fontSize: '14px', visibility: pageNumber === 2 ? "hidden" : "visible" }} onClick={() => setPageNumber(pageNumber + 1)}>{t("App.btn_next_page")}</button>
                </div>
            </div>
        )
    };

    return (
        <div className='col-md-10 col-xl-9 p-3'>
            {renderIntroductionPage()}
            <div className='text-intro my-2 text-center' style={{ lineHeight: '40px', paddingTop: '.8rem', color: '#A9A9A9', fontSize: '14px' }}>
                <button className='btn btn-secondary' style={{ float: 'left', visibility: activePage === '1' ? "hidden" : "visible" }} onClick={() => setActivePage(String(Number(activePage) - 1))}>{t("App.btn_back")}</button>
                <span className='page-text'>{t("Introduction.introduction")} - {t("App.page")} {activePage} / 4</span>
                <button className='btn btn-primary' style={{ float: 'right' }} onClick={onClickHandler}>{activePage === '4' ? t("App.btn_start") : t("App.btn_next")}</button>
            </div>
            <Modal show={isOpen} onHide={hideModal}>
                <Modal.Header style={{ justifyContent: 'end' }}>
                    <button className='btn btn-secondary' onClick={hideModal}>X</button>
                </Modal.Header>
                <Modal.Body className='p-0' style={{ overflowY: 'auto' }}>
                    <PdfViewer />
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Introduction
