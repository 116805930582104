import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useUserAuth } from "../auth/context/UserAuthContext";
import { useCert } from "../contexts/CertificatesContext"
import { Form, Table, Button, Alert } from "react-bootstrap"
import axios from 'axios'
import CertificateCommunication from './CertificateCommunication'
import CertificateThinkingStyle from './CertificateThinkingStyle'

const Recovery = () => {
    const { t, API_PATH, activePage: completed, setActivePage: setCompleted, setStyleInternal, setAlphaRecruitUrlParams, setUserData, setValuesComm, setValuesThink, valuesComm, valuesThink } = useGlobalState()
    const { generateAssessments, error, setError } = useCert()
    const { userAccessRights } = useUserAuth()
    const [name, setName] = useState("")
    const [userlist, setUserlist] = useState([])
    const [valuesCert, setValuesCert] = useState({})
    const [areValuesSet, setAreValuesSet] = useState(false)

    useEffect(() => {
        setStyleInternal()
    }, [setStyleInternal])

    const readParticipantFromDB = useCallback((name) => {
        axios.post(API_PATH + 'read-certs-recovery.php', { name: name })
            .then(res => {
                setUserlist([])
                if (!areValuesSet) {
                    if (res.data.name) {
                        if (res.data.id_comm && res.data.id_think) {
                            setValuesCert({ date: res.data.date, fillTimeComm: res.data.fillTimeComm, fillTimeThink: res.data.fillTimeThink })
                            setAlphaRecruitUrlParams({ res_id: res.data.res_id, res_model: res.data.res_model, token: res.data.token })
                            setUserData({ name: res.data.name, company: res.data.company, branch: res.data.branch })
                            setValuesComm({ value_a: res.data.value_a, value_b: res.data.value_b, value_c: res.data.value_c, value_d: res.data.value_d, value_e: res.data.value_e, value_f: res.data.value_f, value_g: res.data.value_g, value_h: res.data.value_h, fillTime: res.data.fillTimeComm })
                            setValuesThink({ value_yellow: res.data.value_yellow, value_red: res.data.value_red, value_green: res.data.value_green, value_blue: res.data.value_blue, fillTime: res.data.fillTimeThink })
                            setAreValuesSet(true)
                            setCompleted(false)
                        } else {
                            setError(t('Certificates.error_processing'))
                        }
                    } else {
                        if (res.data.length > 0) {
                            for (let i in res.data) {
                                if ((userAccessRights.company === res.data[i].company_short || userAccessRights.company === 'Gesamt' || userAccessRights.company === 'OMS')) {
                                    if ((userAccessRights.operatingUnits.map(x => { return (res.data[i].branch.includes(x)) }).includes(true) || userAccessRights.operatingUnits.includes('Gesamt'))) {
                                        setUserlist(prevItems => [...prevItems, { name: res.data[i].name }])
                                        setError('')
                                    }
                                }
                            }
                        } else {
                            setError(t('Recovery.no_results', { ns: "internal" }) + name + '.')
                        }
                    }
                }
            })
            .catch(e => { setError(e.message) })
    }, [t, API_PATH, areValuesSet, setAlphaRecruitUrlParams, setUserData, setValuesComm, setValuesThink, setCompleted, setError, userAccessRights])

    const handleRecoverCert = async (generatePDF) => {
        setCompleted(false)
        document.getElementById('container-recover').style.transform = "scale(1)"
        await generateAssessments(generatePDF)
        document.getElementById('container-recover').style.transform = "scale(0.75)"
        setCompleted(false)
    }

    const openCertFromUserlist = (name) => {
        setName(name)
        readParticipantFromDB(name)
    }

    const Userlist = () => {
        let content = []
        for (let i in userlist) {
            content.push(
                <tr key={'trUserlist' + i}>
                    <td><Button variant="primary" onClick={() => openCertFromUserlist(userlist[i].name)}>{userlist[i].name}</Button></td>
                </tr>
            )
        }
        return content
    }

    return (
        <div className='loading mt-3 p-3'>
            {error && <Alert className='mb-2' variant="danger">{error}</Alert>}
            {areValuesSet ?
                Object.keys(valuesComm).length > 0 && Object.keys(valuesThink).length > 0 &&
                <div id='container-recover'>
                    <div className='print-hide d-flex justify-content-center'>
                        {completed && <Alert className='mb-4' style={{ fontSize: '1.25rem', lineHeight: '2' }} variant="success">{t("Recovery.alert_certificates_saved", { ns: "internal" })}</Alert>}
                    </div>
                    <div className='m-2 print-hide d-flex flex-row justify-content-center'>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => handleRecoverCert(false)}>{t("Recovery.btn_save_in_archive", { ns: "internal" })}</Button>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => handleRecoverCert(true)}>{t("Recovery.btn_download_pdf", { ns: "internal" })}</Button>
                        <Button variant="primary" className='mx-2' style={{ fontSize: '1.25rem', lineHeight: '1.6' }} onClick={() => window.print()}>{t("Recovery.btn_print", { ns: "internal" })}</Button>
                    </div>
                    <div id='cert-container' className='d-flex mt-5 loading opacity-100' style={{ backgroundColor: '#fff !important' }}>
                        <CertificateCommunication date={valuesCert.date} fillTime={valuesCert.fillTimeComm} valuesComm={valuesComm} />
                        <CertificateThinkingStyle date={valuesCert.date} fillTime={valuesCert.fillTimeThink} valuesThink={valuesThink} />
                    </div>
                </div>
                : <>
                    <h4 className='m-4 print-hide'>{t("Recovery.heading", { ns: "internal" })}</h4>
                    <Form autoComplete="off" onSubmit={() => readParticipantFromDB(name)} className='mb-4 print-hide d-flex justify-content-center'>
                        <Form.Group className='mx-2' controlId="formBasicDisplayName">
                            <Form.Control type="text" placeholder={t('App.name')} onChange={(e) => setName(e.target.value)} />
                        </Form.Group>
                        <Button variant="primary" onClick={() => readParticipantFromDB(name)}>{t('Internal.search', { ns: "internal" })}</Button>
                    </Form>
                    {userlist.length > 0 &&
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>{t('Internal.show', { ns: "internal" })} {userlist.length} {t('Recovery.results_for', { ns: "internal" })} "{name}":</th>
                                </tr>
                            </thead>
                            <tbody>
                                <Userlist />
                            </tbody>
                        </Table>
                    }
                </>
            }
        </div >
    )
}

export default Recovery