import React, { useEffect } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { AssessmentContext } from "../contexts/AssessmentContext"
import { CertificatesContext } from "../contexts/CertificatesContext"
import { UserAuthContextProvider } from "../auth/context/UserAuthContext"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Alert, Container } from 'react-bootstrap'

import Home from "./Home"
import HomeEmployee from "./HomeEmployee"
import Introduction from "./Introduction"
import Communication from "./Communication"
import ThinkingStyle from "./ThinkingStyle"
import Certificates from "./Certificates"
import Header from "./Header"
import Footer from "./Footer"
import NavSidebar from "./NavSidebar"
import Dashboard from "../auth/components/Dashboard"
import CertificateArchive from "./CertificateArchive"
import Recovery from "./Recovery"
import Login from "../auth/components/Login"
import Signup from "../auth/components/Signup"
import ForgotPassword from "../auth/components/ForgotPassword"
import AccountSettings from "../auth/components/AccountSettings"
import UserManagement from "../auth/components/UserManagement"
import ProtectedRoute from "../auth/components/ProtectedRoute"

import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../css/style.css'
import '../css/corporate-design.css'

function App() {
  const { activeComponent, activePage, globalError, updateTempData } = useGlobalState()

  const renderActiveComponent = (component) => {
    if (component && !activeComponent) {
      return component
    }
    switch (activeComponent) {
      case 'Home': return <Home />
      case 'HomeEmployee': return <HomeEmployee />
      case 'Introduction': return <Introduction />
      case 'Communication': return <AssessmentContext><Communication /></AssessmentContext>
      case 'ThinkingStyle': return <AssessmentContext><ThinkingStyle /></AssessmentContext>
      case 'Certificates': return <CertificatesContext><Certificates /></CertificatesContext>
      default: return component
    }
  }

  useEffect(() => {
    updateTempData()
  }, [updateTempData])

  return (
    <div className="App d-flex flex-column" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
      <Router basename="/">
        <UserAuthContextProvider>
          <NavSidebar />
          <Header />
          <div id="content" className="flex-grow-1 d-flex flex-column overflow-auto">
            <Container
              id="main-container"
              className="p-0 d-flex flex-column align-items-center position-relative"
              style={{ minHeight: 'calc(100vh - var(--header-height) - var(--footer-height))' }}
            >
              <Routes>
                <Route
                  path="/"
                  element={
                    <Container
                      id="main-container-assessment"
                      className="position-relative box d-flex flex-column justify-content-center"
                    >
                      {globalError && <Alert className="mb-2" variant="danger">{globalError}</Alert>}
                      {renderActiveComponent(<Home />)}
                    </Container>
                  }
                  key={document.location.href}
                />
                <Route
                  path="/mitarbeiter"
                  element={
                    <Container
                      id="main-container-assessment"
                      className="position-relative box d-flex flex-column justify-content-center"
                    >
                      {globalError && <Alert className="mb-2" variant="danger">{globalError}</Alert>}
                      {renderActiveComponent(<HomeEmployee />)}
                    </Container>
                  }
                  key={document.location.href}
                />
                <Route path="/login" element={<Login />} key={document.location.href} />
                <Route path="/signup" element={<Signup />} key={document.location.href} />
                <Route path="/forgot-password" element={<ForgotPassword />} key={document.location.href} />
                <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} key={document.location.href} />
                <Route path="/cert-archive" element={<ProtectedRoute><CertificateArchive /></ProtectedRoute>} key={document.location.href} />
                <Route path="/recovery" element={<ProtectedRoute><CertificatesContext><Recovery /></CertificatesContext></ProtectedRoute>} key={document.location.href} />
                <Route path="/users" element={<ProtectedRoute><UserManagement /></ProtectedRoute>} key={document.location.href} />
                <Route path="/account-settings" element={<ProtectedRoute><AccountSettings /></ProtectedRoute>} key={document.location.href} />
              </Routes>
            </Container>
          </div>
          <Footer activePage={activePage} />
        </UserAuthContextProvider>
      </Router>
    </div>
  )
}

export default App;
