import React from "react"
import { isBrowser, isMobile } from 'react-device-detect';

const Footer = ({ activePage }) => {
    return (
        <footer id='footer' className="position-relative footer mt-auto" style={isBrowser || (isMobile && activePage !== 'Assessment') ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            <span id='copyright-note'>Powered by <b> ALPHATOP HR GmbH</b></span>
            <a href="https://alphatop.com" id="alpha-website" className='float-end'><b>ALPHATOP</b></a>
        </footer >
    )
}

export default Footer
