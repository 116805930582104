export const INTERNAL_DE = {
    Auth: {
        heading_login: 'Mitarbeiter-Login',
        heading_signup: 'Neues HR-Mitarbeiterkonto',
        password: 'Passwort',
        confirm: 'Passwort bestätigen',
        alert_passwords_must_match: "Die Passwörter müssen übereinstimmen!",
        log_in: "Anmelden",
        log_out: "Abmelden",
        sign_up: "Registrieren",
        reset_password: 'Passwort zurücksetzen',
        dont_have_an_account: "Noch kein Konto?",
        already_have_an_account: "Hast Du ein Konto?",
        forgot_password: "Passwort vergessen?"
    },
    Internal: {
        heading: 'Internes Dashboard',
        search: 'Suchen',
        show: 'Zeige',
        created: 'zul. geändert',
        edit: 'Bearbeiten',
        yes: 'ja',
        no: 'nein'
    },
    Archive: {
        placeholder: 'Nach Name, Unternehmen, OU, Abteilung filtern...',
        comm: 'Kommunikation',
        think: 'Denkstil',
        warning_delete_cert: 'Möchtest Du wirklich die Urkunden des Teilnehmers löschen?',
        yes_delete: 'Ja, Urkunden löschen'
    },
    Recovery: {
        results_for: 'Ergebnisse zu ',
        no_results: 'Keine Ergebnisse gefunden zu ',
        heading: 'Urkunden wiederherstellen',
        btn_save_in_archive: 'In Bewerbung ablegen',
        btn_download_pdf: 'PDF herunterladen & in Bewerbung ablegen',
        btn_print: 'Drucken (optimiert für Chromium)',
        alert_certificates_saved: 'Die Urkunden wurden in der Bewerbung in ALPHA-Recruit abgelegt.',
    },
    Users: {
        placeholder: 'Nach E-Mail, Unternehmen, OU filtern...',
        isAdmin: 'Ist Admin',
        active: 'Aktiv',
        edit_user: 'Benutzer bearbeiten',
        warning_delete_user: 'Möchtest Du wirklich den Benutzer löschen?',
        yes_delete: 'Ja, Benutzer löschen'
    }
}