import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useUserAuth } from "../auth/context/UserAuthContext";
import { Alert } from "react-bootstrap"
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { Modal, Button } from 'rsuite'
import { FileDownload, Trash } from '@rsuite/icons';
import axios from 'axios';
import { Document, Page, pdfjs } from 'react-pdf'
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const CertificateArchive = () => {
  const { t, API_PATH, setStyleInternal } = useGlobalState()
  const { userAccessRights } = useUserAuth()
  const [data, setData] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openedCert, setOpenedCert] = useState(false)
  const [deleteId, setDeleteId] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleOpen = (id) => {
    setDeleteId(id)
    setOpenDialog(true)
  }

  useEffect(() => {
    setStyleInternal()
  }, [setStyleInternal]);

  useEffect(() => {
    axios.post(API_PATH + 'cert-archive-data.php', { action: 'read' })
      .then(res => {
        for (let i in res.data) {
          if ((userAccessRights.company === res.data[i].company_short || userAccessRights.company === 'Gesamt' || userAccessRights.company === 'OMS')) {
            if (userAccessRights.operatingUnits && (userAccessRights.operatingUnits.map(x => { return (res.data[i].branch.includes(x)) }).includes(true) || userAccessRights.operatingUnits.includes('Gesamt'))) {
              let date = new Date(res.data[i].date)
              let timestamp_formatted = String(date.getDate()).padStart(2, '0') + "." + String(date.getMonth() + 1).padStart(2, '0') + "." + date.getFullYear().toString().substr(2) + " " + String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0')

              if (res.data[i].fpathComm && res.data[i].fpathThink) {
                setData(prevItems => [...prevItems, {
                  id: res.data[i].id,
                  name: res.data[i].name,
                  company: res.data[i].company,
                  branch: res.data[i].branch,
                  department: res.data[i].department,
                  date: timestamp_formatted,
                  certComm: <button onClick={() => setOpenedCert(API_PATH + res.data[i].fpathComm)}>{t('Archive.comm', { ns: "internal" })}</button>,
                  certThink: <button onClick={() => setOpenedCert(API_PATH + res.data[i].fpathThink)}>{t('Archive.think', { ns: "internal" })}</button>,
                  delete: userAccessRights.isAdmin === '1' && <button onClick={() => handleOpen(res.data[i].id)}><Trash /></button>,
                }])
              }
              setLoading(false)
            }
          }
        }
      })
  }, [API_PATH, t, userAccessRights])

  const columns = useMemo(
    () => [
      { Header: 'ID', accessor: 'id' },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Unternehmen', accessor: 'company' },
      { Header: 'Branch', accessor: 'branch' },
      { Header: 'Department', accessor: 'department' },
      { Header: t('Internal.created', { ns: "internal" }), accessor: 'date' },
      { Header: t('Archive.comm', { ns: "internal" }), accessor: 'certComm' },
      { Header: t('Archive.think', { ns: "internal" }), accessor: 'certThink' },
      { Header: 'Del', accessor: 'delete' }
    ],
    [t]
  )

  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, state, setGlobalFilter, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize }
  } = useTable({
    columns, data, initialState: { pageIndex: 0, hiddenColumns: ["id", "company", "branch", "department"], sortBy: [{ id: 'id', desc: true }] }
  }, useGlobalFilter, useSortBy, usePagination)

  function GlobalFilter({ globalFilter, setGlobalFilter }) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 800)

    return (
      <span>
        {t('Internal.search', { ns: "internal" })}:{' '}
        <input value={value || ""} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} placeholder={t('Archive.placeholder', { ns: "internal" })} style={{ width: '88.8%', paddingLeft: '0.25rem', marginLeft: '0.5rem', fontSize: '1rem' }} key="search" />
      </span>
    )
  }

  const DownloadIcon = ({ size }) => <FileDownload style={{ fontSize: size, margin: '0 .3rem' }} />;

  const handleDelete = useCallback(() => {
    axios.post(API_PATH + 'cert-archive-data.php', { action: 'delete', id: deleteId })
      .then(() => {
        setData(data.filter(item => item.id !== deleteId))
        handleCloseDialog()
      })
      .catch(e => { setError(e.message) })
  }, [API_PATH, data, deleteId])

  return (
    <div className="mt-4 p-3 text-start">
      {error && <Alert variant="danger">{error}</Alert>}
      <div className="modal-container">
        <Modal backdrop="static" role="alertdialog" open={openDialog} onClose={handleCloseDialog} size="xs">
          <Modal.Body>
            {t('Archive.warning_delete_cert', { ns: "internal" })}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleDelete} appearance="primary">{t('Archive.yes_delete', { ns: "internal" })}</Button>
            <Button onClick={handleCloseDialog} appearance="subtle">{t('App.cancel')}</Button>
          </Modal.Footer>
        </Modal>
      </div>
      {openedCert && (
        <Document className='pdf-viewer float-end ms-5' file={openedCert} onLoadError={console.error}>
          <Page pageNumber={1} scale={1.75} dpi={300} renderTextLayer={false} />
        </Document>
      )}
      <div className='float-start'>
        <table id='tableArchive' width='660' {...getTableProps()}>
          <thead>
            <tr>
              <th colSpan='2' className='thArchive' style={{ border: 'none', paddingLeft: '0' }}><h3>{t('Nav.certificates')}</h3></th>
              {openedCert && <td colSpan='3' className='tdArchive p-0 border-0'><a className='btn btn-primary float-end text-white' href={openedCert} download><DownloadIcon size='22px' /></a></td>}
            </tr>
            <tr>
              <th colSpan='5' className='p-2 ps-1 pe-0'>
                <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
              </th>
            </tr>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th className='thArchive' {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render('Header')}
                    <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                  </th>
                ))}
              </tr>
            ))}
            {loading &&
              <tr><th className='thArchive w-100'><img alt={t("App.loading")} src={process.env.PUBLIC_URL + '/images/gif/loading.gif'} width='200' /></th></tr>
            }
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className='tdArchive' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
        <div className="pagination mt-3 d-flex justify-content-between" style={{ lineHeight: '25px' }}>
          <select value={pageSize} className='px-1' onChange={e => { setPageSize(Number(e.target.value)) }}>
            {[10, 20, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>{t('Internal.show', { ns: "internal" })} {pageSize}</option>
            ))}
          </select>
          <span className='page-text'>{t("App.page")} <b>{pageIndex + 1} {t('App.out_of')} {pageOptions.length}</b></span>
          <div>
            <button className='btn-secondary px-2' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>{' '}
            <button className='btn-secondary px-2' onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>{' '}
            <button className='btn-secondary px-2' onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>{' '}
            <button className='btn-secondary px-2' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>{' '}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificateArchive