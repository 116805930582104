export const CHARACTERISTICS_COMM_RO = [
  ["Explic lucruri cu plăcere.", "Ajut cu plăcere."],
  ["Tind să ofer compasiune..", "Sunt întotdeauna pregătit să fac compromisuri."],
  ["Sunt prietenos cu toată lumea.", "Sunt conștiincios."],
  ["Admir personalitățile puternice.", "Mă retrag ușor în caz de conflicte."],
  ["Sunt timid - fricos.", "Contest lucrurile repede."],
  ["Nu mă tem sa pun la îndoială lucrurile acceptate în mod general.", "Nu mă las păcălit/nu mă las dus cu zăhărelul."],
  ["În caz de conflict imi susțin părerea.", "Vreau sa fiu mereu cel mai bun."],
  ["Organizez lucrurile singur cand văd că nu decurg intr-un mod eficient.", "Imi țin deoparte părerea personală pentru a evita conflicte."],
  ["Protejez persoanele nesigure.", "Respect înțelegerile făcute."],
  ["Mi se pare îngrozitor când alți oameni nu mă plac.", "Sunt modest."],
  ["Întotdeauna fac tot posibilul să ascult chiar și explicațiile plictisitoare.", "Accept doar lucrurile ale căror dovezi le pot vedea."],
  ["Nu am nicio problemă în a îmi cere scuze.", "Pot fi foarte solicitant."],
  ["Nu suport restricțile.", "Pot lua decizii independente - pot lua propriile mele decizii."],
  ["În timpul discuților dificile pot lua o decizie.", "Când mi se cere să fac ceva, fac tot posibilul să o fac."],
  ["Îi pot ierta ușor/ repede pe ceilalți.", "Ezit înainte de a face sau spune ceva."],
  ["Mă grăbesc să îi scuz pe alții.", "Mă plâng de lucrurile care au mers prost."],
  ["Sunt repede de acord cu opinia predominantă.", "Sunt drept și direct."],
  ["Mă mențin în fundal.", "Mă pricep să vorbesc bine despre propria persoană."],
  ["Într-o grupă preiau des inițiativa.", "Mă jenez ușor când lucrurile nu merg așa cum erau planificate."],
  ["Ofer altor oameni cu plăcere consolare.", "Pot să fac critici întemeiate."],
  ["Sunt de acord cu toată lumea.", "Pot pune la punct oamenii agasanți sau insistenți."],
  ["Îmi place să primesc sfaturi.", "Am grijă de/iau seama la mine însumi."],
  ["Iau decizii clare.", "Opun rezistență când ceva nu este conform regulilor."],
  ["Tind să mă simt responsabil.", "Pot fi foarte strict atunci când este nevoie."],
  ["Sunt primitor/ binevoitor.", "Nu mi-e frică să mă prezint așa cum sunt."],
  ["Mă asigur că toată lumea din echipă își asumă o parte din sarcină.", "Mă plâng fără echivoc de nedreptăți."],
  ["Mă asigur că fiecare își primește dreptatea.", "Am standarde înalte despre mine."],
  ["Preiau întotdeauna răspunderea.", "Îmi cunosc aptitudinile."],
  ["Sunt mereu pregătit să ajut.", "Pot să le dau altora instrucțiuni."],
  ["Îmi place să lucrez împreună cu alții.", "Ofer cu plăcere."],
  ["Respect autoritatea.", "Tind să am încredere în alții."],
  ["Pot practica critica de sine.", "Cer repede ajutor."],
  ["Nu sunt ușor de convins.", "Nu mă las dus ușor."],
  ["Îi păcălesc pe alții cu plăcere.", "Nu suport /\"să fiu ținut pe un frâu scurt. /\". / Îmi iubesc libertatea personală."],
  ["Pot să mă ridic chiar și în grupuri mari și să vobesc în fața altora/ cu alții.", "Pot tolera părerile diferite."],
  ["Evit greutățile.", "Le explic altora cum să procedeze. "],
  ["Mă adaptez ușor.", "Am grijă de/iau seama la mine însumi."],
  ["Îi las pe alții în pace când apar greutăți.", "Îmi las opinia personală pe plan secund pentru a evita argumentele."],
  ["Nu iau decizii necugetate.", "Respect înțelegerile făcute."],
  ["Le pot ascunde superiorilor ceva.", "Ezit înainte de a spune sau face ceva."],
  ["Mă compar cu plăcere cu alții.", "Nu sunt ușor de convins."],
  ["Întotdeauna fac tot posibilul să ascult chiar și explicațiile plictisitoare. ", "Le explic altora cum să procedeze. "],
  ["Pot practica critica de sine.", "Mă simt repede răspunzător."],
  ["Opun rezistență când ceva nu este conform regulilor.", "Nu suport când alții nu mă plac."],
  ["Pot fi foarte exigent cu alții.", "Când mi se cere să fac ceva, fac tot posibilul să o fac."],
  ["Am multă încredere în mine.", "Sunt modest."],
  ["Mă retrag ușor în caz de conflicte", "Organizez lucrurile singur cand văd că nu decurg intr-un mod eficient."],
  ["Mă revolt împotriva nedreptății.", "Sunt gata să ajut."],
  ["Mă plâng fără echivoc de nedreptăți.", "Am repede încredere în alți oameni."],
  ["Mă străduiesc să fiu cel/ cea mai bun/ă.", "Admir personalitățile puternice."],
  ["Nu mă tem sa pun la îndoială lucrurile acceptate în mod general.", "Explic cu plăcere."],
  ["Nu mă las păcălit/nu mă las dus cu zăhărelul. ", "Tind să ofer compasiune."],
  ["Iau decizii în mod independent.", "Sunt prietenos cu toată lumea."],
  ["În conflicte îmi susțin părerea.", "Iau decizii clare."],
  ["Pot să mă ridic chiar și în grupuri mari și să vobesc în fața altora/ cu alții.", "Îi protejez pe cei slabi."],
  ["Nu îmi este frică să mă prezint așa cum sunt.", "Le explic altora cum să procedeze. "],
]