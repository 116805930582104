import React, { useState } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useUserAuth } from "../auth/context/UserAuthContext"
import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom"
import { Toggle, Sidenav, Nav } from 'rsuite';
import { Dashboard, Page, UserBadge, Gear } from '@rsuite/icons';
import 'rsuite/dist/rsuite.min.css'

export const NavSidebar = () => {
  const { t } = useGlobalState();
  const { user, userAccessRights, logOut } = useUserAuth();
  const [expanded, setExpanded] = useState(false);

  const handleLogOut = async () => {
    try {
      await logOut()
      alert('Sie werden nun abgemeldet.')
    } catch (err) {
      console.log(err.message);
    }
  }

  return (<>
    {user &&
      user.uid &&
      <div className='position-fixed vh-100' style={isMobile ? { display: 'none' } : { maxWidth: 240, zIndex: 99 }}>
        <Sidenav className='h-100' expanded={expanded} defaultOpenKeys={['2']}>
          <Toggle className='p-2 mt-1' onChange={setExpanded} checked={expanded} />
          <Sidenav.Body>
            <Nav>
              <Nav.Item eventKey="1" as={Link} to="/dashboard" icon={<Dashboard />}>{t('Nav.dashboard')}</Nav.Item>
              <Nav.Menu eventKey="2" title={t('Nav.certificates')} icon={<Page />}>
                <Nav.Item eventKey="2-1" as={Link} to="/cert-archive">{t('Nav.certificates')}</Nav.Item>
                <Nav.Item eventKey="2-1" as={Link} to="/recovery">{t('Nav.recovery')}</Nav.Item>
              </Nav.Menu>
              {userAccessRights.isAdmin === '1' &&
                <Nav.Item eventKey="3" as={Link} to="/users" icon={<Gear />}>{t('Nav.users')}</Nav.Item>
              }
              <Nav.Menu eventKey="4" title={user.displayName} icon={<UserBadge />}>
                <Nav.Item eventKey="4-1" as={Link} to="/account-settings">{t('Nav.account_settings')}</Nav.Item>
                <Nav.Item eventKey="4-1" onClick={() => handleLogOut()}>{t('Nav.log_out')}</Nav.Item>
              </Nav.Menu>
            </Nav>
            <b style={{ fontSize: '12px', position: "absolute", right: '.75rem', bottom: '.75rem' }}>v1.5</b>
          </Sidenav.Body>
        </Sidenav>
      </div>
    }
  </>)
}

export default NavSidebar