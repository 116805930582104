import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { Form, Button, Alert } from "react-bootstrap"
import axios from 'axios'

const Home = () => {
    const { t, API_PATH, fetchTempData, language, handleLanguageChange, activePage, setActivePage, setActiveComponent, setUserData, Companies, Branches, selectedCompany, setSelectedCompany } = useGlobalState()
    const [name, setName] = useState('')
    const [operatingUnit, setOperatingUnit] = useState('')
    const [error, setError] = useState(false)

    useEffect(() => {
        fetchTempData('')
    }, [fetchTempData])

    const createParticipantInDB = useCallback(() => {
        axios.post(API_PATH + 'create-participant.php', { method: 'create', name: name, company: selectedCompany, branch: operatingUnit })
            .then(res => {
                setUserData({ participant_id: res.data.participant_id, name: name, company: selectedCompany, branch: operatingUnit })
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, name, operatingUnit, selectedCompany, setUserData])

    const handleSubmit = e => {
        e.preventDefault()
        createParticipantInDB()
        setActiveComponent("Introduction")
        setActivePage("1")
    }

    function renderLandingPage() {
        if (activePage === '1') {
            return (<>
                <h2 className="my-3" id='heartily-welcome'><b>{t("App.welcome")}!</b></h2>
                <h4><b>{t("Home.find_your_strengths")}</b></h4>
                <h4><b>{t('Home.quote_hamlet')}</b> (Shakespeare, Hamlet)</h4>
                <button className='btn btn-primary btn-start m-3' onClick={() => setActivePage('2')}>{t("App.btn_start")}</button>
            </>)
        } else if (activePage === '2') {
            return (<>
                <div className="mt-2 text-center col-12 col-sm-10 col-md-8 col-lg-4">
                    <h4 className="mt-2 mb-3">{t("App.title")}</h4>
                    <Form onSubmit={handleSubmit} autoComplete="off">
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Control type="text" name='name' placeholder={t('App.name')} value={name} onChange={(e) => setName(e.target.value)} required />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formLanguage">
                            <Form.Select name='language' aria-label="language" value={language} onChange={e => { handleLanguageChange(e.target.value) }} style={{ background: 'url(' + process.env.PUBLIC_URL + '/images/flags/' + language + '.png) #fff 97%/24px no-repeat' }}>
                                <option value="de">Deutsch</option>
                                <option value="en">English</option>
                                <option value="pl">Polski</option>
                                <option value="ro">Română</option>
                                <option value="hu">Magyar</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formCompany">
                            <Form.Select name='company' aria-label="company" value={selectedCompany} onChange={e => setSelectedCompany(e.target.value)} required>
                                <option disabled hidden value="" key="">{t("App.company")}</option>
                                <Companies />
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formOU">
                            <Form.Select name='operatingUnit' aria-label="operatingUnit" value={operatingUnit} onChange={e => setOperatingUnit(e.target.value)} required>
                                <option disabled hidden value="" key="">{t("App.operating_unit")}</option>
                                <Branches />
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="d-grid gap-2 mt-3">
                            <Button variant="primary" className='btn-start' type="Submit">{t('App.btn_start_now')}</Button>
                        </Form.Group>
                    </Form>
                </div>
            </>)
        }
    }

    return (
        <div id='main-text' className='col-lg-9 text-center d-flex flex-column align-items-center'>
            {!error ? (
                renderLandingPage()
            ) : (
                <tr><td><Alert className='mt-3' variant="danger">{error}</Alert></td></tr>
            )}
        </div>
    )
}

export default Home
