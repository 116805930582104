import React, { useEffect } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useCert } from "../contexts/CertificatesContext"

const CertificateCommunication = ({ date, fillTime, valuesComm }) => {
    const { t } = useGlobalState()
    const { styleAlphaA, Header, MainText, Footer } = useCert()
    const valuesLongSide = []
    const valuesShortSide = []
    let time = new Date(fillTime ? fillTime : 0).toISOString().substr(11, 8)
    const size_comm = 600

    let i = 0;
    for (var key in valuesComm) {
        if (i > 0 && i < 5) {
            valuesLongSide[key] = size_comm * 0.5 + valuesComm[key] * 18; /* scaling and positioning */
        } else {
            valuesLongSide[key] = size_comm * 0.5 - valuesComm[key] * 18; /* scaling and positioning */
        }

        if (i === 0 || i === 2 || i === 3 || i === 5) {
            valuesShortSide[key] = size_comm * 0.5 + valuesComm[key] * 18 /* scaling and positioning */ * 0.414; /* to form an octagon, one of the values per point must be changed by * 0.414 */
        } else {
            valuesShortSide[key] = size_comm * 0.5 - valuesComm[key] * 18/* scaling and positioning */ * 0.414; /* to form an octagon, one of the values per point must be changed by * 0.414 */
        }
        i++;
    }

    useEffect(() => {
        if (Object.keys(valuesComm).length > 0) {
            styleAlphaA('comm')
        }
    }, [valuesComm, styleAlphaA])

    const ShowValues = () => {
        let keys = ['value_a', 'value_b', 'value_c', 'value_d', 'value_e', 'value_f', 'value_g', 'value_h'];
        let content = [];
        let l = 8;
        for (let r = 0; r < 4; r++) {
            l--;
            content.push(
                <div id={'divComm' + r} key={'divComm' + r} className='d-flex justify-content-center'>
                    <h6 id={'comm' + l} className='comm text-start'>{valuesComm[keys[l]]}</h6>
                    <h6 id={'comm' + r} className='comm text-end'>{valuesComm[keys[r]]}</h6>
                </div>
            )
        }
        return content;
    }

    return (
        Object.keys(valuesComm).length > 0 &&
        <div id="certComm" className='cert d-flex flex-column alpha-cert' style={{ background: `url('${process.env.PUBLIC_URL}/images/comm/${t('App.lang')}.comm.png') 50% 70%/70.6% no-repeat #fff` }}>
            <Header />
            <div id='main-cert' className={'d-flex flex-column align-items-center text-center'}>
                <MainText t={t} time={time ? time : 0} date={date} strAssessment={'comm'} />
                <div id='value-container' className='position-relative'>
                    <ShowValues />
                </div>
                <div className='diagram-container backgroundComm'>
                    <svg width="600" height="600">
                        <polygon id="polygonComm" style={{ fill: 'none', strokeWidth: '2px' }} points={valuesShortSide['value_a'] + "," + valuesLongSide['value_a'] + " " + valuesLongSide['value_b'] + "," + valuesShortSide['value_b'] + " " + valuesLongSide['value_c'] + "," + valuesShortSide['value_c'] + " " + valuesShortSide['value_d'] + "," + valuesLongSide['value_d'] + " " + valuesShortSide['value_e'] + "," + valuesLongSide['value_e'] + " " + valuesLongSide['value_f'] + "," + valuesShortSide['value_f'] + " " + valuesLongSide['value_g'] + "," + valuesShortSide['value_g'] + " " + valuesShortSide['value_h'] + "," + valuesLongSide['value_h']} />
                    </svg>
                </div>
            </div>
            <Footer strAssessment={'comm'} />
        </div>
    )
}

export default CertificateCommunication
