export const CHARACTERISTICS_COMM_DE = [
    ["Ich erkläre gerne Dinge.",  "Ich helfe gerne."],
    ["Ich neige dazu, Mitleid zu empfinden.",  "Ich bin immer bereit Kompromisse zu schließen."],
    ["Ich bin zu jedermann freundlich.",  "Ich bin gewissenhaft."],
    ["Ich bewundere starke Persönlichkeiten.",  "Ich ziehe mich bei Konflikten leicht zurück."],
    ["Ich bin schüchtern - furchtsam.",  "Ich bestreite Dinge schnell."],
    ["Ich habe keine Furcht, allgemein akzeptierte Dinge in Zweifel zu ziehen oder zu hinterfragen.",  "Ich lasse mir nicht auf der Nase herumtanzen."],
    ["In Konfliktfällen stehe ich zu meiner Meinung.",  "Ich möchte immer der Beste sein."],
    ["Ich organisiere Dinge selbst, wenn ich sehe, dass sie nicht effizient laufen.",  "Ich trete mit meiner persönlichen Meinung in den Hintergrund, um Konflikte zu vermeiden."],
    ["Ich schütze Menschen, die unsicher sind.",  "Ich halte mich an Vereinbarungen."],
    ["Ich finde es schrecklich, wenn andere Menschen mich nicht mögen.",  "Ich bin bescheiden."],
    ["Ich tue immer mein Bestes, um auch langweiligen Erklärungen zu folgen.",  "Ich akzeptiere nur Dinge, deren Beweis ich sehen kann."],
    ["Ich habe kein Problem damit, mich zu entschuldigen.",  "Ich kann sehr fordernd sein."],
    ["Ich kann Restriktionen nicht ertragen.",  "Ich kann unabhängige Entscheidungen treffen - ich kann eigene Entscheidungen treffen."],
    ["In schwierigen Diskussionen kann ich die Entscheidung treffen.",  "Wenn ich gebeten werde, etwas zu tun, tue ich mein Bestes, um es zu erledigen."],
    ["Ich kann anderen leicht/schnell vergeben.",  "Ich zögere, bevor ich etwas tue oder sage."],
    ["Ich entschuldige andere schnell.",  "Ich beklage mich über Dinge, die schiefgelaufen sind."],
    ["Ich schließe mich schnell der herrschenden Meinung an.",  "Ich bin geradeheraus und direkt."],
    ["Ich halte mich im Hintergrund.",  "Ich kann gut über mich selbst reden."],
    ["In der Gruppe ergreife ich oft die Initiative.",  "Ich werde schnell verlegen, wenn Dinge nicht so laufen wie geplant."],
    ["Ich spende anderen Menschen gerne Trost.",  "Ich kann fundierte Kritik üben."],
    ["Ich stimme jedem zu.",  "Ich kann lästige oder aufdringliche Menschen zurechtweisen."],
    ["Ich nehme gerne Ratschläge an.",  "Ich passe auf mich selbst auf."],
    ["Ich treffe klare Vereinbarungen.",  "Ich leiste Widerstand, wenn etwas nicht den Regeln entspricht."],
    ["Ich neige dazu, mich verantwortlich zu fühlen.",  "Ich kann, wenn erforderlich, sehr streng sein."],
    ["Ich bin entgegenkommend.",  "Ich habe keine Angst, mich selbst so darzustellen wie ich bin."],
    ["Ich achte darauf, dass alle im Team einen Teil der Aufgabe übernehmen.",  "Ich beschwere mich über Ungerechtigkeiten unmissverständlich."],
    ["Ich achte darauf, dass jeder zu seinem Recht kommt.",  "Ich habe hohe Ansprüche an mich selbst."],
    ["Ich übernehme immer die Verantwortung.",  "Ich kenne meine Fähigkeiten."],
    ["Ich bin hilfsbereit.",  "Ich kann anderen Instruktionen geben."],
    ["Ich mag es, mit anderen zusammen zu arbeiten.",  "Ich gebe gern."],
    ["Ich respektiere Autorität.",  "Ich neige dazu, anderen zu vertrauen."],
    ["Ich kann Selbstkritik üben.",  "Ich bitte schnell um Hilfe."],
    ["Ich bin nicht leicht zu überzeugen.",  "Ich bin zurückhaltend - ich lasse mich nicht leicht gehen."],
    ["Ich foppe andere gerne.",  "Ich ertrage es nicht, \"am kurzen Zügel geführt zu werden\". / Ich liebe meine persönliche Freiheit."],
    ["Ich kann auch in großen Gruppen aufstehen und vor/zu anderen sprechen.",  "Ich kann offene Meinungsverschiedenheiten ertragen."],
    ["Ich vermeide Schwierigkeiten.",  "Ich erkläre anderen wie sie vorgehen sollten."],
    ["Ich passe mich leicht an.",  "Ich achte auf mich selbst."],
    ["Ich lasse andere in Ruhe wenn es zu Schwierigkeiten kommt.",  "Ich stelle meine persönliche Meinung in den Hintergrund, um Auseinandersetzungen zu vermeiden."],
    ["Ich treffe keine unbedachten Entscheidungen.",  "Ich halte mich an Vereinbarungen."],
    ["Ich kann Vorgesetzten etwas vorenthalten.",  "Ich zögere, bevor ich etwas sage oder tue."],
    ["Ich messe mich gerne mit/an anderen.",  "Ich bin nicht leicht zu überzeugen."],
    ["Ich tue mein Bestes, um auch langweiligen Erklärungen zu folgen.",  "Ich erkläre anderen wie sie vorgehen sollten."],
    ["Ich bin fähig zur Selbstkritik.",  "Ich fühle mich schnell verantwortlich."],
    ["Ich leiste Widerstand, wenn etwas nicht den Regeln entspricht.",  "Ich kann es nicht ertragen, wenn andere mich nicht mögen."],
    ["Ich kann gegenüber anderen sehr fordernd sein.",  "Wenn ich etwas tun soll, gebe ich mein Bestes, um es zu erfüllen."],
    ["Ich habe großes Selbstvertrauen.",  "Ich bin bescheiden."],
    ["Ich ziehe mich bei Konflikten leicht zurück.",  "Ich organisiere Dinge selbst, wenn ich sehe, dass sie nicht effizient laufen."],
    ["Ich lehne mich gegen Ungerechtigkeit auf.",  "Ich bin hilfsbereit."],
    ["Ich beschwere mich über Ungerechtigkeiten unmissverständlich.",  "Ich habe schnell Vertrauen zu anderen Menschen."],
    ["Ich strebe danach der/die Beste zu sein.",  "Ich bewundere starke Persönlichkeiten."],
    ["Ich habe keine Angst, allgemein akzeptierte Dinge in Frage zu stellen.",  "Ich erkläre gern."],
    ["Ich lasse mir nicht auf der Nase herumtanzen.",  "Ich neige dazu, Mitleid zu empfinden."],
    ["Ich treffe unabhängige Entscheidungen.",  "Ich bin zu jedermann freundlich."],
    ["In Konflikten stehe ich zu meiner Meinung.",  "Ich treffe klare Vereinbarungen."],
    ["Ich kann auch in großen Gruppen aufstehen und vor/ zu anderen sprechen.",  "Ich schütze die Schwachen."],
    ["Ich habe keine Angst, mich so darzustellen wie ich bin.",  "Ich erkläre anderen, wie sie handeln sollten."]
  ]