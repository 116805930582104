export const TRANSLATIONS_DE = {
    App: {
        lang: 'de',
        title: 'Assessment Center',
        welcome: 'Herzlich willkommen',
        page: 'Seite',
        out_of: 'von',
        btn_start: 'Starten',
        btn_start_now: 'Jetzt starten',
        btn_back: 'Zurück',
        btn_next: 'Weiter',
        btn_previous_page: 'Vorherige Seite',
        btn_next_page: 'Nächste Seite',
        cancel: 'Abbrechen',
        loading: 'Lädt...',
        name: 'Name',
        email: 'E-Mail-Adresse',
        company: 'Unternehmen',
        operating_unit: 'Operating Unit'
    },
    Nav: {
        dashboard: 'Interner Bereich',
        restart_assessment: 'Assessment neu starten (Admin)',
        certificates: 'Urkundenarchiv',
        recovery: 'Urkunden wiederherstellen',
        account_settings: 'Kontoeinstellungen',
        config: 'Konfiguration',
        users: 'Benutzerverwaltung',
        log_out: 'Abmelden'
    },
    Header: {
        alert_reset_assessment: 'Möchtest Du das Assessment wirklich zurücksetzen?',
        warning_reset_assessment: 'Diese Funktion darf nur verwendet werden, wenn der Bewerber die Bearbeitung sonst nicht fortsetzen kann.',
        yes_restart_assessment: 'Ja, Assessment zurücksetzen',
        alert_assessment_reset: 'Das Assessment wurde zurückgesetzt.'
    },
    Home: {
        find_your_strengths: "Finde Deine Stärken!",
        quote_hamlet: "Bereit sein ist alles!",
        alpha_recruit_error: "ALPHA Recruit Fehler: Ungültiger Link",
        alert_user_not_found: "Dieser Link ist leider ungültig. Bitte wende Dich an Deine(n) Ansprechpartner(in)."
    },
    Introduction: {
        introduction: 'Einführung',
        page_1_heading: 'Herzlich willkommen',
        page_1_1: 'Auf den nächsten Seiten erfährst Du, wie Du das Assessment Center ausfüllst.',
        page_1_2: 'Dieses verfolgt das Ziel, Dir eine Rückmeldung zu Deinem individuellen Denkstil und Deinem Kommunikationsverhalten im Alltag zu geben.',
        page_1_3: 'Bitte beachte, dass das Assessment erst ab einem Mindestalter von 15 Jahren ausgefüllt werden sollte.',
        page_2_heading: 'Zustimmung zur Datenverarbeitung',
        page_2_1: 'Bevor Du mit dem Assessment beginnst, informieren wir Dich darüber, wie wir Deine Daten verarbeiten und wie Du Deine Einwilligung in die Datenverarbeitung gibst.',
        page_2_2: 'Lies bitte die Informationen und markiere dann das Kästchen darunter, um Deine Zustimmung zu geben.',
        page_2_3: ' Ich stimme der Speicherung meiner personenbezogenen Daten zu. Alle persönlichen Informationen werden vertraulich und mit größter Sorgfalt behandelt. Die Daten werden nur der Personalabteilung zugänglich gemacht.',
        data_protection_info: 'Datenschutzinformationen lesen',
        alert_data_storage: "Markiere bitte das Kästchen oben, um der Speicherung Deiner personenbezogenen Daten zuzustimmen.",
        page_3_heading: 'Und so funktioniert es',
        page_3_1: 'Dir werden auf den nächsten Seiten verschiedene Aussagen gezeigt. Bitte stufe diese so ein, wie es für Dich persönlich am ehesten zutrifft.',
        page_3_2: 'Es gibt weder richtige noch falsche Antworten.',
        page_3_3: 'Nur bei einer ehrlichen Bearbeitung kann sichergestellt werden, dass Deine Ergebnisse in sich logisch und stimmig sind. So profitierst Du am meisten von dem Ergebnis.',
        page_3_4: 'Wenn Situationen beschrieben werden, die Du noch nicht persönlich erlebt hast - dann schätze bitte ein, wie Du Dich in diesen wahrscheinlich verhalten würdest.',
        page_3_5: 'Wenn Dir die Einschätzung bei einzelnen Aussagen schwerfallen sollte, wähle bitte die Aussage aus, die am ehesten zutreffen würde.',
        page_4_heading: 'Gleich geht es los',
        page_4_1: 'Das Ausfüllen wird etwa 15-20 Minuten dauern. Die Zeit, die Du benötigst hat keine Auswirkung auf Dein Ergebnis. Bitte antworte möglichst spontan.',
        page_4_2: 'Natürlich behandeln wir Deine Antworten und Ergebnisse ',
        page_4_3: 'absolut vertraulich.',
        page_4_4: 'Durch das Absenden am Ende bestätigst Du automatisch, dass Du die Fragen ehrlich und ohne Hilfe von anderen beantwortet hast.'
    },
    Assessments: {
        assessment: "Assessment",
        progress_of_user: 'Fortschritt von ',
        current_fill_time: 'Aktuelle Bearbeitungszeit: ',
        profile_creation_heading: 'Dein Profil wird nun erstellt. Habe bitte etwas Geduld.',
        profile_creation_1: 'Vielen Dank für Deine Zeit, Dein Kommunikations- und Denkstilprofil wird nun erstellt.',
        profile_creation_2: 'Bitte warte auf die Erstellung Deines Profils. Dies kann einige Sekunden bis Minuten in Anspruch nehmen. Während dieses Vorgangs muss die Seite immer geöffnet bleiben.',
        completed_heading: 'Glückwunsch,',
        completed: 'Du hast die Assessments Kommunikation und Denkstil nun abgeschlossen. Dein Ansprechpartner meldet sich in Kürze bei Dir.'
    },
    Communication: {
        name: 'Kommunikation',
        heading: 'Was passt am besten zu Dir?',
        introduction_1: 'Auf den folgenden Seiten findest Du 56 Abschnitte mit jeweils zwei Charakterisierungssätzen. Wähle in jedem Abschnitt den Satz aus, der am besten auf Dich zutrifft.',
        introduction_2: 'Habe dabei Situationen, vorzugsweise im Berufsleben, aber auch im Privatleben (beispielsweise im Sportverein) unter "gleichrangigen" Teamkollegen vor Augen.',
        introduction_3: 'Deine Auswahl sollte nicht geprägt sein durch Hierarchieverhältnisse von Vorgesetzten / Familie / Verwandtschaft.',
        completed_heading: 'Teil 1 von 2 abgeschlossen!',
        completed: 'Du hast das Assessment Kommunikation nun abgeschlossen. Klicke "Weiter", um mit dem zweiten Assessment fortzufahren, oder "Zurück", um Deine Auswahl zu überprüfen.',
    },
    ThinkingStyle: {
        name: 'Denkstil',
        heading: 'Ich bin eher ...',
        introduction_1: 'Auf den folgenden Seiten findest Du 64 Abschnitte mit jeweils zwei Eigenschaften und den dazugehörigen Erklärungen.',
        introduction_2: 'Welche der beiden Eigenschaften passt besser zu Deiner Denkweise? Wähle diese Eigenschaft aus.',
        btn_submit: 'Antworten senden',
        completed_heading: 'Teil 2 von 2 abgeschlossen!',
        completed: 'Du hast nun das Assessment Denkstil abgeschlossen. Klicke "Antworten senden", um die Assessments abzuschließen, oder "Zurück", um Deine Auswahl zu überprüfen.'
    },
    Certificates: {
        heading_comm: 'Kommunikationsprofil',
        description_comm: 'Das Handlungs- und Kommunikationsmodell zeigt in wertfreier Darstellung, wie der Mensch in der Kommunikation wahrgenommen werden kann.',
        heading_think: 'Denkstilprofil',
        description_think: 'Dein Profil wurde auf Basis Deiner gegebenen Antworten erstellt und zeigt Deinen Denkstil in wertfreier Darstellung auf.',
        fill_time: 'Bearbeitungsdauer',
        privacy_note_1: 'Die Daten sind streng vertraulich und sind ausschließlich ',
        privacy_note_2: 'für Dich und Deinen Ansprechpartner einsehbar.',
        evaluation_note: 'Das Profil darf nur von zertifizierten Personen ausgewertet werden.',
        error_processing: 'Fehler bei der Verarbeitung der Assessment-Daten. Die Wiederherstellung der Daten ist fehlgeschlagen!'
    }
}