import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useAssessment } from "../contexts/AssessmentContext"
import { Alert } from "react-bootstrap"
import { Cookies } from 'react-cookie'
import axios from 'axios'
import { CHARACTERIZATIONS_THINK_DE } from '../translations/de/characterizations_think.de.js'
import { CHARACTERIZATIONS_THINK_EN } from '../translations/en/characterizations_think.en.js'
import { CHARACTERIZATIONS_THINK_PL } from '../translations/pl/characterizations_think.pl.js'
import { CHARACTERIZATIONS_THINK_RO } from '../translations/ro/characterizations_think.ro.js'
import { CHARACTERIZATIONS_THINK_HU } from '../translations/hu/characterizations_think.hu.js'

const ThinkingStyle = () => {
    const { t, API_PATH, alphaRecruitUrlParams, userData, setActiveComponent, activePage, setActivePage, currentProgress, fillTime, updateFillTime, setValuesThink } = useGlobalState();
    const { userSelectedBtns, activeAssessment, btnsDisabled, checkProgress, handleBtnOnClick, handleBtnBackOnClick, renderAssessmentForm } = useAssessment();
    const [arrUserSelectedBtns, setArrUserSelectedBtns] = useState(Array.from({ length: 16 }, () => new Array(16).fill(null)));
    const lengthAssessment = CHARACTERIZATIONS_THINK_DE.length;
    const [error, setError] = useState();

    useEffect(() => {
        updateFillTime()
    }, [updateFillTime, fillTime])

    // Calculation of the Assessment Thinking Style Values (don't change anything here)
    const returnThinkValues = useCallback(() => {
        try {
            let user_selected_btns = [...arrUserSelectedBtns];
            let i = 0;

            for (let row = 0; row < 15; row += 2) {
                for (let col = 0; col < 15; col += 2) {
                    if (userSelectedBtns[i].value === '1') {
                        user_selected_btns[row][col] = 1;
                    } else if (userSelectedBtns[i].value === '2') {
                        user_selected_btns[row + 1][col + 1] = 1;
                    }
                    i++;
                }
            }
            setArrUserSelectedBtns(user_selected_btns);

            let sumRow = new Array(16).fill(0);
            for (let row = 0; row < 15; row += 2) {
                for (let col = 0; col < 15; col += 2) {
                    if (arrUserSelectedBtns[row][col] === 1) {
                        sumRow[row]++;
                    }
                }
            }

            let sumCol = new Array(16).fill(0);
            for (let col = 1; col < 16; col += 2) {
                for (let row = 1; row < 16; row += 2) {
                    if (arrUserSelectedBtns[row][col] === 1) {
                        sumCol[col]++;
                    }
                }
            }

            const thinkValuesNew = { value_yellow: null, value_red: null, value_green: null, value_blue: null, fillTime: fillTime };
            thinkValuesNew.value_yellow = sumRow[0] + sumRow[8] + sumCol[1] + sumCol[9];
            thinkValuesNew.value_red = sumRow[2] + sumRow[10] + sumCol[3] + sumCol[11];
            thinkValuesNew.value_green = sumRow[4] + sumRow[12] + sumCol[5] + sumCol[13];
            thinkValuesNew.value_blue = sumRow[6] + sumRow[14] + sumCol[7] + sumCol[15];
            setValuesThink(thinkValuesNew);
            axios.post(API_PATH + 'store-assessment-values-in-database.php', { urlParams: alphaRecruitUrlParams, userData: userData, valuesThink: thinkValuesNew })
                .catch(e => { setError(e.message) })
            const cookies = new Cookies()
            cookies.set('valuesThink', thinkValuesNew, { path: '/', expires: new Date(Date.now() + 604800), secure: true, sameSite: 'strict' })
        } catch (e) {
            setError(e.message)
        }
    }, [API_PATH, arrUserSelectedBtns, userSelectedBtns, setValuesThink, fillTime, alphaRecruitUrlParams, userData])

    const changeBtnStyleOnClick = useCallback((value_current_assessment) => {
        if (document.getElementById('field_' + value_current_assessment) && document.getElementById('characteristic_' + value_current_assessment) && document.getElementById('explanation_' + value_current_assessment)) {
            document.getElementById('field_' + value_current_assessment).style.backgroundColor = "rgba(63,223,165)";
            document.getElementById('characteristic_' + value_current_assessment).style.color = "#fff !important";
            document.getElementById('explanation_' + value_current_assessment).style.color = "white";
        }
    }, [])

    useEffect(() => {
        checkProgress(changeBtnStyleOnClick, lengthAssessment)
    }, [checkProgress, changeBtnStyleOnClick, lengthAssessment])

    const handleComplete = () => {
        returnThinkValues();
        setActiveComponent("Certificates");
        setActivePage(false);
    }

    const renderAssessmentBtn = (id) => {
        const CHARACTERIZATIONS_LOCAL = t('App.lang') === 'en' ? CHARACTERIZATIONS_THINK_EN : t('App.lang') === 'pl' ? CHARACTERIZATIONS_THINK_PL : t('App.lang') === 'ro' ? CHARACTERIZATIONS_THINK_RO : t('App.lang') === 'hu' ? CHARACTERIZATIONS_THINK_HU : CHARACTERIZATIONS_THINK_DE
        return (
            <button className="btn btn-assessment" id={'field_' + id} key={'field_' + id + activeAssessment} value={id} disabled={userSelectedBtns.length < currentProgress ? true : btnsDisabled} onClick={(e) => { handleBtnOnClick(e.currentTarget, changeBtnStyleOnClick) }}>
                <h5 className='characteristic-think' id={'characteristic_' + id}><b>{id === 1 ? CHARACTERIZATIONS_LOCAL[activeAssessment][0] : (id === 2 && CHARACTERIZATIONS_LOCAL[activeAssessment][2])}</b></h5>
                <p className='explanation-think' id={'explanation_' + id}>{id === 1 ? CHARACTERIZATIONS_LOCAL[activeAssessment][1] : (id === 2 && CHARACTERIZATIONS_LOCAL[activeAssessment][3])}</p>
            </button>
        )
    }

    const renderAssessmentThinkingStyle = () => {
        if (activePage === 'Introduction') {
            return (
                <div className='col-md-10 col-xl-9 p-3 d-flex flex-column align-items-center'>
                    <h4 className='mb-3'>{t("Assessments.assessment")} 2 / 2 - {t("ThinkingStyle.name")}</h4>
                    <p>{t("ThinkingStyle.introduction_1")}</p>
                    <p>{t("ThinkingStyle.introduction_2")}</p>
                    <button className='btn btn-primary m-2' onClick={() => setActivePage('Assessment')}>{t("App.btn_start_now")}</button>
                </div>
            )
        } else if (activePage === 'Complete') {
            return (
                <div className='col-md-10 col-xl-9 p-3'>
                    <h4 className='mb-3'>{t("ThinkingStyle.completed_heading")}</h4>
                    <Alert variant="success">{t("ThinkingStyle.completed")}</Alert>
                    <button className='btn btn-secondary my-2 mx-0 float-start' style={{ borderColor: 'transparent', background: '#fff' }} onClick={() => handleBtnBackOnClick()}>{t("App.btn_back")}</button>
                    <button className='btn btn-primary btn-complete m-2 float-end' onClick={() => handleComplete()}>{t("ThinkingStyle.btn_submit")}</button>
                </div>
            )
        } else {
            return (<> {renderAssessmentForm(renderAssessmentBtn, lengthAssessment, 'ThinkingStyle')} </>)
        }
    }

    return (error ? <Alert className='mb-2' variant="danger">{error}</Alert> : renderAssessmentThinkingStyle())
}

export default ThinkingStyle
