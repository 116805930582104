export const CHARACTERIZATIONS_THINK_HU = [
  [
    "Elfogulatlan",
    "Nyitott és fogékony vagyok az új ötletek és nézetek felé, még akkor is, ha azok eltérnek az enyémtől.",
    "Szimultán",
    "Egyszerre több különböző típusú feladaton képes vagyok dolgozni."
  ],
  [
    "Kreatív",
    "Kreatívan tevékenykedni, ötletekkel rendelkezni és azokat kreatívan megvalósítani.",
    "Lelkes",
    "Könnyen és nagy lelkesedéssel vágok bele valamibe."
  ],
  [
    "Koncepcionális",
    "Önállóan tervez és ír valamit (pl. javaslatok, kidolgozások, tervek stb.)",
    "Gyakorlatias",
    "Jobban szeretem a konkrét cselekvést, mint az elméleti elmélkedést."
  ],
  [
    "Fantáziadús",
    "Képesség olyan dolgok elképzelésére, amelyeket az érzékszervek nem képesek felfogni, vagy amelyek a valóságban nem léteznek. Képesség a problémák újszerű megközelítésére.",
    "Kvantitatív",
    "Hajlam arra, hogy a tényeket mérhető kritériumok alapján mérlegelje és értékelje. Szeretek a számok alapján tájékozódni."
  ],
  [
    "Felfedező",
    "Szeretek ismeretlen területre merészkedni, és új ötleteket vagy koncepciókat keresni.",
    "Kreatív",
    "Kreatívan tevékenykedni, ötletekkel rendelkezni és azokat kreatívan megvalósítani."
  ],
  [
    "Stratéga",
    "Képes vagyok stratégiailag gondolkodni, és a dolgokat átfogó összefüggéseikben és következményeikben látni.",
    "Szenvedélyes",
    "Érzéseim és gondolataim nagyon hevesen tudnak befolyásolni."
  ],
  [
    "Holisztikus",
    "Képesnek lenni arra, hogy az egyes problémákat holisztikus összefüggéseikben lássuk, azaz a sok egymagában álló fa helyett az erdőt lássuk.",
    "Szervezett",
    "Szeretem egy projekt folyamatát működő egésszé rendezni és koordinálni."
  ],
  [
    "Elkötelezett",
    "Hosszú távú célok elérésére való törekvés és folyamatos fejlődés.",
    "Türelmes",
    "Hosszú ideig képes vagyok fókuszálni, és kitartani egy adott feladat mellett."
  ],
  [
    "Szenvedélyes",
    "Érzéseim és gondolataim nagyon hevesen tudnak befolyásolni.",
    "Integráló",
    "Képes vagyok ötletek, koncepciók és helyzetek részeit és elemeit egy egésszé ötvözni."
  ],
  [
    "Segítőkész",
    "Szeretem felajánlani a segítségemet másoknak, és támogatni őket a nehézségekben.",
    "Intuitív (érzés)",
    "Megérzem mások érzelmeit, és racionális indoklás nélkül támaszkodhatok a szimpátia és antipátia érzéseimre. Érzelmileg felismerek valamit anélkül, hogy tényszerű bizonyítékokkal alá lennének támasztva."
  ],
  [
    "Emocionális",
    "Nagyon intenzíven megélem az érzelmeimet, és ki is tudom mutatni az érzéseimet.",
    "Kitartó",
    "Kitartó és céltudatos vagyok a céljaim elérésében."
  ],
  [
    "Barátságos",
    "Szeretek kedves és szívélyes lenni.",
    "Tényalapú",
    "Fontos, hogy pontosan ismerjem a tényeket, és a döntésemet ezekre a tényekre alapozzam."
  ],
  [
    "Együttérző",
    "Gyakran megértem mások érzéseit, és meg tudom hallgatni a többi embert.",
    "Koncepcionális",
    "Önállóan tervezni és végrehajtani dolgokat (pl. javaslatokat, kidolgozásokat, terveket stb.)"
  ],
  [
    "Harmonizáló",
    "Képes vagyok harmóniába hozni a dolgokat és az érzéseket.",
    "Hasznos",
    "Szeretek segítséget nyújtani másoknak és támogatni őket a nehézségekben."
  ],
  [
    "Vendégszerető",
    "Szeretem meghívni az embereket, hogy közösen töltsünk el időt.",
    "Aprólékos",
    "Egy kérdés apró összetevőinek figyelembe vétele és értékének megállapítása."
  ],
  [
    "Nyitott",
    "Nyitottan és kedvesen állok másokhoz.",
    "Matematikai",
    "Hajlam a számokkal és adatokkal való foglalkozásra és azok feldolgozására."
  ],
  [
    "Szervezett",
    "Szeretem egy projekt folyamatát működő egésszé szervezni.",
    "Művész lélek",
    "A festéshez, rajzoláshoz vagy szobrászathoz való vonzalom vagy tehetség. Hajlam az esztétikai-művészeti alkotásra az élet különböző területein."
  ],
  [
    "Aprólékos",
    "Egy kérdés apró összetevőinek figyelembe vétele és értékének megállapítása.",
    "Interperszonális",
    "Tartós kapcsolatok kiépítése és fenntartása sok és sokféle emberrel."
  ],
  [
    "Fegyelmezett",
    "Fegyelmezett vagyok, és rendíthetetlenül tudom követni az elképzeléseimet.",
    "Strukturált",
    "Szeretem a szisztematikus rendszereket és az előre lefektetett"
  ],
  [
    "Gyakorlatias",
    "Jobban szeretem a konkrét cselekvést, mint az elméleti elmélkedést.",
    "Analitikus",
    "A dolgok vagy gondolatok részekre bontása az egyes részek közötti szabályszerűségek és kapcsolatok felismerése érdekében."
  ],
  [
    "Domináns",
    "Önuralomra és mások irányítására törekszik. Hajlamos vagyok megadni az alaphangot.",
    "Elfogulatlan",
    "Nyitott és fogékony vagyok az új ötletek és nézetek felé, még akkor is, ha azok eltérnek az enyémtől."
  ],
  [
    "Tervező",
    "Szeretem előre meghatározni a bizonyos cél eléréséhez szükséges lépéseket.",
    "Emocionális",
    "Nagyon intenzíven megélem az érzelmeimet, és ki is tudom mutatni az érzéseimet."
  ],
  [
    "Szekvenciális",
    "Sorrendiséget megkövetelő, és ennek megfelelően foglalkozzon velük, azaz egyszerre csak egy dolgot tegyen meg.",
    "Fegyelmezett",
    "Én irányítok, és nem hagyom, hogy eltérítsenek a célhoz vezető útról."
  ],
  [
    "Időben",
    "Fontos számomra, hogy tartsam a határidőket és kontrolláljam az időmet.",
    "Lényegretörő",
    "Világosan és egyértelműen tudok nyilatkozni, és a dolgok lényegére tudok térni."
  ],
  [
    "Logikus",
    "Logikusan és koherens módon, törvények szerint gondolkodik.",
    "Kockázatvállaló",
    "Képes vagyok bizonytalan döntéseket hozni, és meglátni bennük a lehetőségeket."
  ],
  [
    "Matematikai",
    "Hajlam a számokkal és adatokkal való foglalkozásra és azok feldolgozására.",
    "Megbízható",
    "Pozitívan állok másokhoz valamint ők is hozzám, és szeretek rájuk támaszkodni."
  ],
  [
    "Egyértelműen",
    "Világosan és egyértelműen tudok nyilatkozni, és a dolgok lényegére tudok térni.",
    "Folyamathoz kapcsolódó",
    "Szeretek bizonyos eljárásokat kialakítani, amelyeket aztán követni tudok."
  ],
  [
    "Tudományos",
    "Szeretem magam igazolni szabályszerűségekkel és logikusan megmagyarázható és bizonyítható tényekkel.",
    "Kedveli a számokat",
    "A számszerű arányok felé orientálódik. Hajlamos pontos számokat és méréseket találni."
  ],
  [
    "Egyenes",
    "Szeretek világos állításokkal foglalkozni, amelyek érthető tényeket tartalmaznak.",
    "Fantáziadús",
    "Képesség olyan dolgok elképzelésére, amelyeket az érzékszervek nem képesek felfogni, vagy amelyek a valóságban nem léteznek. Képesség a problémák újszerű megközelítésére."
  ],
  [
    "Racionális",
    "A tényeket nem érzelmileg, hanem ésszerűen és racionálisan látni és értékelni.",
    "Barátságos",
    "Szeretek kedves és szívélyes lenni."
  ],
  [
    "Kvantitatív",
    "Hajlam arra, hogy a tényeket mérhető kritériumok alapján mérlegelje és értékelje. Szeretek a számok alapján tájékozódni.",
    "Gyakorlatias",
    "Jobban szeretem a konkrét cselekvést, mint az elméleti elmélkedést."
  ],
  [
    "Műszaki beállítottságú",
    "A mérnöki és tudományos területek ismereteinek megértése és alkalmazása.",
    "Tudományos",
    "Szeretem magam igazolni szabályszerűségekkel és logikusan megmagyarázható és bizonyítható tényekkel."
  ],
  [
    "Szimultán",
    "Egyszerre több különböző típusú feladaton képes vagyok dolgozni.",
    "Találékony",
    "Gyakran sok kreatív ötletem és javaslatom van a projektekben."
  ],
  [
    "Integráló",
    "Képes vagyok ötletek, koncepciók és helyzetek részeit és elemeit egy egésszé ötvözni.",
    "Társasági",
    "Szeretek emberekkel foglalkozni, és jobban szeretek csapatban dolgozni, mint egyedül."
  ],
  [
    "Művész lélek",
    "A festéshez, rajzoláshoz vagy szobrászathoz való vonzalom vagy tehetség. Hajlam az esztétikai-művészeti alkotásra az élet különböző területein.",
    "Szorgalmas",
    "Szeretek dolgozni."
  ],
  [
    "Kockázatvállaló",
    "Képes vagyok bizonytalan döntéseket hozni, és meglátni bennük a lehetőségeket.",
    "Kritikus",
    "Gondosan ellenőriz és értékel, pl. projekteket vagy ötleteket. A hozzáállásom gyakran a kivárás és a várakozás."
  ],
  [
    "Találékony",
    "Gyakran sok kreatív ötletem és javaslatom van a projektekben.",
    "Felfedező",
    "Szeretek ismeretlen területre merészkedni, és új ötleteket vagy koncepciókat keresni."
  ],
  [
    "Kíváncsi",
    "Olyan ember vagyok, aki sokat kérdez és szeret mindenkiről tudni.",
    "Együttérző",
    "Gyakran megértem mások érzéseit, és meg tudom hallgatni a többi embert."
  ],
  [
    "Vízionista",
    "Jó a képzelőerőm, és szeretek a jövőben gondolkodni.",
    "Domináns",
    "Önuralomra és mások irányítására törekszik. Hajlamos vagyok megadni az alaphangot."
  ],
  [
    "Vizuális típus",
    "Képekben és példázatokban való gondolkodás.",
    "Értelmes",
    "Szeretek világos, érthető tényeket tartalmazó kijelentésekkel foglalkozni."
  ],
  [
    "Társasági",
    "Szeretek emberekkel foglalkozni, és jobban szeretek csapatban dolgozni, mint egyedül.",
    "Kíváncsi",
    "Olyan ember vagyok, aki sokat kérdez és szeret mindenkiről tudni."
  ],
  [
    "Intuitív (érzés)",
    "Megérzem mások érzelmeit, és racionális indoklás nélkül támaszkodhatok a szimpátia és antipátia érzéseimre. Érzelmileg felismerek valamit anélkül, hogy tényszerű bizonyítékokkal alá lennének támasztva.",
    "Kifejező",
    "Nagyon hatásosan és világosan közvetítem a nézeteimet."
  ],
  [
    "Interperszonális",
    "Tartós kapcsolatok kiépítése és fenntartása sok és sokféle emberrel.",
    "Aprólékos",
    "Egy kérdés apró összetevőinek figyelembe vétele és értékének megállapítása."
  ],
  [
    "Megbízható",
    "Pozitívan állok másokhoz valamint ők is hozzám, és szeretek rájuk támaszkodni.",
    "Tényorientált",
    "Kizárólag a tényekre összpontosító, szigorú gondolkodásmód."
  ],
  [
    "Lelkes",
    "Könnyen és nagy lelkesedéssel vágok bele valamibe.",
    "Stratéga",
    "Képes vagyok stratégiailag gondolkodni, és a dolgokat átfogó összefüggéseikben és következményeikben látni."
  ],
  [
    "Kifejező",
    "Nagyon hatásosan és világosan közvetítem a nézeteimet.",
    "Harmonizálás",
    "Képes vagyok harmóniába hozni a dolgokat és az érzéseket."
  ],
  [
    "Együttműködő",
    "Szeretek másokkal együtt dolgozni egy közös cél érdekében.",
    "Tervező",
    "Szeretem előre meghatározni a bizonyos cél eléréséhez szükséges lépéseket."
  ],
  [
    "Nyitott",
    "Nyitott vagyok mások javaslataira és segítségfelajánlásaira.",
    "Racionális",
    "A tényeket nem érzelmileg, hanem ésszerűen és racionálisan látni és értékelni."
  ],
  [
    "Gyakorlatias",
    "Jobban szeretem a konkrét cselekvést, mint az elméleti elmélkedést.",
    "Vízionista",
    "Jó a képzelőerőm, és szeretek a jövőben gondolkodni."
  ],
  [
    "Tartósan kitartó",
    "Kitartó vagyok a céljaim elérésében.",
    "Együttműködő",
    "Szeretek másokkal együtt dolgozni egy közös cél érdekében."
  ],
  [
    "Strukturált",
    "Szeretem a szisztematikus rendszereket és az előre lefektetett",
    "Körültekintő",
    "Óvatos ember vagyok, aki körültekintően mérlegeli tettei következményeit."
  ],
  [
    "Folyamathoz kapcsolódó",
    "Szeretek bizonyos eljárásokat kialakítani, amelyeket aztán követni tudok.",
    "Kihívást kedvelő",
    "Hajlamos vagyok megkérdőjelezni a tényeket, és szeretem az 'ördög ügyvédjét' játszani."
  ],
  [
    "Szorgalmas",
    "Szeretek dolgozni.",
    "Holisztikus",
    "Képesnek lenni arra, hogy az egyes problémákat holisztikus összefüggéseikben lássuk, azaz a sok egymagában álló fa helyett az erdőt lássuk."
  ],
  [
    "Aprólékos",
    "Egy kérdés apró összetevőinek figyelembe vétele és értékének megállapítása.",
    "Vendégszerető",
    "Szeretem meghívni az embereket, hogy közösen töltsünk el időt."
  ],
  [
    "Megfontolt",
    "Óvatos ember vagyok, aki körültekintően mérlegeli tettei következményeit.",
    "Szekvenciális",
    "Sorrendiséget megkövetelő, és ennek megfelelően foglalkozzon velük, azaz egyszerre csak egy dolgot tegyen meg."
  ],
  [
    "Hagyományos",
    "Hajlam a hagyományokhoz való ragaszkodásra.",
    "Kvantitatív",
    "Hajlam arra, hogy a tényeket mérhető kritériumok alapján mérlegelje és értékelje. Szeretek a számok alapján tájékozódni."
  ],
  [
    "Matematikai",
    "Hajlam a számokkal és adatokkal való foglalkozásra és azok feldolgozására.",
    "Vizuális",
    "Képekben és példázatokban való gondolkodás."
  ],
  [
    "Objektív",
    "Előítéletek nélkül gondolkodom és cselekszem, és ritkán befolyásolnak a személyes érzéseim.",
    "Nyitott",
    "Nyitott vagyok mások javaslataira és segítségfelajánlásaira."
  ],
  [
    "Analitikus",
    "A dolgok vagy gondolatok részekre bontása az egyes részek közötti szabályszerűségek és kapcsolatok felismerése érdekében.",
    "Hagyományos",
    "Hajlam a hagyományokhoz való ragaszkodásra.",
  ],
  [
    "Kedveli a számokat",
    "A számszerű arányok felé orientálódik.Hajlamos pontos számokat és méréseket találni.",
    "Realisztikus",
    "Úgy látom a dolgokat, ahogyan vannak vagy ahogyan mutatják magukat, és kerülöm a spekulációkat és a képzelgést."
  ],
  [
    "Kritikus",
    "Gondosan ellenőriz és értékel, pl.projekteket vagy ötleteket.A hozzáállásom gyakran a kivárás és a várakozás.",
    "Kockázatvállaló",
    "Hajlandó vagyok rizikós hozni, és meglátom bennük a lehetőségeket."
  ],
  [
    "Tényalapú gondolkodás",
    "Kizárólag a tényekre összpontosító, szigorú gondolkodásmód.",
    "Nyitott",
    "Nyitottan és kedvesen állok másokhoz."
  ],
  [
    "Kihívást kedvelő",
    "Hajlamos vagyok megkérdőjelezni a tényeket, és szeretem az \"ördög ügyvédjét\" játszani.",
    "Időben",
    "Fontos számomra, hogy tartsam a határidőket és kontrolláljam az időmet. "
  ],
  [
    "Realisztikus",
    "Úgy látom a dolgokat, ahogyan vannak vagy ahogyan mutatják magukat, és kerülöm a spekulációkat és az önhittséget.",
    "Műszaki beállítottságú",
    "A mérnöki és természettudományos ismeretek megértése és alkalmazása."
  ]
]