import React, { useState, useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import axios from 'axios'

const Home = () => {
    const { t, API_PATH, fetchTempData, userData, setUserData, setActiveComponent, setAlphaRecruitUrlParams } = useGlobalState()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const params = new URLSearchParams(window.location.search)
    const res_id = params.get("res_id")
    const res_model = params.get("res_model")
    const token = params.get("token")

    const fetchAlphaRecruitUserData = useCallback(() => {
        axios.post(API_PATH + 'fetch-alpha-recruit-user-data.php', { res_id: res_id, res_model: res_model, token: token })
            .then(res => {
                fetchTempData(res_id)
                setUserData(res.data.user)
                if (!res.data.user.name) {
                    setError(t('Home.alert_user_not_found'))
                }
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, fetchTempData, res_id, res_model, token, t, setUserData])

    useEffect(() => {
        let isMounted = true
        if (isMounted) {
            if (res_id && res_model && token) {
                setAlphaRecruitUrlParams({ res_id: res_id, res_model: res_model, token: token })
                fetchAlphaRecruitUserData()
                setLoading(false)
            } else {
                setError(t('Home.alert_user_not_found'))
            }
        }
        return () => { isMounted = false }
    }, [fetchAlphaRecruitUserData, res_id, res_model, token, setAlphaRecruitUrlParams, t])

    const createParticipantInDB = useCallback(() => {
        userData && axios.post(API_PATH + 'create-participant.php', { method: 'create', res_id: res_id, name: userData.name, company: userData.company, branch: userData.branch, department: userData.department })
            .then(res => { setUserData(prevState => ({ ...prevState, participant_id: res.data.participant_id })) })
            .catch(e => { setError(e.message) })
    }, [API_PATH, res_id, userData, setUserData])

    const setActiveComponentHandler = e => {
        e.preventDefault()
        createParticipantInDB()
        setActiveComponent("Introduction")
    }

    const renderLandingPage = () => {
        return (<>
            <div id="heartily-welcome">
                <h4>{t("App.welcome")},</h4>
                <h2><b>{userData.name}</b></h2>
            </div>
            <h4><b>{t("Home.find_your_strengths")}</b></h4>
            <div id='show_video' className='py-2'>
                <iframe src="https://player.vimeo.com/video/910436103" className='my-3' width="640" height="360" title='video_oms' frameBorder="0"></iframe>
            </div>
            <button className='btn btn-primary btn-start' onClick={setActiveComponentHandler}>{t("App.btn_start")}</button>
        </>)
    }

    return (
        <div id='main-text' className='col-lg-9 m-3 text-center d-flex flex-column align-items-center'>
            {!error ? <>
                {!loading ?
                    renderLandingPage()
                    : <h4>{t("App.loading")}</h4>
                }
            </> : <>
                <h4 className='m-3'>{t('Home.alpha_recruit_error')}</h4>
                <p>{error}</p>
            </>}
        </div >
    )
}

export default Home
