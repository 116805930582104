export const CHARACTERISTICS_COMM_PL = [
    ["Lubię tłumaczyć rzeczy.", "Chętnie pomagam."],
    ["Tenduję do współczucia.", "Zawsze jestem gotowy do zawarcia kompromisu."],
    ["Jestem przyjazny dla wszystkich.", "Jestem sumienny."],
    ["Podziwiam silne osobowości.", "Łatwo się wycofuję w konfliktach."],
    ["Jestem nieśmiały - bojaźliwy.", "Szybko się bronię."],
    ["Nie boję się kwestionować powszechnie akceptowanych rzeczy.", "Nie daję sobą pomiatać."],
    ["W przypadku sporów trzymam się swojego zdania.", "Chcę zawsze być najlepszy."],
    ["Sam organizuję rzeczy, jeśli widzę, że nie są efektywne.", "Odstępuję od mojego osobistego zdania, aby uniknąć konfliktów."],
    ["Chronię ludzi, którzy są niepewni.", "Dotrzymuję umów."],
    ["Przeszkadza mi, gdy inni ludzie mnie nie lubią.", "Jestem skromny."],
    ["Zawsze staram się zrozumieć nawet nudne wyjaśnienia.", "Akceptuję tylko te rzeczy, których dowód mogę zobaczyć."],
    ["Nie mam problemu z przeproszeniem.", "Potrafię być bardzo wymagający."],
    ["Nie znoszę ograniczeń.", "Potrafię podejmować niezależne decyzje - mogę decydować samodzielnie."],
    ["W trudnych dyskusjach mogę podjąć decyzję.", "Kiedy jestem poproszony o zrobienie czegoś, robię wszystko, aby to zrobić."],
    ["Łatwo mi wybaczyć innym.", "Zastanawiam się przed podjęciem jakiejkolwiek działalności lub wypowiedzią."],
    ["Szybko przepraszam innych.", "Skarżę się na rzeczy, które nie poszły po mojej myśli."],
    ["Szybko podążam za dominującym zdaniem.", "Jestem szczery i bezpośredni."],
    ["Trzymam się w tle.", "Potrafię dobrze mówić o sobie."],
    ["W grupie często przejmuję inicjatywę.", "Szybko się zawstydzam, gdy rzeczy nie idą zgodnie z planem."],
    ["Chętnie pocieszam innych ludzi.", "Potrafię udzielać rzeczowej krytyki."],
    ["Zgadzam się z każdym.", "Potrafię upominać uciążliwe lub nachalne osoby."],
    ["Chętnie przyjmuję rady.", "Dbam o siebie."],
    ["Stawiam jasne warunki.", "Stawiam opór, gdy coś nie spełnia zasad."],
    ["Mam skłonność do odczuwania odpowiedzialności.", "Potrafię być bardzo surowy, jeśli jest to konieczne."],
    ["Jestem życzliwy.", "Nie boję się pokazywać siebie takim, jaki jestem."],
    ["Dbam o to, aby wszyscy w zespole brali udział w zadaniu.", "Konsekwentnie skarżę się na niesprawiedliwość."],
    ["Zwracam uwagę, aby każdy otrzymał to, na co zasługuje.", "Wysoko sobie stawiam wymagania."],
    ["Zawsze biorę na siebie odpowiedzialność.", "Znam swoje umiejętności."],
    ["Jestem pomocny.", "Potrafię udzielać instrukcji innym."],
    ["Lubię współpracować z innymi.", "Chętnie się dzielę."],
    ["Szanuję autorytet.", "Mam tendencję do ufać innym."],
    ["Potrafię być samokrytyczny.", "Szybko proszę o pomoc."],
    ["Nie jestem łatwy do przekonania.", "Nie uzewnętrzniam emocji - nie łatwo mnie rozluźnić."],
    ["Lubię żartować z innych.", "Nie znoszę, gdy ktoś chce mnie kontrolować. / Kocham swoją osobistą wolność."],
    ["Potrafię wystąpić przed dużą grupą i przemówić do innych.", "Potrafię tolerować otwarte różnice zdań."],
    ["Unikam trudności.", "Wyjaśniam innym, jak powinni postępować."],
    ["Łatwo się dostosowuję.", "Zwracam uwagę na siebie."],
    ["Zostawiam innych w spokoju, gdy pojawiają się trudności.", "Stawiam moje osobiste zdanie na drugim planie, aby uniknąć konfliktów."],
    ["Nie podejmuję pochopnych decyzji.", "Dotrzymuję umów."],
    ["Potrafię zataić coś przed przełożonymi.", "Waham się, zanim coś powiem lub zrobię."],
    ["Lubię się mierzyć z innymi.", "Nie jestem łatwy do przekonania."],
    ["Robię co w mojej mocy, aby zrozumieć nawet nudne wyjaśnienia.", "Wyjaśniam innym, jak powinni postępować."],
    ["Potrafię być samokrytyczny.", "Szybko czuję się odpowiedzialny."],
    ["Stawiam opór, gdy ktoś lub coś nie zgadza się z zasadami.", "Nie mogę znieść, gdy inni mnie nie lubią."],
    ["Potrafię być bardzo wymagający wobec innych.", "Gdy mam coś zrobić, staram się to jak najlepiej wykonać."],
    ["Mam dużą pewność siebie.", "Jestem skromny."],
    ["Łatwo się wycofuję w konfliktach.", "Organizuję rzeczy samodzielnie, gdy widzę, że nie działają efektywnie."],
    ["Buntuję się przeciw niesprawiedliwości.", "Jestem pomocny."],
    ["Jasno wyrażam swoje niezadowolenie z niesprawiedliwości.", "Szybko nawiązuję zaufanie do innych ludzi."],
    ["Dążę do bycia najlepszym.", "Podziwiam silne osobowości."],
    ["Nie boję się kwestionować powszechnie akceptowanych rzeczy.", "Chętnie wyjaśniam."],
    ["Nie pozwalam sobie na bycie wykorzystywanym.", "Mam tendencję do odczuwania współczucia."],
    ["Podejmuję niezależne decyzje.", "Jestem uprzejmy dla każdego."],
    ["W konfliktach trzymam się swojego zdania.", "Ustalam jasne porozumienia."],
    ["Potrafię wystąpić przed dużą grupą i przemówić do innych.", "Chronię słabszych."],
    ["Nie boję się pokazywać siebie takim, jaki jestem.", "Wyjaśniam innym, jak powinni postępować."]
]