import React, { createContext, useContext, useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { Cookies } from 'react-cookie'
import lifecycle from 'page-lifecycle'
import i18n from "../translations/i18n"
import "../translations/i18n"
import axios from 'axios'
import Bowser from "bowser";

const globalStateContext = createContext();

export function GlobalStateContext({ children }) {
  const { t } = useTranslation(['translation', 'internal'])
  const [API_PATH] = useState('https://assessment-center.alphatop.com/api/')
  const [globalError, setGlobalError] = useState(false)
  const [activeComponent, setActiveComponent] = useState('')
  const [activePage, setActivePage] = useState('1')
  const [currentProgress, setCurrentProgress] = useState(0)
  const [fillTime, setFillTime] = useState(0)
  const [language, setLanguage] = useState('de')
  const [tempDataIsSet, setTempDataIsSet] = useState(false)
  const [alphaRecruitUrlParams, setAlphaRecruitUrlParams] = useState({ res_id: null, res_model: '', token: '' })
  const [userData, setUserData] = useState({ name: '', company: '', branch: '', department: '' })
  const [valuesComm, setValuesComm] = useState({})
  const [valuesThink, setValuesThink] = useState({})

  const [selectedCompany, setSelectedCompany] = useState("")
  const [managementUnits, setManagementUnits] = useState({})

  useEffect(() => {
    if (sessionStorage.length > 0) {
      setActiveComponent(sessionStorage.getItem("component"));
      setActivePage(sessionStorage.getItem("page"));
      setCurrentProgress(Number(sessionStorage.getItem("progress")));
      setFillTime(Number(sessionStorage.getItem("filltime")));
      handleLanguageChange(sessionStorage.getItem("language"));
      setTempDataIsSet(sessionStorage.getItem("tempDataIsSet"));
      setAlphaRecruitUrlParams(JSON.parse(sessionStorage.getItem("urlParams")));
      setUserData(JSON.parse(sessionStorage.getItem("userData")));
    }
  }, [])

  useEffect(() => {
    const onVisibilityChange = (e) => {
      e.preventDefault()
      e.returnValue = 'return something'
      sessionStorage.setItem("component", activeComponent);
      sessionStorage.setItem("page", activePage);
      sessionStorage.setItem("progress", currentProgress != null ? currentProgress : 0);
      sessionStorage.setItem("filltime", fillTime);
      sessionStorage.setItem("language", language);
      sessionStorage.setItem("tempDataIsSet", tempDataIsSet)
      sessionStorage.setItem("urlParams", JSON.stringify(alphaRecruitUrlParams));
      sessionStorage.setItem("userData", JSON.stringify(userData));
    }
    lifecycle.addEventListener("statechange", onVisibilityChange)
    return () => lifecycle.removeEventListener("statechange", onVisibilityChange)
  }, [activeComponent, activePage, currentProgress, fillTime, language, tempDataIsSet, alphaRecruitUrlParams, userData])

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  }

  const updateFillTime = useCallback(() => {
    if (activePage === 'Assessment') {
      const interval = setTimeout(() => setFillTime(fillTime + 1000), 1000)
      return () => clearTimeout(interval)
    } else if (activePage === 'Introduction') {
      setFillTime(0)
    }
  }, [fillTime, setFillTime, activePage])

  const fetchTempData = useCallback((res_id) => {
    const cookies = new Cookies()
    let cookie_user_data
    if (!cookie_user_data) {
      cookie_user_data = cookies.get('userData');
    }
    userData && axios.post(API_PATH + 'create-participant.php', { method: 'read', res_id: res_id, participant_id: cookie_user_data ? cookie_user_data.participant_id : userData.participant_id })
      .then(res => {
        let tempData = res.data.temp ? JSON.parse(res.data.temp) : {}
        if (res.data.temp) {
          if (!sessionStorage.getItem("tempDataIsSet")) {
            setTempDataIsSet(true)
            setCurrentProgress(cookies.get('selectedBtns') != null ? cookies.get('selectedBtns').length : (tempData.progress != null ? tempData.progress : 0))
            setFillTime(tempData.filltime != null ? tempData.filltime : 0)
            setActivePage(tempData.page)
            setActiveComponent(cookies.get('activeComponent') != null && tempData.page === "Assessment" ? cookies.get('activeComponent') : tempData.component)
            cookie_user_data && setUserData(cookie_user_data)
          }
        }
        if (!tempData.component || tempData.component === 'Home') {
          document.querySelector("#content").style.background = "url('images/alphatop/background.jpg') 40% 18% / cover no-repeat";
        }
      })
      .catch(e => { setGlobalError(e.message) })
  }, [API_PATH, userData, setActiveComponent, setActivePage, setCurrentProgress, setFillTime, setTempDataIsSet])

  const updateTempData = useCallback(() => {
    if (activeComponent === 'Certificates' || activeComponent === 'Introduction' || activePage === 'Introduction' || activePage === 'Complete') {
      userData && axios.post(API_PATH + 'create-participant.php', { method: 'update', participant_id: userData.participant_id, res_id: alphaRecruitUrlParams.res_id, data: JSON.stringify({ component: activeComponent, page: activePage, userAgent: Bowser.parse(window.navigator.userAgent), language: language }) })
        .catch(e => { setGlobalError(e.message) })
    }
  }, [API_PATH, activeComponent, activePage, language, userData, alphaRecruitUrlParams])

  const restartAssessment = () => {
    userData && axios.post(API_PATH + 'create-participant.php', { method: 'update', participant_id: userData.participant_id, res_id: alphaRecruitUrlParams.res_id, data: '' })
      .then(() => {
        const cookies = new Cookies()
        cookies.remove("userData", { path: '/' })
        cookies.remove("selectedBtns", { path: '/' })
        sessionStorage.clear()
        setActiveComponent('Home')
        setActivePage('1')
        setCurrentProgress(0)
        setTempDataIsSet(false)
        alert(t('Header.alert_assessment_reset'))
      })
      .catch(e => { setGlobalError(e.message) })
  }

  function setStyleInternal() {
    document.body.style.background = '#fff';
    Object.assign(document.getElementById('main-container').style, { backgroundColor: '#fff', maxWidth: '2332px' })
    document.getElementById('nav-container').style.width = '1320px';
  }

  useEffect(() => {
    axios.post(API_PATH + 'read-managament-units.php')
      .then(res => { setManagementUnits({ companies: res.data.companies, branches: res.data.branches }) })
      .catch(e => { setGlobalError(e.message) })
  }, [API_PATH])

  function Companies() {
    let content = []
    for (let i in managementUnits.companies) {
      content.push(<option value={managementUnits.companies[i].company_short} key={managementUnits.companies[i].company_short}>{managementUnits.companies[i].company}</option>)
    }
    return content
  }

  function Branches() {
    let content = []
    for (let i in managementUnits.branches) {
      if (managementUnits.branches[i].company === selectedCompany) {
        content.push(<option value={managementUnits.branches[i].branch} key={managementUnits.branches[i].branch}>{managementUnits.branches[i].branch}</option>)
      }
    }
    return content
  }

  return (
    <globalStateContext.Provider
      value={{ t, API_PATH, globalError, setGlobalError, activeComponent, setActiveComponent, activePage, setActivePage, currentProgress, setCurrentProgress, fillTime, setFillTime, updateFillTime, language, handleLanguageChange, tempDataIsSet, setTempDataIsSet, alphaRecruitUrlParams, setAlphaRecruitUrlParams, userData, setUserData, valuesComm, setValuesComm, valuesThink, setValuesThink, fetchTempData, updateTempData, restartAssessment, setStyleInternal, Companies, Branches, selectedCompany, setSelectedCompany }}
    >
      {children}
    </globalStateContext.Provider>
  );
}

export function useGlobalState() {
  return useContext(globalStateContext);
}