import React, { useState, useEffect, useCallback, useMemo } from "react"
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useUserAuth } from "../context/UserAuthContext";
import { Form, Alert, Button } from "react-bootstrap"
import { useTable, useGlobalFilter, useAsyncDebounce, useSortBy, usePagination } from 'react-table'
import { Modal } from 'rsuite'
import { Edit, Trash } from '@rsuite/icons';
import axios from 'axios'

const UserManagement = () => {
    const { t, API_PATH, setStyleInternal, Companies, Branches, selectedCompany, setSelectedCompany } = useGlobalState()
    const { userAccessRights } = useUserAuth()
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [openEditDialog, setOpenEditDialog] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const handleCloseEditDialog = () => setOpenEditDialog(false)
    const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)
    const [userId, setUserId] = useState(null)
    const [email, setEmail] = useState('')
    const [operatingUnits, setOperatingUnits] = useState('')
    const [isAdmin, setIsAdmin] = useState(null)
    const [active, setActive] = useState(null)

    useEffect(() => {
        setStyleInternal()
    }, [setStyleInternal])

    const handleOpenEditDialog = useCallback((id, userData) => {
        setUserId(id)
        setEmail(userData.email)
        setSelectedCompany(userData.company_short)
        setOperatingUnits(userData.branch)
        setIsAdmin(userData.isAdmin)
        setActive(userData.active)
        setOpenEditDialog(true)
    }, [setSelectedCompany])

    const handleOpenDeleteDialog = (id) => {
        setUserId(id)
        setOpenDeleteDialog(true)
    }

    const fetchUserData = useCallback(() => {
        if (!data.length && userAccessRights) {
            axios.post(API_PATH + 'user-management.php', { action: 'read' })
                .then(res => {
                    for (let i in res.data) {
                        let date = new Date(res.data[i].date)
                        let timestamp_formatted = String(date.getDate()).padStart(2, '0') + "." + String(date.getMonth() + 1).padStart(2, '0') + "." + date.getFullYear().toString().substr(2) + " " + String(date.getHours()).padStart(2, '0') + ":" + String(date.getMinutes()).padStart(2, '0')

                        setData(prevItems => [...prevItems, {
                            id: res.data[i].id,
                            email: res.data[i].email,
                            company: res.data[i].company,
                            company_short: res.data[i].company_short,
                            branch: res.data[i].branch,
                            date: timestamp_formatted,
                            isAdmin: res.data[i].isAdmin === '1' ? t('Internal.yes', { ns: "internal" }) : t('Internal.no', { ns: "internal" }),
                            active: res.data[i].active === '1' ? t('Internal.yes', { ns: "internal" }) : t('Internal.no', { ns: "internal" }),
                            edit: <button onClick={() => handleOpenEditDialog(res.data[i].id, res.data[i])}><Edit /></button>,
                            delete: <button onClick={() => handleOpenDeleteDialog(res.data[i].id, res.data[i])}><Trash /></button>,
                        }])
                    }
                    setLoading(false)
                })
                .catch(e => { setError(e.message) })
        }
    }, [API_PATH, data.length, t, userAccessRights, handleOpenEditDialog])

    useEffect(() => {
        fetchUserData()
    }, [fetchUserData])

    const handleEdit = useCallback((e) => {
        e.preventDefault()
        axios.post(API_PATH + 'user-management.php', { action: 'edit', id: userId, userData: { email: email, company: selectedCompany, operatingUnits: operatingUnits, isAdmin: isAdmin, active: active } })
            .then(res => {
                let newArr = [...data]
                for (let i in newArr) {
                    if (newArr[i].id === userId) {
                        newArr[i].email = res.data.email
                        newArr[i].company = res.data.company
                        newArr[i].branch = res.data.operatingUnits
                        newArr[i].isAdmin = res.data.isAdmin === '1' ? t('Internal.yes', { ns: "internal" }) : t('Internal.no', { ns: "internal" })
                        newArr[i].active = res.data.active === '1' ? t('Internal.yes', { ns: "internal" }) : t('Internal.no', { ns: "internal" })
                    }
                }
                setData(newArr)
                handleCloseEditDialog()
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, data, t, userId, email, selectedCompany, operatingUnits, isAdmin, active])

    const handleDelete = useCallback((e) => {
        e.preventDefault()
        axios.post(API_PATH + 'user-management.php', { action: 'delete', id: userId })
            .then(() => {
                setData(data.filter(item => item.id !== userId))
                handleCloseDeleteDialog()
            })
            .catch(e => { setError(e.message) })
    }, [API_PATH, data, userId])

    const columns = useMemo(
        () => [
            { Header: 'ID', accessor: 'id' },
            { Header: t('App.email'), accessor: 'email' },
            { Header: t('App.company'), accessor: 'company' },
            { Header: t('App.operating_unit'), accessor: 'branch' },
            { Header: t('Internal.created', { ns: "internal" }), accessor: 'date' },
            { Header: t('Users.isAdmin', { ns: "internal" }), accessor: 'isAdmin' },
            { Header: t('Users.active', { ns: "internal" }), accessor: 'active' },
            { Header: 'Edit', accessor: 'edit' },
            { Header: 'Del', accessor: 'delete' }
        ],
        [t]
    )

    const {
        getTableProps, getTableBodyProps, headerGroups, prepareRow, state, setGlobalFilter, page, canPreviousPage, canNextPage, pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize }
    } = useTable({
        columns, data, initialState: { pageIndex: 0 }
    }, useGlobalFilter, useSortBy, usePagination)

    function GlobalFilter({ globalFilter, setGlobalFilter }) {
        const [value, setValue] = React.useState(globalFilter)
        const onChange = useAsyncDebounce(value => {
            setGlobalFilter(value || undefined)
        }, 800)

        return (
            <span>
                {t('Internal.search', { ns: "internal" })}:{' '}
                <input value={value || ""} onChange={e => { setValue(e.target.value); onChange(e.target.value) }} placeholder={t('Users.placeholder', { ns: "internal" })} style={{ width: '92.5%', paddingLeft: '0.25rem', marginLeft: '0.5rem', fontSize: '1rem' }} key="search" />
            </span>
        )
    }

    return (
        <div className="mt-4 p-3 text-start">
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="modal-container">
                <Modal backdrop="static" role="alertdialog" open={openEditDialog} onClose={handleCloseEditDialog}>
                    <Modal.Header />
                    <Modal.Body>
                        <div className="p-4">
                            <h3 className="mb-4">{t('Users.edit_user', { ns: "internal" })}</h3>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleEdit} autoComplete="off">
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label><b>{t('App.email')}</b></Form.Label>
                                    <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} required />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formActive">
                                    <Form.Label><b>{t('Users.active', { ns: "internal" })}</b></Form.Label>
                                    <Form.Check defaultChecked={active === '1' ? true : false} onChange={e => setActive(e.target.checked ? '1' : '0')} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formCompany">
                                    <Form.Label><b>{t('App.company')}</b></Form.Label>
                                    <Form.Select value={selectedCompany} onChange={e => setSelectedCompany(e.target.value)} required>
                                        <option value="Gesamt" key="Gesamt">Gesamte Labora (Alle Unternehmen)</option>
                                        <option value="OMS" key="OMS">OMS Group</option>
                                        <Companies />
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formOU">
                                    <Form.Label><b>{t("App.operating_unit")}</b></Form.Label>
                                    <Form.Select value={operatingUnits} onChange={e => setOperatingUnits(e.target.value)} required>
                                        <option hidden value="" key=""></option>
                                        <option value="Gesamt" key="Gesamt">Gesamt (Alle OU's)</option>
                                        <Branches />
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formIsAdmin">
                                    <Form.Label><b>{t('Users.isAdmin', { ns: "internal" })}</b></Form.Label>
                                    <Form.Check defaultChecked={isAdmin === '1' ? true : false} onChange={e => setIsAdmin(e.target.checked ? '1' : '0')} />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="Submit">{t('Internal.edit', { ns: "internal" })}</Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
            <div className="modal-container">
                <Modal backdrop="static" role="alertdialog" open={openDeleteDialog} onClose={handleCloseDeleteDialog} size="xs">
                    <Modal.Body>
                        {t('Users.warning_delete_user', { ns: "internal" })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleDelete} variant="primary">{t('Users.yes_delete', { ns: "internal" })}</Button>
                        <Button onClick={handleCloseDeleteDialog} variant="secondary">{t('App.cancel')}</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className='float-start'>
                <table id='tableArchive' width='1000' {...getTableProps()}>
                    <thead>
                        <tr>
                            <th colSpan='2' className='thArchive' style={{ border: 'none', paddingLeft: '0' }}><h4>{t('Nav.users')}</h4></th>
                        </tr>
                        <tr>
                            <th colSpan='9' className='pb-2 ps-1 pe-0'>
                                <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter} />
                            </th>
                        </tr>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className='thArchive' {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>{column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}</span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                        {loading &&
                            <tr><th className='thArchive w-100'><img alt={t("App.loading")} src={process.env.PUBLIC_URL + '/images/gif/loading.gif'} width='200' /></th></tr>
                        }
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row, i) => {
                            prepareRow(row)
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return <td className='tdArchive' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                    })}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="pagination mt-3 d-flex justify-content-between" style={{ lineHeight: '25px' }}>
                    <select value={pageSize} className='px-1' onChange={e => { setPageSize(Number(e.target.value)) }}>
                        {[10, 25, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>{t('Internal.show', { ns: "internal" })} {pageSize}</option>
                        ))}
                    </select>
                    <span className='page-text'>{t("App.page")} <b>{pageIndex + 1} {t('App.out_of')} {pageOptions.length}</b></span>
                    <div>
                        <button className='btn-secondary px-2' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>{'<<'}</button>{' '}
                        <button className='btn-secondary px-2' onClick={() => previousPage()} disabled={!canPreviousPage}>{'<'}</button>{' '}
                        <button className='btn-secondary px-2' onClick={() => nextPage()} disabled={!canNextPage}>{'>'}</button>{' '}
                        <button className='btn-secondary px-2' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>{'>>'}</button>{' '}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement