export const CHARACTERISTICS_COMM_HU = [
  [
    "Szeretem megmagyarázni a dolgokat.",
    "Szeretek segíteni."
  ],
  [
    "Hajlamos vagyok együttérzni másokkal.",
    "Mindig kész vagyok kompromisszumot kötni."
  ],
  [
    "Mindenkivel barátságos vagyok.",
    "Lelkiismeretes vagyok."
  ],
  [
    "Csodálom az erős személyiségeket.",
    "Könnyen visszahátrálok a konfliktusoktól."
  ],
  [
    "Szégyenlős vagyok - félénk.",
    "Gyorsan vitatom a dolgokat."
  ],
  [
    "Nem félek kételkedni vagy megkérdőjelezni általánosan elfogadott dolgokat.",
    "Nem hagyom magam az orromnál fogva vezetni."
  ],
  [
    "Konfliktus esetén kiállok a véleményem mellett.",
    "Mindig a legjobb akarok lenni."
  ],
  [
    "Magam kezébe veszem dolgok irányítását, ha látom, hogy nem működnek hatékonyan.",
    "A személyes véleményemmel háttérbe húzódom, hogy elkerüljem a konfliktusokat."
  ],
  [
    "Megvédem azokat az embereket, akik bizonytalanok.",
    "Tartom magam a megállapodásokhoz."
  ],
  [
    "Szörnyűnek tartom, ha mások nem kedvelnek engem.",
    "Szerény vagyok."
  ],
  [
    "Mindig igyekszem a legjobbamat nyújtani, még akkor is ha a feladat amit el kell végezzek unalmas.",
    "Csak olyan dolgokat fogadok el, amelyeknek értelmét látom."
  ],
  [
    "Nekem nem okoz gondot a bocsánatkérés.",
    "Nagyon igényes tudok lenni."
  ],
  [
    "Nem bírom a korlátozásokat.",
    "Képes vagyok önálló döntéseket hozni - meg tudom hozni a saját döntéseimet."
  ],
  [
    "Nehéz vitákban én hozhatom meg a döntést.",
    "Ha megkérnek valamire, mindent megteszek, hogy a legjobb tudásom szerint elvégezzem."
  ],
  [
    "Könnyen/gyorsan megbocsátok másoknak.",
    "Tétovázom, mielőtt bármit is tennék vagy mondanék."
  ],
  [
    "Gyorsan elnézést kérek másoktól.",
    "Panaszkodom amennyiben a dolgok rosszul sikerülnek."
  ],
  [
    "Gyorsan csatlakozom a harsány és vezető véleményekhez.",
    "Egyenes és közvetlen vagyok."
  ],
  [
    "Én a háttérben maradok.",
    "Jól tudok magamról beszélni."
  ],
  [
    "A csoportban gyakran én kezdeményezek.",
    "Gyorsan zavarba jövök, ha a dolgok nem úgy mennek, ahogy terveztem."
  ],
  [
    "Szeretek vigaszt nyújtani másoknak.",
    "Megalapozott kritikát tudok megfogalmazni."
  ],
  [
    "Mindenkivel jól kijövök.",
    "Az idegesítő vagy tolakodó embereket a helyükre tudom tenni."
  ],
  [
    "Szívesen fogadok el tanácsokat.",
    "Magamért én felelek."
  ],
  [
    "Világos megállapodásokat kötök.",
    "Ellenállok, ha valami nem felel meg a szabályoknak."
  ],
  [
    "Hajlamos vagyok felelősséget érezni minden iránt.",
    "Szükség esetén nagyon szigorú tudok lenni."
  ],
  [
    "Alkalmazkodó vagyok.",
    "Nem félek megmutatni, ki vagyok valójában."
  ],
  [
    "Gondoskodom arról, hogy a csapatban mindenki kivegye a részét a feladatból.",
    "Az igazságtalanságot nem tűröm, és fel is lépek ellene."
  ],
  [
    "Gondoskodom róla, hogy mindenki megkapja azt, ami neki jár.",
    "Nagyon az elvárásaim magammal szemben."
  ],
  [
    "Mindig vállalom a felelősséget.",
    "Ismerem a képességeimet."
  ],
  [
    "Segítőkész vagyok.",
    "Tudok utasításokat adni másoknak."
  ],
  [
    "Szeretek másokkal együtt dolgozni.",
    "Szeretek adni másoknak."
  ],
  [
    "Tisztelem a tekintélyt.",
    "Nem esik nehezemre bízni másokban."
  ],
  [
    "Tudok utasításokat adni másoknak.",
    "Amennyiben szükségem van rá, nem esik nehezemre segítséget kérni."
  ],
  [
    "Engem nem könnyű meggyőzni.",
    "Visszafogott vagyok - nem engedem el magam könnyen."
  ],
  [
    "Szeretek másokat ugratni.",
    "Nem bírom, ha 'rövid gyeplőnél fogva vezetnek'. / Szeretem a személyes szabadságomat."
  ],
  [
    "Nagy csoportokban is tudom érvényesíteni az akaratomat és tudok mások előtt/hoz beszélni.",
    "Elviselem a nyílt nézeteltérést."
  ],
  [
    "Kerülöm a nehézségeket.",
    "Elmagyarázom másoknak, hogyan kell eljárniuk."
  ],
  [
    "Könnyen alkalmazkodom.",
    "Án magamra vigyázom."
  ],
  [
    "Inkább békén hagyok másokat, amint konfliktus helyzetbe kerülök.",
    "Személyes véleményemet háttérbe helyezem, hogy elkerüljem a vitákat."
  ],
  [
    "Nem hozok elhamarkodott döntéseket.",
    "Tartom magam a megállapodásokhoz."
  ],
  [
    "Elhallgathatok néhány információt a feletteseim elől.",
    "Habozom, mielőtt bármit mondanék vagy tennék."
  ],
  [
    "Szeretem magam másokhoz mérni.",
    "Engem nem könnyű meggyőzni."
  ],
  [
    "Igyekszem követni még az unalmas feladatokat is.",
    "Elmagyarázom másoknak, hogyan kell eljárniuk."
  ],
  [
    "Képes vagyok önkritikát gyakorolni.",
    "Gyorsan felelősnek érzem magam a feladatokért."
  ],
  [
    "Ellenállok, ha valami nem felel meg a szabályoknak.",
    "Nem bírom elviselni, ha mások nem kedvelnek."
  ],
  [
    "Nagyon követelőző tudok lenni másokkal szemben.",
    "Ha megkérnek valamire, mindent megteszek, hogy teljesítsem azt."
  ],
  [
    "Nagy önbizalmam van.",
    "Szerény vagyok."
  ],
  [
    "Könnyen meghátrálok egy konfliktusban.",
    "Kezembe veszem a dolgok irányítását, ha úgy látom, hogy nem működnek hatékonyan."
  ],
  [
    "Lázadok az igazságtalanság ellen.",
    "Segítőkész vagyok."
  ],
  [
    "Az igazságtalanságot nem tűröm, és fel is lépek ellene.",
    "Gyorsan megbízom másokban."
  ],
  [
    "Arra törekszem, hogy a legjobb legyek.",
    "Csodálom az erős személyiségeket."
  ],
  [
    "Nem félek megkérdőjelezni az általánosan elfogadott dolgokat.",
    "Szívesen elmagyarázom a dolgokat."
  ],
  [
    "Nem hagyom magam az orromnál fogva vezetni.",
    "Hajlamos vagyok együttérzni másokkal."
  ],
  [
    "Önálló döntéseket hozok.",
    "Mindenkivel barátságos vagyok."
  ],
  [
    "Konfliktus esetén kiállok a véleményem mellett.",
    "Világos megállapodásokat kötök."
  ],
  [
    "Nagy csoportokban is tudom érvényesíteni az akaratomat és tudok mások előtt/hoz beszélni.",
    "Megvédem a gyengéket."
  ],
  [
    "Nem félek magamat olyannak mutatni, amilyen vagyok.",
    "Elmagyarázom másoknak, hogyan kellene viselkedniük."
  ],
]