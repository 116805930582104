export const INTERNAL_EN = {
    Auth: {
        heading_login: 'Employee Login',
        heading_signup: 'Employee Signup',
        password: 'Password',
        confirm: 'Confirm Password',
        alert_passwords_must_match: 'The passwords must match',
        log_in: 'Log In',
        log_out: 'Log Out',
        sign_up: 'Sign Up',
        reset_password: 'Reset Password',
        dont_have_an_account: 'Don\'t have an account?',
        already_have_an_account: 'Already have an account?',
        forgot_password: 'Forgot Password?'
    },
    Internal: {
        heading: 'Internal Dashboard',
        search: 'Search',
        show: 'Show',
        created: 'last modified',
        edit: 'Edit',
        yes: 'yes',
        no: 'no'
    },
    Archive: {
        placeholder: 'Filter by name, company, branch, department...',
        comm: 'Communication',
        think: 'Thinking Styles',
        warning_delete_cert: 'Do you really want to delete the participant´s certificates?',
        yes_delete: 'Yes, delete certificates'
    },
    Recovery: {
        results_for: 'Ergebnisse zu ',
        no_results: 'No results found for ',
        heading: 'Recover certificates',
        btn_save_in_archive: 'Store in application',
        btn_download_pdf: 'Download PDF & store in application',
        btn_print: 'Print (optimized for Chromium)',
        alert_certificates_saved: 'The certificates were stored in the application in ALPHA-Recruit.'
    },
    Users: {
        placeholder: 'Filter by email, company, branch...',
        isAdmin: 'Is admin',
        active: 'Active',
        edit_user: 'Edit user',
        warning_delete_user: 'Do you really want to delete the user?',
        yes_delete: 'Yes, delete user'
    }
}