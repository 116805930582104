export const CHARACTERIZATIONS_THINK_DE = [
    ["Unvoreingenommen", "Ich bin für neue Ideen und Ansichten offen und empfänglich, auch wenn sie von meinen abweichen.", "Simultan", "In der Lage sein, mehrere verschiedenartige Aufgaben gleichzeitig zu bearbeiten."],
    ["Kreativ", "Schöpferisch tätig sein, Ideen haben und diese auch gestalterisch verwirklichen.", "Enthusiastisch", "Ich engagiere mich leicht mit großer Begeisterung für etwas."],
    ["Konzeptionell", "Selbst und selbständig etwas entwerfen, verfassen (z. B. Vorschläge, Ausarbeitungen, Pläne etc.).", "Praktisch", "Ich bevorzuge konkretes Handeln gegenüber theoretischer Betrachtung."],
    ["Phantasievoll", "Fähigkeiten zur Vorstellung von Dingen, die mit den Sinnen nicht erfassbar sind oder in der Realität nicht existieren. Fähigkeit, Probleme auf eine neuartige Weise anzugehen.", "Quantitativ", "Neigung, Sachverhalte nach messbaren Kriterien zu betrachten und zu bewerten. Ich orientiere mich gerne an Zahlen."],
    ["Entdeckertyp", "Ich begebe mich gern in unbekanntes Gelände und versuche neue Ideen oder Konzepte zu finden.", "Kreativ", "Schöpferisch tätig sein, Ideen haben und diese auch gestalterisch verwirklichen."],
    ["Strategisch", "Ich kann strategisch denken und Dinge in ihrem Gesamtzusammenhang erkennen und ihre Auswirkungen.", "Leidenschaftlich", "Meine Gefühle und Ideen können mich sehr heftig bewegen."],
    ["Ganzheitlich", "In der Lage sein, einzelne Sachverhalte in ihren gesamtheitlichen Zusammenhängen zu sehen, d. h. statt vieler einzelner Bäume den Wald zu sehen.", "Organisiert", "Ich gestalte gerne den Ablauf eines Projekts zu einem funktionierenden Ganzen."],
    ["Risikofreudig", "Ich bin bereit, unsichere Entscheidungen zu treffen und darin Chancen zu sehen.", "Logisch", "Nach Gesetzmäßigkeiten folgerichtig und schlüssig denken."],
    ["Leidenschaftlich", "Meine Gefühle und Ideen können mich sehr heftig bewegen.", "Integrierend", "Ich habe die Fähigkeit, Teile und Elemente von Ideen, Konzepten und Situationen zu einem Ganzen zu kombinieren."],
    ["Hilfsbereit", "Ich biete anderen gern meine Hilfe an und unterstütze sie bei Schwierigkeiten.", "Intuitiv (Gefühl)", "Hat ein Gespür für die Befindlichkeit von anderen Menschen und kann mich ohne rationale Begründung auf mein Gefühl von Sympathie und Antipathie verlassen. Etwas gefühlsmäßig erkennen, ohne faktische Nachweise zu haben."],
    ["Gefühlsbetont", "Ich empfinde Dinge sehr intensiv und ich kann meine Gefühle auch zeigen.", "Beharrlich", "Ich bin zäh und ausdauernd beim Verfolgen meiner Ziele."],
    ["Freundlich", "Ich bin gern liebenswürdig, nett und herzlich.", "Faktenbasiert", "Mir ist es wichtig die genauen Fakten zu kennen und meine Entscheidung an diesen Fakten zu orientieren."],
    ["Mitfühlend", "Ich verstehe häufig die Gefühle anderer und kann auf andere Menschen eingehen.", "Konzeptionell", "Selbst und selbständig etwas entwerfen, verfassen (z. B. Vorschläge, Ausarbeitungen, Pläne etc.)"],
    ["Harmonisierend", "Ich neige dazu, Dinge und Gefühle in Einklang zu bringen.", "Hilfsbereit", "Ich biete anderen gern meine Hilfe an und unterstütze sie bei Schwierigkeiten."],
    ["Gastfreundlich", "Ich lade Menschen gern zu mir ein, um mit ihnen zusammen zu sein.", "Detailliert", "Kleine und kleinste Bestandteile eines Sachverhaltes berücksichtigen und darauf Wert legen."],
    ["Entgegenkommend", "Ich begegne anderen Menschen offen und liebenswürdig.", "Mathematisch", "Die Neigung, mit Zahlen und Daten umzugehen und sie verarbeiten zu können."],
    ["Organisiert", "Ich gestalte gerne den Ablauf eines Projekts zu einem funktionierenden Ganzen.", "Künstlerisch", "Vorliebe oder Talent zum Malen, Zeichnen oder Bildhauern. Neigung zu ästhetisch-künstlerischer Gestaltung in unterschiedlichen Lebensbereichen."],
    ["Detailliert", "Kleine und kleinste Bestandteile eines Sachverhaltes berücksichtigen und darauf Wert legen.", "Zwischenmenschlich", "Beziehungen zu vielen und unterschiedlichen Menschen aufbauen und dauerhaft pflegen."],
    ["Diszipliniert", "Ich bin beherrscht und kann meine Vorsätze unbeirrt verfolgen.", "Strukturiert", "Ich mag eine systematische Gliederung und einen festgelegten Rahmen."],
    ["Praktisch", "Ich bevorzuge konkretes Handeln gegenüber theoretischer Betrachtung.", "Analytisch", "Dinge oder Ideen in Teile zerlegen, um Gesetzmäßigkeiten und Beziehungen zwischen Einzelteilen zu erkennen."],
    ["Dominant", "Sich gegenüber anderen durchsetzen und steuern wollen. Ich neige dazu den Ton anzugeben.", "Unvoreingenommen", "Ich bin für neue Ideen und Ansichten offen und empfänglich, auch wenn sie von meinen abweichen."],
    ["Planer", "Ich bestimme gerne im Voraus die notwendigen Schritte, um ein bestimmtes Ziel zu erreichen.", "Gefühlsbetont", "Ich empfinde Dinge sehr intensiv und ich kann meine Gefühle auch zeigen."],
    ["Sequentiell", "Sachverhalte in eine Reihenfolge zergliedern und entsprechend behandeln, d. h. eins nach dem anderen erledigen.", "Diszipliniert", "Ich bin beherrscht und kann meine Vorsätze unbeirrt verfolgen."],
    ["Pünktlich", "Es ist für mich wichtig, Termine einzuhalten und meine Zeit zu kontrollieren.", "Eindeutig", "Ich kann klare und eindeutige Aussagen treffen und die Dinge auf den Punkt bringen."],
    ["Logisch", "Nach Gesetzmäßigkeiten folgerichtig und schlüssig denken.", "Risikofreudig", "Ich bin bereit, unsichere Entscheidungen zu treffen und darin Chancen zu sehen."],
    ["Mathematisch", "Die Neigung, mit Zahlen und Daten umzugehen und sie verarbeiten zu können.", "Vertrauensvoll", "Ich habe anderen Menschen gegenüber eine positive Einstellung und verlasse mich gerne auf sie."],
    ["Eindeutig", "Ich kann klare und eindeutige Aussagen treffen und die Dinge auf den Punkt bringen.", "Prozessbezogen", "Ich lege für mein Vorgehen gern bestimmte Verfahrensweisen fest, denen ich dann folgen kann."],
    ["Wissenschaftlich", "Ich beschäftige mich gerne mit Gesetzmäßigkeiten und logisch erklärbaren und nachweisbaren Tatsachen.", "Mag Zahlen", "Orientiert an Zahlenverhältnissen. Neigung, exakte Zahlen und Maße zu finden."],
    ["Deutlich", "Ich habe es gerne mit deutlichen Aussagen zu tun, die nachvollziehbare Fakten enthalten.", "Phantasievoll", "Fähigkeiten zur Vorstellung von Dingen, die mit den Sinnen nicht erfassbar sind oder in der Realität nicht existieren. Fähigkeit, Probleme auf eine neuartige Weise anzugehen."],
    ["Rational", "Sachverhalte nicht gefühlsmäßig, sondern mit Verstand und Vernunft zu sehen und zu bewerten.", "Freundlich", "Ich bin gern liebenswürdig, nett und herzlich."],
    ["Quantitativ", "Neigung, Sachverhalte nach messbaren Kriterien zu betrachten und zu bewerten. Ich orientiere mich gerne an Zahlen.", "Praktisch", "Ich bevorzuge konkretes Handeln gegenüber theoretischer Betrachtung."],
    ["Technisch", "Wissen aus dem Ingenieur- und naturwissenschaftlichen Bereich verstehen und anwenden.", "Wissenschaftlich", "Ich beschäftige mich gerne mit Gesetzmäßigkeiten und logisch erklärbaren und nachweisbaren Tatsachen."],
    ["Simultan", "In der Lage sein, mehrere verschiedenartige Aufgaben gleichzeitig zu bearbeiten.", "Einfallsreich", "Ich habe in Projekten oft viele kreative Ideen und Vorschläge."],
    ["Integrierend", "Ich habe die Fähigkeit, Teile und Elemente von Ideen, Konzepten und Situationen zu einem Ganzen zu kombinieren.", "Gesellig", "Ich liebe es mit Menschen zu tun zu haben und und arbeite lieber im Team als alleine."],
    ["Künstlerisch", "Vorliebe oder Talent zum Malen, Zeichnen oder Bildhauern. Neigung zu ästhetisch-künstlerischer Gestaltung in unterschiedlichen Lebensbereichen.", "Fleißig", "Ich habe Freude am Arbeiten."],
    ["Risikofreudig", "Ich bin bereit, unsichere Entscheidungen zu treffen und darin Chancen zu sehen.", "Kritisch", "Sorgfältiges Prüfen und Bewerten, z. B. von Projekten oder Ideen. Meine Haltung ist häufig abwartend."],
    ["Einfallsreich", "Ich habe in Projekten oft viele kreative Ideen und Vorschläge.", "Entdeckertyp", "Ich begebe mich gern in unbekanntes Gelände und versuche neue Ideen oder Konzepte zu finden."],
    ["Neugierig", "Ich bin ein Mensch, der viel fragt und gern über alle Bescheid weiß.", "Mitfühlend", "Ich verstehe häufig die Gefühle anderer und kann auf andere Menschen eingehen."],
    ["Visionär", "Ich habe ein gutes Vorstellungsvermögen und denke gerne in der Zukunft.", "Dominant", "Sich gegenüber anderen durchsetzen und steuern wollen. Ich neige dazu den Ton anzugeben."],
    ["Sinnbildlich", "Denken in Bildern und Gleichnissen.", "Deutlich", "Ich habe es gerne mit deutlichen Aussagen zu tun, die nachvollziehbare Fakten enthalten."],
    ["Gesellig", "Ich liebe es mit Menschen zu tun zu haben und und arbeite lieber im Team als alleine.", "Neugierig", "Ich bin ein Mensch, der viel fragt und gern über alle Bescheid weiß."],
    ["Intuitiv (Gefühl)", "Ich habe ein Gespür für die Befindlichkeit von anderen Menschen und kann mich ohne rationale Begründung auf mein Gefühl von Sympathie und Antipathie verlassen. Etwas gefühlsmäßig erkennen, ohne faktische Nachweise zu haben.", "Ausdrucksstark", "Ich vermittle meine Ansichten sehr eindrücklich und deutlich."],
    ["Zwischenmenschlich", "Beziehungen zu vielen und unterschiedlichen Menschen aufbauen und dauerhaft pflegen.", "Detailliert", "Kleine und kleinste Bestandteile eines Sachverhaltes berücksichtigen und darauf Wert legen."],
    ["Vertrauensvoll", "Ich habe anderen Menschen gegenüber eine positive Einstellung und verlasse mich gerne auf sie.", "Faktenbetont", "Strenge, sich ausschließlich an Tatsachen orientierende Denkweise."],
    ["Enthusiastisch", "Ich engagiere mich leicht mit großer Begeisterung für etwas.", "Strategisch", "Ich kann strategisch denken und Dinge in ihrem Gesamtzusammenhang erkennen und ihre Auswirkungen vorsehen."],
    ["Ausdrucksstark", "Ich vermittle meine Ansichten sehr eindrücklich und deutlich.", "Harmonisierend", "Ich neige dazu, Dinge und Gefühle in Einklang zu bringen."],
    ["Kooperativ", "Ich arbeite gern mit anderen zusammen an einem gemeinsamen Ziel.", "Planer", "Ich bestimme gerne im Voraus die notwendigen Schritte, um ein bestimmtes Ziel zu erreichen."],
    ["Offen für Menschen", "Ich bin offen für Vorschläge und Hilfsangebote von anderen Menschen.", "Rational", "Sachverhalte nicht gefühlsmäßig, sondern mit Verstand und Vernunft zu sehen und zu bewerten."],
    ["Praktisch", "Ich bevorzuge konkretes Handeln gegenüber theoretischer Betrachtung.", "Visionär", "Ich habe ein gutes Vorstellungsvermögen und denke gerne in der Zukunft."],
    ["Beharrlich", "Ich bin zäh und ausdauernd beim Verfolgen meiner Ziele.", "Kooperativ", "Ich arbeite gern mit anderen zusammen an einem gemeinsamen Ziel."],
    ["Strukturiert", "Ich mag eine systematische Gliederung und einen festgelegten Rahmen.", "Absichernd", "Ich bin ein vorsichtiger Mensch, der mit Umsicht die Konsequenz seines Handelns bedenkt."],
    ["Prozessbezogen", "Ich lege für mein Vorgehen gern bestimmte Verfahrensweisen fest, denen ich dann folgen kann.", "Herausfordernd", "Ich neige dazu Sachverhalte in Frage zu stellen und spiele gern den \"Anwalt des Teufels\"."],
    ["Fleißig", "Ich habe Freude am Arbeiten.", "Ganzheitlich", "In der Lage sein, einzelne Sachverhalte in ihren gesamtheitlichen Zusammenhängen zu sehen, d. h. statt vieler einzelner Bäume den Wald zu sehen."],
    ["Detailliert", "Kleine und kleinste Bestandteile eines Sachverhaltes berücksichtigen und darauf Wert legen.", "Gastfreundlich", "Ich lade Menschen gern zu mir ein, um mit ihnen zusammen zu sein."],
    ["Absichernd", "Ich bin ein vorsichtiger Mensch, der mit Umsicht die Konsequenz seines Handelns bedenkt.", "Sequentiell", "Sachverhalte in eine Reihenfolge zergliedern und entsprechend behandeln, d. h. eins nach dem anderen erledigen."],
    ["Konventionell", "Neigung, am Althergebrachten festzuhalten.", "Quantitativ", "Neigung, Sachverhalte nach messbaren Kriterien zu betrachten und zu bewerten. Ich orientiere mich gerne an Zahlen."],
    ["Mathematisch", "Die Neigung, mit Zahlen und Daten umzugehen und sie verarbeiten zu können.", "Sinnbildlich", "Denken in Bildern und Gleichnissen."],
    ["Objektiv", "Ich denke und handle vorurteilsfrei und lasse mich selten durch persönliche Gefühle beeinflussen.", "Offen für Menschen", "Ich bin offen für Vorschläge und Hilfsangebote von anderen Menschen."],
    ["Analytisch", "Dinge oder Ideen in Teile zerlegen, um Gesetzmäßigkeiten und Beziehungen zwischen Einzelteilen zu erkennen.", "Konventionell", "Neigung, am Althergebrachten festzuhalten."],
    ["Mag Zahlen", "Orientiert an Zahlenverhältnissen. Neigung, exakte Zahlen und Maße zu finden.", "Realistisch", "Ich sehe die Dinge so wie sie sind oder sich darstellen und vermeide Spekulation und Einbildung."],
    ["Kritisch", "Sorgfältiges Prüfen und Bewerten, z. B. von Projekten oder Ideen. Meine Haltung ist häufig abwartend.", "Risikofreudig", "Ich bin bereit, unsichere Entscheidungen zu treffen und darin Chancen zu sehen."],
    ["Faktenbetont", "Strenge, sich ausschließlich an Tatsachen orientierende Denkweise.", "Entgegenkommend", "Ich begegne anderen Menschen offen und liebenswürdig."],
    ["Herausfordernd", "Ich neige dazu Sachverhalte in Frage zu stellen und spiele gern den \"Anwalt des Teufels\".", "Pünktlich", "Es ist für mich wichtig, Termine einzuhalten und meine Zeit zu kontrollieren."],
    ["Realistisch", "Ich sehe die Dinge so wie sie sind oder sich darstellen und vermeide Spekulation und Einbildung.", "Technisch", "Wissen aus dem Ingenieur- und naturwissenschaftlichen Bereich verstehen und anwenden."]
  ]