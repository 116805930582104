import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useUserAuth } from "../context/UserAuthContext";

const ForgotPassword = () => {
  const { t } = useGlobalState();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { resetPassword } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await resetPassword(email);
      navigate("/login");
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="p-5 box mt-2 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        <h3 className="mb-4">{t('Auth.reset_password', { ns: 'internal' })}</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder={t('App.email')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              {t('Auth.reset_password', { ns: 'internal' })}
            </Button>
          </div>
        </Form>
      </div>
      <div className="p-4 box mt-3 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        {t('Auth.dont_have_an_account', { ns: 'internal' })} <Link to="/signup">{t('Auth.sign_up', { ns: 'internal' })}</Link>
      </div>
    </>
  );
};

export default ForgotPassword;