export const TRANSLATIONS_PL = {
    App: {
        lang: 'pl',
        title: 'Centrum Oceny',
        welcome: 'Witamy serdecznie',
        page: 'Strona',
        out_of: 'z',
        btn_start: 'Rozpocznij',
        btn_start_now: 'Rozpocznij teraz',
        btn_back: 'Wróć',
        btn_next: 'Dalej',
        btn_previous_page: 'Poprzednia strona',
        btn_next_page: 'Następna strona',
        cancel: 'Anuluj',
        loading: 'Ładowanie...',
        name: 'Imię',
        email: 'Adres e-mail',
        company: 'Firma',
        operating_unit: 'Jednostka operacyjna'
    },
    Nav: {
        dashboard: 'Panel wewnętrzny',
        restart_assessment: 'Rozpocznij ocenę od nowa (Admin)',
        certificates: 'Archiwum certyfikatów',
        recovery: 'Przywróć certyfikaty',
        account_settings: 'Ustawienia konta',
        config: 'Konfiguracja',
        users: 'Zarządzanie użytkownikami',
        log_out: 'Wyloguj się'
    },
    Header: {
        alert_reset_assessment: 'Czy na pewno chcesz zresetować ocenę?',
        warning_reset_assessment: 'Ta funkcja może być używana tylko wtedy, gdy kandydat nie może kontynuować edycji.',
        yes_restart_assessment: 'Tak, zresetuj ocenę',
        alert_assessment_reset: 'Ocena została zresetowana.'
    },
    Home: {
        find_your_strengths: "Odkryj swoje mocne strony!",
        quote_hamlet: "Być gotowym to wszystko!",
        alpha_recruit_error: "Błąd ALPHA Recruit: Nieprawidłowy link",
        alert_user_not_found: "Ten link jest nieprawidłowy. Skontaktuj się z personelem odpowiedzialnym."
    },
    Introduction: {
        introduction: 'Wprowadzenie',
        page_1_heading: 'Witamy serdecznie',
        page_1_1: 'Na następnych stronach dowiesz się, jak wypełnić Centrum Oceny.',
        page_1_2: 'Celem tego jest udzielenie informacji zwrotnej na temat Twojego indywidualnego stylu myślenia i zachowania komunikacyjnego na co dzień.',
        page_1_3: 'Prosimy zauważyć, że Centrum Oceny powinno być wypełniane dopiero od 15 roku życia.',
        page_2_heading: 'Zgoda na przetwarzanie danych',
        page_2_1: 'Przed rozpoczęciem oceny informujemy Cię, w jaki sposób przetwarzamy Twoje dane i jak wyrazić zgodę na przetwarzanie danych.',
        page_2_2: 'Prosimy zapoznaj się z informacjami, a następnie zaznacz pole poniżej, aby wyrazić zgodę.',
        page_2_3: 'Wyrażam zgodę na przechowywanie moich danych osobowych. Wszystkie informacje osobiste są poufne i traktowane z najwyższą starannością. Dane są dostępne tylko dla działu personalnego.',
        data_protection_info: 'Przeczytaj informacje dotyczące ochrony danych',
        alert_data_storage: "Prosimy zaznacz pole powyżej, aby wyrazić zgodę na przechowywanie Twoich danych osobowych.",
        page_3_heading: 'Jak to działa',
        page_3_1: 'Na następnych stronach zostaną przedstawione różne stwierdzenia. Prosimy ocenić je według własnego osobistego odczucia.',
        page_3_2: 'Nie ma prawidłowych ani nieprawidłowych odpowiedzi.',
        page_3_3: 'Tylko uczciwe wypełnienie gwarantuje, że Twoje wyniki będą logiczne i spójne. Dzięki temu będziesz maksymalnie korzystać z wyników.',
        page_3_4: 'Jeśli opisane są sytuacje, których osobiście jeszcze nie doświadczyłeś/-aś - proszę ocenić, jak byś najprawdopodobniej się w nich zachował/-a.',
        page_3_5: 'Jeśli ocena konkretnych stwierdzeń sprawia Ci trudność, proszę wybrać stwierdzenie, które najbardziej Ci odpowiada.',
        page_4_heading: 'Zaczynamy',
        page_4_1: 'Wypełnienie zajmie około 15-20 minut. Czas, który potrzebujesz, nie wpływa na Twój wynik. Prosimy o możliwie spontaniczne odpowiedzi.',
        page_4_2: 'Oczywiście będziemy traktować Twoje odpowiedzi i wyniki ',
        page_4_3: 'absolutnie poufnie.',
        page_4_4: 'Przesyłając na końcu, potwierdzasz automatycznie, że odpowiedziałeś/-aś na pytania uczciwie i bez pomocy innych osób.'
    },
    Assessments: {
        assessment: 'Oceny',
        progress_of_user: 'Postęp użytkownika ',
        current_fill_time: 'Aktualny czas wypełniania: ',
        profile_creation_heading: 'Twój profil jest teraz tworzony. Proszę mieć trochę cierpliwości.', 
        profile_creation_1: 'Dziękujemy za poświęcenie czasu. Twój profil komunikacyjny i stylu myślenia jest teraz tworzony.',
        profile_creation_2: 'Prosimy czekać na utworzenie Twojego profilu. Może to zająć kilka sekund lub minut. W trakcie tego procesu strona musi pozostać otwarta.',
        completed_heading: 'Gratulacje!',
        completed: 'Ukończyłeś/-aś ocenę komunikacji i stylu myślenia. Twój opiekun skontaktuje się wkrótce z Tobą.'
    },
    Communication: {
        name: 'Komunikacja',
        heading: 'Co najlepiej do Ciebie pasuje?',
        introduction_1: 'Na następnych stronach znajduje się 56 sekcji, z których każda zawiera dwa zdania charakteryzujące. Wybierz zdanie, które najlepiej opisuje Ciebie w każdej sekcji.',
        introduction_2: 'Miej na uwadze sytuacje, preferowana w kontekście zawodowym, ale także w życiu prywatnym (np. w klubie sportowym) z "równorzędnymi" członkami zespołu.',
        introduction_3: 'Twoje wybory nie powinny być uwarunkowane hierarchią przełożonych / rodziny / krewnych.',
        completed_heading: 'Ukończono część 1 z 2!',
        completed: 'Ukończyłeś/-aś ocenę komunikacji. Kliknij "Dalej", aby kontynuować drugą część oceny lub "Wstecz", aby sprawdzić swoje wybory.',
    },
    ThinkingStyle: {
        name: 'Styl myślenia',
        heading: 'Jestem raczej...',
        introduction_1: 'Na następnych stronach znajduje się 64 sekcje, z których każda zawiera dwie cechy i wyjaśnienia.',
        introduction_2: 'Która z tych dwóch cech lepiej pasuje do Twojego sposobu myślenia? Wybierz tę cechę.',
        btn_submit: 'Wyślij odpowiedzi', 
        completed_heading: 'Ukończono część 2 z 2!',
        completed: 'Ukończyłeś/-aś ocenę stylu myślenia. Kliknij "Wyślij odpowiedzi", aby zakończyć oceny, lub "Wstecz", aby sprawdzić swoje wybory.'
    },
    Certificates: {
        heading_comm: 'PROFIL KOMUNIKACJI',
        description_comm: 'Model działań i komunikacji pokazuje w formie pozbawionej wartości, w jaki sposób ludzie mogą być postrzegani w komunikacji.',
        heading_think: 'PREFERENCJE W MYŚLENIU',
        description_think: 'Twój profil został utworzony na podstawie Twoich odpowiedzi i wyświetla Twoje preferencje myślenia uporządkowane od silnych do słabych.',
        fill_time: 'Czas trwania testu',
        privacy_note_1: 'Dane są ściśle poufne i mogą być wykorzystywane ',
        privacy_note_2: 'wyłącznie do wewnętrznych celów firmy.',
        evaluation_note: 'Profil może być oceniany wyłącznie przez upoważnione osoby.'
    }
}