import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import { useGlobalState } from "../../contexts/GlobalStateContext";
import { useUserAuth } from "../context/UserAuthContext";
import axios from 'axios'

const Signup = () => {
  const { t, API_PATH, Companies, Branches, selectedCompany, setSelectedCompany } = useGlobalState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [operatingUnits, setOperatingUnits] = useState([]);
  const [error, setError] = useState("");
  const { signUp, logOut } = useUserAuth();
  let navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      if (password === confirmPassword) {
        await signUp(name, email, password);
        navigate("/dashboard");
        axios.post(API_PATH + 'user-access-rights.php', { action: 'create', email: email, company: selectedCompany, operatingUnits: operatingUnits })
          .then(() => {
            logOut()
            alert("Fehler: Konto nicht aktiviert. Der Zugang muss zuerst aktiviert werden vom Vorgesetzten.")
          })
      } else {
        setError(t('Auth.alert_passwords_must_match', { ns: 'internal' }));
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <>
      <div className="p-5 box mt-3 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        <h3 className="mb-4">{t('Auth.heading_signup', { ns: 'internal' })}</h3>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicDisplayName">
            <Form.Control
              type="text"
              placeholder={t('App.name')}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder={t('App.email')}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder={t('Auth.password', { ns: 'internal' })}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicConfirm">
            <Form.Control
              type="password"
              placeholder={t('Auth.confirm', { ns: 'internal' })}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </Form.Group>

          <p><b>Deine Zuständigkeit(en) im HR / Recruiting:</b></p>

          <Form.Group className="mb-3" controlId="formCompany">
            <Form.Select value={selectedCompany} onChange={e => setSelectedCompany(e.target.value)} required>
              <option hidden value="" key="">{t("App.company")}</option>
              <option value="Gesamt" key="Gesamt">Gesamte Labora (Alle Unternehmen)</option>
              <Companies />
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formOU">
            <Form.Select value={operatingUnits} onChange={e => !operatingUnits.includes(e.target.value) && setOperatingUnits(prevState => [...prevState, e.target.value])} multiple required>
              <option defaultValue disabled value="" key="">{t("App.operating_unit")}</option>
              <option value="Gesamt" key="Gesamt">Alle Operating Units</option>
              <Branches />
            </Form.Select>
          </Form.Group>

          <div className="d-grid gap-2">
            <Button variant="primary" type="Submit">
              {t('Auth.sign_up', { ns: 'internal' })}
            </Button>
          </div>
        </Form>
      </div>
      <div className="p-4 box mt-3 text-center col-12 col-sm-10 col-md-8 col-lg-5">
        {t('Auth.already_have_an_account', { ns: 'internal' })} <Link to="/login">{t('Auth.log_in', { ns: 'internal' })}</Link>
      </div>
    </>
  );
};

export default Signup;