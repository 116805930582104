import React, { useEffect, useCallback } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useCert } from "../contexts/CertificatesContext"
import { Alert } from "react-bootstrap"
import { Cookies } from 'react-cookie'
import { format } from 'date-fns'
import CertificateCommunication from './CertificateCommunication'
import CertificateThinkingStyle from './CertificateThinkingStyle'

const Certificates = () => {
    const { t, activePage: completed, valuesComm, valuesThink } = useGlobalState()
    const { generateAssessments, error } = useCert()
    const date = format(new Date(), 'dd.MM.yyyy')

    useEffect(() => {
        generateAssessments(false)
    }, [generateAssessments])

    useEffect(() => {
        if (completed === false || completed === 'false') {
            const onBeforeUnload = (e) => {
                e.preventDefault()
                e.returnValue = 'return something'
                return 'return something'
            }
            window.addEventListener("beforeunload", onBeforeUnload)
            return () => window.removeEventListener("beforeunload", onBeforeUnload)
        }
    }, [completed])

    const AssessmentsCompleted = useCallback(() => {
        if (completed === false || completed === 'false') {
            return (<>
                <img alt={t("App.loading")} src={process.env.PUBLIC_URL + '/images/gif/loading.gif'} width='200' />
                <h4>{t('Assessments.profile_creation_heading')}</h4>
                <p className="pt-3">{t('Assessments.profile_creation_1')}</p>
                <p className="p-2"><b>{t('Assessments.profile_creation_2')}</b></p>
                <div id='cert-container' className='loading'>
                    <CertificateCommunication date={date} fillTime={Number(valuesComm['fillTime'])} valuesComm={valuesComm} />
                    <CertificateThinkingStyle date={date} fillTime={Number(valuesThink['fillTime'])} valuesThink={valuesThink} />
                </div>
            </>)
        } else {
            const cookies = new Cookies()
            cookies.remove("userData", { path: '/' })
            cookies.remove("selectedBtns", { path: '/' })
            sessionStorage.clear()
            return (<>
                <h4 className='mb-3'>{t("Assessments.completed_heading")}</h4>
                <Alert variant="success">{t("Assessments.completed")}</Alert>
            </>)
        }
    }, [completed, date, t, valuesComm, valuesThink])

    return (<>
        {error && <Alert variant="danger">{error}</Alert>}
        <div className='loading col-md-9 col-xl-8 p-3 align-items-start'>
            <AssessmentsCompleted />
        </div>
    </>)
}

export default Certificates
