import React, { useEffect } from "react"
import { useGlobalState } from "../contexts/GlobalStateContext"
import { useCert } from "../contexts/CertificatesContext"

// Denkstilprofil "Victoria"
const CertificateThinkingStyle = ({ date, fillTime, valuesThink }) => {
    const { t } = useGlobalState()
    const { styleAlphaA, Header, MainText, Footer } = useCert()
    const valuesPolygon = [];
    let time = new Date(fillTime ? fillTime : 0).toISOString().substr(11, 8)
    const size_think = 273

    valuesPolygon['yellowX'] = (size_think * .5 + valuesThink['value_yellow'] * (size_think / 64)) + 29
    valuesPolygon['yellowY'] = (size_think * .5 - valuesThink['value_yellow'] * (size_think / 64))
    valuesPolygon['red'] = (size_think * .5 + valuesThink['value_red'] * (size_think / 64)) + 29
    valuesPolygon['greenX'] = (size_think * .5 - valuesThink['value_green'] * (size_think / 64))
    valuesPolygon['greenY'] = (size_think * .5 + valuesThink['value_green'] * (size_think / 64)) + 29
    valuesPolygon['blue'] = (size_think * .5 - valuesThink['value_blue'] * (size_think / 64))

    useEffect(() => {
        if (Object.keys(valuesThink).length > 0) {
            styleAlphaA('think')
        }
    }, [valuesThink, styleAlphaA])

    return (
        Object.keys(valuesThink).length > 0 &&
        <div id="certThink" className='cert d-flex flex-column alpha-cert' style={t('App.lang') === 'de' ? { background: `url('${process.env.PUBLIC_URL}/images/think/de.think.png') 50% 65.6%/86.1% no-repeat #fff` } : { background: `url('${process.env.PUBLIC_URL}/images/think/${t('App.lang')}.think.png') 50% 65.1%/82.4% no-repeat #fff` }}>
            <Header />
            <div id='main-cert' className='d-flex flex-column align-items-center text-center'>
                <MainText t={t} time={time} date={date} strAssessment={'think'} />
                <div className='diagram-container d-flex flex-column align-items-center' style={{ marginTop: '167.5px' }}>
                    <svg className='polygonContainerThink' style={{ width: '303px', height: '303px' }}>
                        <polygon id="polygonThink" points={valuesPolygon['yellowX'] + "," + valuesPolygon['yellowY'] + " " + valuesPolygon['red'] + "," + valuesPolygon['red'] + " " + valuesPolygon['greenX'] + "," + valuesPolygon['greenY'] + " " + valuesPolygon['blue'] + "," + valuesPolygon['blue']} />
                    </svg>
                    <div id='thinkValueTop' className='d-flex justify-content-between'>
                        <h6 id='value_blue' className='think text-start'>{valuesThink["value_blue"]}</h6>
                        <h6 id='value_yellow' className='think text-end'>{valuesThink["value_yellow"]}</h6>
                    </div>
                    <div id='thinkValueBottom' className='d-flex justify-content-between'>
                        <h6 id='value_green' className='think text-start'>{valuesThink["value_green"]}</h6>
                        <h6 id='value_red' className='think text-end'>{valuesThink["value_red"]}</h6>
                    </div>
                </div>
            </div>
            <Footer strAssessment={'think'} />
        </div >
    )
}

export default CertificateThinkingStyle
